import {gql, useQuery} from '@apollo/client'
import {Grid} from '@mui/material'
import {styled} from '@mui/system'
import {getPlaceholder} from '../../Directory'
import CompanyImageIcon from '../../profile/Profile.CompanyImageIcon'

export const MOST_ENGAGED_MEMBERS = gql`
  query MostEngagedMembers($orgId: ID!) {
    mostEngagedMembers(objectId: $orgId) {
      name
      role
      company
      visits
      time
      daysAgo
      pageId
      logoUrl
    }
  }
`

const ListContainer = styled('div')(({ theme }) => ({
  padding: '20px',
  borderRadius: '12px',
  fontFamily: 'Aktiv-Grotesk',
  fontSize: '20px',
  fontWeight: 400,
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}))

const Subtitle = styled('p')(({ theme }) => ({
  fontSize: '14px',
  color: '#6D8884',
  marginBottom: 47,
  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
    marginBottom: 24,
  },
}))

const StatsText = styled('p')({
  marginRight: 20,
  fontSize: 13,
  whiteSpace: 'nowrap',
})

// TODO: Delete this?
// const Button = styled('button')(({ theme }) => ({
//   marginTop: '47px',
//   borderRadius: '100px',
//   height: '58px',
//   width: '226px',
//   border: '1px solid #2F4F4F',
//   fontSize: '16px',
//   fontWeight: 400,
//   cursor: 'pointer',
//   backgroundColor: 'transparent',
//   fontFamily: 'Aktiv Grotesk',
//   '&:hover': {
//     textDecoration: 'underline',
//   },
//   [theme.breakpoints.down('sm')]: {
//     width: '100%',
//     fontSize: '14px',
//     height: '48px',
//   },
// }))

const BlurWrapper = styled('div')({
  filter: 'blur(3.5px)',
  pointerEvents: 'none',
})

const RegularWrapper = styled('div')({
})


// Fake filler data
const fakeMembers = Array.from({ length: 3 }).map((_, i) => ({
  name: '—',
  role: 'Founder',
  company: 'Stealth Startup',
  visits: Math.floor(Math.random() * 10 + 1),
  time: `${Math.floor(Math.random() * 5) + 1}:${(Math.random() * 60).toFixed(0).padStart(2, '0')}`,
  daysAgo: `${Math.floor(Math.random() * 30) + 1} days ago`,
  pageId: `fake-${i}`,
  logoUrl: null,
}))

export const MostEngagedMembers = ({ profile, orgId }: { profile: any, orgId: string }) => {
  const { data, loading, error } = useQuery(MOST_ENGAGED_MEMBERS, {
    variables: { orgId },
  })

  if (loading || !data) return null
  if (error) return <p>Error loading members</p>

  const members = data.mostEngagedMembers
  const isEmpty = members.length === 0
  const membersToDisplay = isEmpty ? fakeMembers : members
  const Wrapper = isEmpty ? BlurWrapper : RegularWrapper

  return (
    <ListContainer>
      <h2 style={{ fontSize: '22px' }}>Most engaged Cherub members</h2>
      {isEmpty ? <Subtitle>This will be available once someone clicks on one of your profile links</Subtitle> : <Subtitle>Who’s spent the most time on your profile in the past 90 days</Subtitle> }
      
      <Wrapper>
        {membersToDisplay.map((member: any, index: number) => {
          const href = `#/profile/${member.pageId}`

          return (
            <Grid
              container
              key={index}
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{
                borderBottom: '1px solid #ddd',
                paddingY: 1.5,
                marginBottom: 1,
              }}
            >
              {/* Left side: Logo + Info */}
              <Grid item xs={12} sm={6} md={5}>
                <a
                  href={href}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CompanyImageIcon
                    src={member.logoUrl ?? getPlaceholder('founder', Math.floor(Math.random() * 11))}
                  />
                  <div style={{ paddingLeft: 12 }}>
                    <p style={{ fontSize: 12 }}>{member.role}</p>
                    <p style={{ fontSize: 16 }}>{member.name}</p>
                    <p style={{ fontSize: 14 }}>{member.company}</p>
                  </div>
                </a>
              </Grid>

              {/* Right side: Stats */}
              <Grid item xs={12} sm={6} md={7}>
                <Grid container spacing={1} sx={{ flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                  <Grid item>
                    <StatsText>Visited {member.visits} times</StatsText>
                  </Grid>
                  {member.time && member.time !== '0:00' && (
                    <Grid item>
                      <StatsText>Viewed for {member.time}</StatsText>
                    </Grid>
                  )}
                  <Grid item>
                    <StatsText>{member.daysAgo}</StatsText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Wrapper>
    </ListContainer>
  )
}

import {MutationResult, gql, useApolloClient} from '@apollo/client'
import {Typography} from '@mui/material'
import Grid from '@mui/material/Grid'
import {Box, Stack, SxProps, styled, useTheme} from '@mui/system'
import crypto from 'crypto-js'
import _ from 'lodash'
import React, {useCallback, useEffect, useMemo, useReducer} from 'react'
import {useBlocker, useParams} from 'react-router-dom'
import Footer from '../Footer'
import Header from '../Header'
import {ProfileEditFieldsFragment, useGetEditProfileQuery, useSaveProfileMutation} from '../api/types'
import colors from '../colors'
import {PrimaryButton, SecondaryButton} from '../ui/Buttons'
import {Done} from '../ui/icons'
import {CherubModal} from './Profile.CherubModal'
import {
  Action,
  ProfileDataKeys,
  ProfileDataType,
  ProfileForm,
  ProfileFormContext,
  ProfileFormDispatchContext,
  nullProfile,
  profileFormReducer,
} from './Profile.Contexts'
import GetStartedLink from './Profile.GetStartedLink'
import PrimaryAction from './Profile.PrimaryAction'
import SecondaryAction from './Profile.SecondaryAction'
import ProfileSection, {SectionDivider} from './Profile.Section'
import CherubTooltip from './Profile.Tooltip'
import {anchorFor} from './common'
import founderInputs from './founderInputs'
import investorInputs from './investorInputs'

const NavigationIcon = styled('div')<{focused?: boolean}>(
  ({theme, focused}) => `
  visibility: ${focused ? 'visible' : 'hidden'};

  color: #000;
  text-align: center;
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
`,
)

const NavigationItem = styled('div')<{focused?: boolean}>(
  ({theme, focused}) => `
  color: ${focused ? colors.evergreen[130] : colors.darkEvergreen[60]};
  cursor: pointer;
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 17.6px */
`,
)

function ErrorMessage() {
  const theme = useTheme() as any
  const style = {
    ...theme?.typography?.body4,
    marginBottom: 0,
  }
  return (
    <Box typography="body4" textAlign="left">
      <Typography paragraph variant="body4">
        Something went wrong while saving.
      </Typography>
      <ol style={{...style, paddingLeft: 20}}>
        <li style={style}>Check that the edit you attempted was successful.</li>
        <li style={style}>Be sure your internet connection is stable.</li>
      </ol>
    </Box>
  )
}

function EditNavHeader({
  isDirty,
  state: {loading, error, called},
}: {
  isDirty: boolean
  state: MutationResult<any>
}): React.JSX.Element {
  let inner: React.JSX.Element = <></>

  // Note: Called never changes from true to false (one time update)
  // In priority order: show a failed save, note that there are changes (via Saving...), or that a save was successful
  // See https://www.figma.com/design/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?node-id=2666-3503&m=dev
  if (error) {
    inner = (
      <>
        Not Saved <CherubTooltip iconStyle={{height: 18, width: 18}} title="Not saved" description={ErrorMessage()} />
      </>
    )
  } else if (isDirty) {
    inner = <>Saving...</>
  } else if (called && !loading && !error) {
    inner = (
      <>
        <Done width={18} height={18} style={{verticalAlign: 'middle'}} /> Saved
      </>
    )
  } else {
    inner = <>&nbsp;</>
  }
  return (
    <Box bgcolor={colors.darkEvergreen[100]} position="sticky" zIndex={999} top="80px">
      <Box typography="body4" color={colors.darkEvergreen[40]}>
        <Box marginRight={5} padding={5} sx={{textAlign: 'right'}}>
          {inner}
        </Box>
      </Box>
    </Box>
  )
}

const styles: Record<string, SxProps> = {
  preamble: {
    padding: {xs: '40px 25px', md: '40px 40px', lg: '40px 120px'},
  },
  form: {
    borderTop: `2px solid ${colors.background[5]}`,
    background: colors.background['warm white'],
    padding: {xs: '40px 25px', lg: '40px 120px'},
  },
  modal: {
    background: colors.transparent[10],
    position: 'fixed',
    zIndex: '1300px',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default function Profile(): React.JSX.Element {
  const params = useParams()
  const client = useApolloClient()
  const [profileData, dispatch] = useReducer(profileFormReducer, nullProfile)
  const [queuedFields, setQueuedFields] = React.useState<ProfileDataKeys[]>([])
  const isDirty = Object.keys(queuedFields).length > 0

  const [sections, setSections] = React.useState(founderInputs)

  // Wrap the dispatch so we can capture the changes so far
  const formDispatch = (action: Action) => {
    if (action.type === 'set_loaded') {
      return
    }

    dispatch(action)
    const rootField = action.field.property.split('.')[0].split('[')[0] as ProfileDataKeys
    setQueuedFields([...queuedFields, rootField])
  }

  // While we cannot customize this, we can at least try to help someone not ditch all their changes by mistake
  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault()
        e.returnValue = ''
        return true
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => window.removeEventListener('beforeunload', onBeforeUnload)
  })

  useEffect(() => {
    if (isDirty) {
      onSubmit()
    }
  }, [queuedFields]) // eslint-disable-line react-hooks/exhaustive-deps -- never invoke on a changed onSubmit

  const blocker = useBlocker(
    ({currentLocation, nextLocation}) => isDirty && currentLocation.pathname !== nextLocation.pathname,
  )

  const [focusedSection, setFocusedSection] = React.useState(0)
  const onSetFocusedSection = (index: number) => {
    const section = sections[index]
    const element = document.getElementById(anchorFor(section))!
    window.scrollTo({top: element.offsetTop - 120, behavior: 'smooth'})
    setFocusedSection(index)
  }

  // TODO; for testing, may need to lift this into loader?
  const onCompleted = (data: {profile: ProfileEditFieldsFragment}) => {
    dispatch({
      type: 'set_loaded',
      profile: data.profile.data,
      files: data.profile.files,
      metadata: {
        profileType: data.profile.profileType,
        truncated: data.profile.truncated,
      },
    })
    setSections(data.profile.profileType === 'founder' ? founderInputs : investorInputs)
  }
  const {loading, error} = useGetEditProfileQuery({onCompleted, variables: {orgId: params.orgId!}})

  const [saveProfile, saveProfileState] = useSaveProfileMutation()

  // TODO: changes collecting every change, we should be deduping
  // eg: http:// -> [h t t p]
  const deboucedSave = useMemo(
    () =>
      _.debounce(async (currentData: ProfileForm, changes: ProfileDataKeys[]) => {
        const numberOfChanges = changes.length
        const profile = changes.reduce((diff, rootField) => {
          const field = _.get(currentData.profile, rootField)
          diff[rootField] = field
          return diff
        }, {} as ProfileDataType)

        const pendingFiles = currentData.pendingFiles
        const fileReferences = {}
        for (const pendingFile in pendingFiles) {
          const {file} = pendingFiles[pendingFile]
          const buffer = await file.arrayBuffer()
          const checksum = crypto.MD5(crypto.lib.WordArray.create(buffer)).toString(crypto.enc.Base64)
          const name = pendingFile.includes('[')
            ? pendingFile.replace(/\[\d\]\./, '__') + 's'
            : pendingFile.replace('.', '_')

          const response = await client.mutate({
            mutation: gql`
              mutation StartProfileUpload(
                $name: ProfileUploadOptions!
                $checksum: String!
                $size: Int!
                $content_type: String!
                $orgId: ID
              ) {
                startUpload(
                  input: {
                    type: profile
                    name: $name
                    metadata: {checksum: $checksum, size: $size, contentType: $content_type}
                    owner: $orgId
                  }
                ) {
                  errors
                  signedId
                  url
                  headers
                }
              }
            `,
            variables: {
              name,
              checksum,
              size: file.size,
              content_type: file.type,
              orgId: params.orgId,
            },
          })

          if (response.errors) {
            throw response.errors
          }

          const {url, headers, signedId} = response.data.startUpload

          const upload = await fetch(url, {method: 'PUT', headers, body: file})
          if (!upload.ok) {
            const text = await upload.text()
            // TODO: tracking ID / tap into error reporting service
            throw new Error('An issue was encountered while uploading your files: ' + text)
          }

          const attachment = await client.mutate({
            mutation: gql`
              mutation FinishProfileUpload($name: ProfileUploadOptions!, $signedId: String!, $orgId: ID) {
                finishUpload(input: {signedId: $signedId, type: profile, name: $name, owner: $orgId}) {
                  errors
                  key
                }
              }
            `,
            variables: {signedId, name, orgId: params.orgId},
          })

          if (attachment.errors) {
            throw attachment.errors
          }

          // Once saved, we need to persist a reference, as files are loaded outside of the data blob
          _.set(fileReferences, pendingFile, {key: attachment.data.finishUpload.key, ref: 'files'})
        }

        const data = _.merge(_.cloneDeep(profile), fileReferences)
        const founder = profileData.metadata.profileType === 'founder' ? data : null
        const investor = profileData.metadata.profileType === 'investor' ? data : null
        await saveProfile({
          // TODO(long term): admins probably shouldn't use this specific page and instead a different one
          variables: {founder, investor, orgId: params.orgId},
          onCompleted: data => {
            if (data.updateProfile?.profile) {
              const profile = data.updateProfile.profile // for some reason this is needed to get the compiler to stop being wrong
              onCompleted({profile})
              setQueuedFields(changes.slice(numberOfChanges))
            }
          },
        })
      }, 2500),
    [client, params.orgId, profileData.metadata.profileType, saveProfile],
  )

  const onSubmit = useCallback(() => deboucedSave(profileData, queuedFields), [profileData, deboucedSave, queuedFields])

  if (profileData.profile == null || loading) {
    return (
      <div className="Profile">
        {' '}
        <p>Loading...</p>
      </div>
    )
  }

  if (error) {
    return (
      <div className="Profile">
        <p>Error : {error.message}</p>
      </div>
    )
  }

  return (
    <ProfileFormContext.Provider value={profileData}>
      <CherubModal
        open={blocker.state === 'blocked'}
        aria-label="Blocking depature as there are changes"
        onClose={blocker.reset}>
        <Stack spacing={5}>
          <Typography variant="h3">Are you sure you want to discard the changes you made?</Typography>
          <Typography variant="body3">
            Click cancel and back to the edit side to update to save your progress.
          </Typography>
          <Box sx={{height: '20px'}} />
          <PrimaryButton size="large" onClick={blocker.proceed}>
            Discard
          </PrimaryButton>
          <SecondaryButton size="large" onClick={blocker.reset}>
            Cancel
          </SecondaryButton>
        </Stack>
      </CherubModal>
      <ProfileFormDispatchContext.Provider value={formDispatch}>
        <Header />
        <EditNavHeader isDirty={isDirty} state={saveProfileState} />
        <Box sx={styles.preamble}>
          <Stack spacing={10}>
            <Typography variant="h1">Your Profile</Typography>
            <GetStartedLink />
            <Box>
              <Grid container spacing={15}>
                {profileData.metadata.profileType === 'founder' && (
                  <Grid item xs={12} md={6}>
                    {/* TODO: Needs to be hidden until editing, see: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=390-6893&mode=dev */}
                    <PrimaryAction onClick={() => onSubmit()} />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <SecondaryAction />
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>
        <Box sx={styles.form}>
          <Grid container spacing={4}>
            <Grid item sx={{display: {xs: 'none', md: 'block'}}} md={2}>
              <Box sx={{position: 'sticky', top: '120px'}}>
                <Grid container columnSpacing={2.5}>
                  <Grid item xs="auto">
                    <Stack spacing={1.5}>
                      {sections.map((section, index) => (
                        <NavigationIcon key={section.title} focused={focusedSection === index}>
                          📍
                        </NavigationIcon>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs>
                    <Stack spacing={1.5}>
                      {sections.map((section, index) => (
                        <NavigationItem
                          key={section.title}
                          focused={focusedSection === index}
                          onClick={() => onSetFocusedSection(index)}>
                          {section.title}
                        </NavigationItem>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid container item xs={12} md={10} rowSpacing={10} sx={{paddingLeft: '120px'}}>
              {sections.map((section, index) => (
                <React.Fragment key={section.title}>
                  <ProfileSection section={section} />
                  {index !== sections.length - 1 && <SectionDivider />}
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </ProfileFormDispatchContext.Provider>
    </ProfileFormContext.Provider>
  )
}

import {gql, useMutation} from '@apollo/client'
import {Grid} from '@mui/material'
import {loadStripe, StripeEmbeddedCheckout} from '@stripe/stripe-js'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {RequiresAuthentication} from './auth/RequiresAuthentication'
import config from './config'
import Footer from './Footer'
import Header from './Header'
import {SecondaryButton} from './ui/Buttons'

const EXPIRE_CHECKOUT_SESSION = gql`
  mutation ExpireCheckoutSession($sessionId: String!) {
    expireCheckoutSession(input: {sessionId: $sessionId}) {
      success
    }
  }
`

const CREATE_EMBEDDED_PORTAL_SESSION = gql`
  mutation CreateStripeCheckoutSession($priceId: String!) {
    createStripeCheckoutSession(input: {priceId: $priceId}) {
      clientSecret
      sessionId
    }
  }
`

type EmbeddedStripePortalProps = {
  priceId: string
}

function EmbeddedStripePortal({priceId}: EmbeddedStripePortalProps): React.JSX.Element {
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [sessionId, setSessionId] = useState<string | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const portalRef = useRef<StripeEmbeddedCheckout | null>(null) // Correct type

  const [createPortalSession] = useMutation(CREATE_EMBEDDED_PORTAL_SESSION)
  const [expireSession] = useMutation(EXPIRE_CHECKOUT_SESSION)
  const navigate = useNavigate()

  // Clean up any existing embedded portal instance
  const cleanupPortal = () => {
    if (portalRef.current) {
      try {
        portalRef.current.destroy()
      } catch (error) {
        console.warn('Failed to destroy embedded checkout:', error)
      }
      portalRef.current = null
    }
  }

  // Fetch new clientSecret and sessionId when priceId changes
  useEffect(() => {
    const fetchClientSecret = async () => {
      cleanupPortal() // Ensure no stale portal exists before new session

      try {
        const {data} = await createPortalSession({variables: {priceId}})
        setClientSecret(data.createStripeCheckoutSession.clientSecret)
        setSessionId(data.createStripeCheckoutSession.sessionId)
      } catch (error) {
        console.error('Failed to fetch Stripe session:', error)
      }
    }

    fetchClientSecret()

    return () => {
      cleanupPortal() // Clean up on component unmount
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceId])

  // Initialize embedded portal when clientSecret becomes available
  useEffect(() => {
    const initializeEmbeddedPortal = async () => {
      if (!clientSecret || !containerRef.current) return

      cleanupPortal() // Ensure clean slate before initializing

      try {
        const stripe = await loadStripe(config.stripe.pk)

        if (!stripe) {
          console.error('Failed to load Stripe.')
          return
        }

        const portal = await stripe.initEmbeddedCheckout({clientSecret})
        portalRef.current = portal

        portal.mount(containerRef.current)
      } catch (error) {
        console.error('Failed to initialize embedded checkout:', error)
      }
    }

    initializeEmbeddedPortal()

    return () => {
      cleanupPortal() // Cleanup on unmount or dependency change
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSecret])

  // Handle user cancellation
  const handleCancelCheckout = async () => {
    if (!sessionId) return

    try {
      const {data} = await expireSession({variables: {sessionId}})
      if (data?.expireCheckoutSession?.success) {
        cleanupPortal()
        navigate('/accounts', {replace: true})
      }
    } catch (error) {
      console.error('Failed to expire checkout session:', error)
    }
  }

  return (
    <Grid
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2, // Add some spacing between elements
        px: 2, // Add horizontal padding for smaller screens
        width: '100%',
      }}>
      <div
        ref={containerRef}
        style={{
          width: '100%', // Full width by default
          maxWidth: '600px', // Cap at a reasonable size for larger screens
          minWidth: '300px', // Minimum for very small devices
          height: 'auto',
        }}
      />

      {clientSecret && (
        <SecondaryButton
          onClick={handleCancelCheckout}
          size="large"
          sx={{
            marginBottom: 5,
            width: '100%', // Full width on mobile
            maxWidth: '400px', // Cap width on larger screens
            mt: 2, // Add margin for separation
          }}>
          Cancel Checkout
        </SecondaryButton>
      )}
    </Grid>
  )
}

export const route = {
  path: '/change-subscription',
  element: (
    <RequiresAuthentication>
      <EmbeddedStripePortalView />
    </RequiresAuthentication>
  ),
  title: 'Change Subscription',
}

export default function EmbeddedStripePortalView(): React.JSX.Element {
  const location = useLocation()

  const priceId = location.state?.priceId

  if (!priceId) {
    return <div>Price ID is required</div>
  }
  return (
    <>
      <Header />
      <EmbeddedStripePortal priceId={priceId} />
      <Footer />
    </>
  )
}

import {gql, useQuery} from '@apollo/client'
import {Grid} from '@mui/material'
import {styled} from '@mui/system'
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts'
import colors from '../../colors'

const MOST_VISITED_LINKS = gql`
  query MostVisitedLinks($orgId: ID!) {
    mostVisitedLinks(objectId: $orgId) {
      name
      visits
    }
  }
`

const COLORS = ['#2F4F4F', '#4C6B6B', '#6E8C8C', '#90ADAD', '#B3CFCF', '#D6F0F0']

const Card = styled('div')({
  backgroundColor: colors.background['warm white'],
  padding: '20px',
  borderRadius: '30px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  maxWidth: '100%',
})

const BlurWrapper = styled('div')({
  filter: 'blur(3.5px)',
  pointerEvents: 'none',
})

const RegularWrapper = styled('div')({
})

const Subtitle = styled('p')({
  fontSize: '16px',
  color: '#3A3A3A',
  marginBottom: '15px',
})

const LinkList = styled('div')({
  marginTop: '10px',
})

const LinkItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  padding: '5px 0',
  borderBottom: '1px solid #eee',
  color: '#2F4F4F',
  wordBreak: 'break-word',
})

const LinkText = styled('a')({
  display: 'flex',
  textDecoration: 'none',
  color: '#2F4F4F',
  '&:hover': {
    textDecoration: 'underline',
  },
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  marginRight: '30px',
  fontSize: '14px',
  fontWeight: '400',
})

const Visits = styled('span')({
  fontWeight: 'bold',
  color: '#666',
  marginRight: '15px',
  whiteSpace: 'nowrap',
})

const fakeLinks = [
  { name: 'pitch-deck.pdf', visits: 134 },
  { name: 'team-bio.pdf', visits: 92 },
  { name: 'product-launch.pdf', visits: 67 },
  { name: 'traction-metrics.pdf', visits: 41 },
]

type MostVisitedLinksProps = {
  orgId: string
}

const MostVisitedLinks = ({ orgId }: MostVisitedLinksProps) => {
  const { data, loading, error } = useQuery(MOST_VISITED_LINKS, {
    variables: { orgId },
  })

  if (loading || !data) return null
  if (error) return <Card><p>Error loading links</p></Card>

  const hasLinks = data.mostVisitedLinks.length > 0
  const linksToRender = hasLinks ? data.mostVisitedLinks : fakeLinks
  const Wrapper = hasLinks ? RegularWrapper : BlurWrapper

  const chartData = linksToRender.map((item: any, i: number) => ({
    ...item,
    color: COLORS[i % COLORS.length],
  }))

  return (
    <Card>
      <h2 style={{ fontWeight: 400 }}>Most Visited Links</h2>
      {!hasLinks ? <Subtitle>This will be available once someone clicks on one of your profile links</Subtitle> : <Subtitle>{'What is getting the most clicks on your profile (all-time)'}</Subtitle> }

      <Wrapper>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={5}>
            <div style={{ width: '100%', height: 250 }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={chartData}
                    dataKey="visits"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={80}
                  >
                    {chartData.map((entry: any, index: number) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip
                    contentStyle={{
                      backgroundColor: 'white',
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px',
                      boxShadow: 'none',
                      height: 40,
                    }}
                    labelStyle={{
                      fontFamily: 'Aktiv-Grotesk',
                      fontSize: '14px',
                      fontWeight: '400',
                    }}
                    itemStyle={{
                      fontFamily: 'Aktiv-Grotesk',
                      fontSize: '14px',
                    }}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Grid>

          <Grid item xs={12} sm={7}>
            <LinkList>
              {chartData.map((link: any, index: number) => (
                <LinkItem key={index}>
                  <LinkText
                    href={`https://${link.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      style={{
                        backgroundColor: link.color,
                        width: 12,
                        height: 12,
                        marginRight: 16,
                        flexShrink: 0,
                      }}
                    />
                    {link.name}
                  </LinkText>
                  <Visits>{link.visits} visits</Visits>
                </LinkItem>
              ))}
            </LinkList>
          </Grid>
        </Grid>
      </Wrapper>
    </Card>
  )
}

export default MostVisitedLinks

import {Grid} from '@mui/material'
import {Box, Stack, SxProps, styled} from '@mui/system'
import React from 'react'
import {ListTagsQuery, useListTagsQuery} from '../api/types'
import colors from '../colors'
import {Close} from '../ui/icons'

const styles: Record<string, SxProps> = {
  tagList: {
    height: '311px',
    position: 'relative',
    overflowY: 'scroll',
  },
}

const Title = styled('div')(
  ({theme}) => `
  color: ${colors.background.black};

  font-family: "Warnock-Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 115%; /* 32.2px */
  letter-spacing: -0.28px;
`,
)

const Description = styled('div')(
  ({theme}) => `
  color: ${colors.gray[2]};

  /* Body/Body 3 Regular */
  font-family: "Aktiv-Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
`,
)

const TagButton = styled('button')<{selected: boolean; atMax: boolean}>(
  ({theme, selected, atMax}) => `
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid ${selected ? colors.gray[1] : colors.gray[4]};
  background: ${selected ? colors.gray[5] : colors.background.white};
  cursor: ${atMax && !selected ? 'not-allowed' : 'pointer'};

  height: 5rem;
  padding: 0.88rem 1.25rem;
  text-align: left;
  width: 100%;
`,
)

const TagText = styled('div')(
  ({theme}) => `
  color: ${colors.background.black};

  font-family: "Aktiv-Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
`,
)

const Counter = styled('div')(
  ({theme}) => `
  color: ${colors.gray[1]};
  text-align: center;
  
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 17.6px */
`,
)

const Save = styled('button')(
  ({theme}) => `
  border-radius: 100px;
  border: 1px solid ${colors.background[5]};
  background: ${colors.evergreen[100]};
  color: ${colors.background[1]};
  cursor: pointer;

  padding: 20px 0px; // 57 high = 20 * 2 + 17
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  
  text-align: center;
  font-family: "Aktiv-Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: -0.14px;

   &:disabled {
    background: ${colors.evergreen[40]}; /* Lighter background */
    color: ${colors.background[3]}; /* Dimmed text color */
    cursor: not-allowed;
    border: 1px solid ${colors.background[4]}; /* Adjusted border */
    opacity: 0.6; /* Slightly faded appearance */
  }
`,
)

type TagSelectorProps = {
  title: string
  description?: string
  categories: string[]
  tags: string[] // TODO: typing
  maxItems: number | null
  filterTODO: string
  showSuggestTODO: boolean // TODO: ask if second link should show it at bottom of scroll
  onClose?: () => void
  setTags: (tags: string[]) => void
}

// see https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=376-6984&mode=dev &
//     https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=178-3573&mode=dev
export default function TagSelector({
  title,
  description,
  categories,
  tags,
  maxItems,
  setTags,
  onClose,
}: TagSelectorProps): React.JSX.Element {
  const {data, loading, error} = useListTagsQuery()
  const [pickedTags, setPickedTags] = React.useState<{[key: string]: string}>(
    tags.reduce((o, tag) => ({...o, [tag]: tag}), {}),
  )

  let filteredTags: ListTagsQuery['tags'] = []
  if (categories?.length > 0) {
    filteredTags = data?.tags?.filter(tag => categories.includes(tag.tagCategory.name)) ?? []
  } else {
    filteredTags = data?.tags ?? []
  }

  const onSave = () => setTags(Object.values(pickedTags))
  const numberSelected = Object.keys(pickedTags).length
  // if there is no maxItems set, then we can't ever be at max
  const atMax = maxItems != null && numberSelected >= maxItems

  return (
    <Stack spacing={5}>
      <Stack spacing={2.5}>
        <Title id="modal-title" style={{position: 'relative'}}>
          {title}
          {onClose && (
            <Close
              style={{position: 'absolute', top: -8, right: -16, cursor: 'pointer', color: colors.background.black}}
              aria-label="Close"
              onClick={() => {
                setTags(tags)
                onClose()
              }}
            />
          )}
        </Title>
        <Description id="modal-description">{description}</Description>
      </Stack>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>}
      {filteredTags && (
        <Box sx={styles.tagList}>
          <Grid container rowSpacing={3} columnSpacing={2}>
            {filteredTags.map(tag => {
              const selected = !!pickedTags[tag.name]
              return (
                <Grid key={tag.id} item xs={6}>
                  <TagButton
                    type="button"
                    selected={selected}
                    atMax={atMax}
                    onClick={() => {
                      if (!selected && atMax) {
                        return
                      }

                      const newTags = {...pickedTags}
                      if (selected) {
                        delete newTags[tag.name]
                      } else {
                        newTags[tag.name] = tag.name
                      }
                      setPickedTags(newTags)
                    }}>
                    <TagText>{tag.name}</TagText>
                  </TagButton>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      )}
      <Counter>{maxItems != null ? `${numberSelected}/${maxItems} Selected` : `${numberSelected} Selected`}</Counter>
      <Save disabled={Object.keys(pickedTags).length === 0} aria-label="Save changes" onClick={onSave}>
        Save changes
      </Save>
    </Stack>
  )
}

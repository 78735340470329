import {gql, useQuery} from '@apollo/client'
import {Modal} from '@mui/base'
import {ClickAwayListener} from '@mui/base/ClickAwayListener'
import {Popper} from '@mui/base/Popper'
import {CircularProgress, Grid, Link, Typography} from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import {Box, Stack, SxProps, styled} from '@mui/system'
import _ from 'lodash'
import {DateTime} from 'luxon'
import React, {useEffect, useReducer, useRef, useState} from 'react'
import {useHref, useNavigate, useSearchParams} from 'react-router-dom'
import {getPlaceholder} from './Directory'
import Footer from './Footer'
import Header from './Header'
import {useMarkViewed} from './api/mutations'
import {RequiresAuthentication} from './auth/RequiresAuthentication'
import colors from './colors'
import ProgressComponent from './components/ProgressBar'
import {DealFlowContext, DealFlowDispatchContext, dealflowReducer} from './deal/Deal.Contexts'
import MobileNavMenu from './profile-view/MobileNavMenu'
import CompanyImageIcon from './profile/Profile.CompanyImageIcon'
import RouterUtil from './routeUtil'
import {PrimaryButton, SecondaryButton} from './ui/Buttons'
import {DealTags} from './ui/Tags'
import {ChevronDown, Close, LeftArrow, RightArrow, UnreadMessage} from './ui/icons'

const dealsQuery = `
    deal {
      id
      createdAt
      data
      recommendedFor
      requestIntro
      suggestedProfile
    }
    match {
      id
      createdAt
      data
      initiatingProfile {
        id
        owner {
          id
        }
        profileType
        viewData
      }
      notes
      recommendedFor
      requestIntro
      state
      suggestedProfile
      targetProfile {
        id
        owner {
        id
        }
        profileType
        viewData
      }
    }
    type
`

const GET_DEALS = gql`
query GetDeals {
  dealCarousel {
    ${dealsQuery}
  }
}
`

const GET_DEAL_HISTORY = gql`
query GetDealHistory($filter: String!, $cursor: ISO8601DateTime) {
  dealHistory(filter: $filter, cursor: $cursor) {
    ${dealsQuery}
  }
}
`

const GET_DEAL_HISTORY_PAGE_METADATA = gql`
  query GetDealHistoryPageMetadata {
    dealHistoryPageMetadata {
      all {
        totalItems
        totalPages
      }
      introed {
        totalItems
        totalPages
      }
      requested {
        totalItems
        totalPages
      }
      sent {
        totalItems
        totalPages
      }
    }
  }
`

const profileQuery = `profile {
  id
  owner {
    id
  }
  profileReviewStatus {
    value
  }
}`

const GET_PROFILE = gql`
query GetProfile {
  ${profileQuery}
}
`

export const route = {
  path: '/deals',
  element: (
    <RequiresAuthentication>
      <DealFlowView />
    </RequiresAuthentication>
  ),
  title: 'Deals',
}

RouterUtil.addRoute(route)

const styles: Record<string, SxProps> = {
  modal: {
    background: colors.transparent[10],
    position: 'fixed',
    zIndex: '1300px',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

// More generic components
const Item = styled('div')(
  ({theme}) => `
    margin-top: 0.31rem;
    `,
)

const DealCard = styled('div')<{backgroundColor?: string}>(
  ({theme, backgroundColor}) => `
  box-sizing: border-box;  

  align-items: flex-start;
  align-content: stretch;
  align-self: stretch;

  display: flex;
  flex: 1 0 0;
  flex-direction: column;


  ${theme.breakpoints.up('xs')} {
    background-color: ${backgroundColor || colors.evergreen[130]};
    border-radius: 1.25rem;
    
    max-height: 41.25rem;
    min-width: 27.9375rem;
    max-width: 27.9375rem;
    padding: 1.26rem 1.53rem 1.63rem;
  }
  ${theme.breakpoints.only('xs')} {
    min-width: 22.4375rem;
  }
`,
)

const DealImage = styled('div')(
  ({theme}) => `
    border-radius: 1.25rem;
    max-height: 12.5rem;
    min-height: 12.5rem;
    margin-bottom: 1rem;
    width: 25rem;
`,
)

/* TODO: combine these to one with a prop param*/
const StyledLeftArrow = function (props: {enabled?: boolean; padding?: string} & React.SVGAttributes<{}>) {
  return (
    <Box
      onClick={props.onClick}
      sx={{
        border: '2.25px solid',
        borderColor: colors.background[5],
        borderRadius: '50%',
        color: props.enabled ? colors.evergreen[100] : colors.background[5],
        cursor: props.enabled ? 'pointer' : 'default',
        fontSize: '16px',
        lineHeight: '8px',
        padding: props.padding,
      }}>
      <LeftArrow {...props} />
    </Box>
  )
}
const StyledRightArrow = function (props: {enabled?: boolean; padding?: string} & React.SVGAttributes<{}>) {
  return (
    <Box
      onClick={props.onClick}
      sx={{
        border: '2.25px solid',
        borderColor: colors.background[5],
        borderRadius: '50%',
        color: props.enabled ? colors.evergreen[100] : colors.background[5],
        cursor: props.enabled ? 'pointer' : 'default',
        fontSize: '16px',
        lineHeight: '8px',
        padding: props.padding,
      }}>
      <RightArrow {...props} />
    </Box>
  )
}

function InitialCardContent(props: any) {
  const {deals, historyPages, me} = props
  // const me = props.me

  if (deals.length > 0) {
    return <LetsReviewInitial />
  }

  if (historyPages > 0) {
    return <AllReviewed orgId={me.orgId} />
  }

  if (me.verificationStatus === 'vetted') {
    return <NoDealsInitial orgId={me.orgId} />
  }

  if (me.verificationStatus === 'unsubmitted') {
    return <ApplyToStartInitial orgId={me.orgId} />
  }

  if (me.verificationStatus === 'submitted') {
    return <ApplicationInReview />
  }
  return <></>
}

function LetsReviewInitial(props: any) {
  return (
    <Stack>
      <Typography
        variant="d2"
        sx={{
          paddingTop: '40px',
          color: colors.evergreen[100],
          textAlign: 'center',
          marginBottom: '20px',
          fontSize: {md: '64px'},
        }}>
        Let's go through your deals together
      </Typography>
      <Typography variant="body2" sx={{color: colors.evergreen[100], textAlign: 'center'}}>
        Here are the matches we think you'll like most.
      </Typography>
    </Stack>
  )
}

function AllReviewed(props: any) {
  return (
    <Stack>
      <Typography
        variant="d2"
        sx={{
          paddingTop: '40px',
          color: colors.evergreen[100],
          textAlign: 'center',
          marginBottom: '20px',
          fontSize: {md: '64px'},
        }}>
        You've reviewed
        <br /> all your deals
      </Typography>
      <Typography variant="body2" sx={{color: colors.gray[1], textAlign: 'center'}}>
        Want more matches?
        <br /> Edit your preferences to help us make better suggestions.
      </Typography>
      <div>
        <SecondaryButton
          href={`/profile/${props.orgId}/edit`}
          size="large"
          sx={{width: '100%', textDecoration: 'none', marginTop: {xs: '20px', md: '90px'}}}>
          Edit my preferences
        </SecondaryButton>
      </div>
    </Stack>
  )
}

function NoDealsInitial(props: any) {
  return (
    <Stack>
      <Typography
        variant="d2"
        sx={{
          paddingTop: '40px',
          color: colors.evergreen[100],
          textAlign: 'center',
          marginBottom: '20px',
          fontSize: {md: '64px'},
        }}>
        Nothing yet...
      </Typography>
      <Typography variant="body2" sx={{color: colors.evergreen[100], textAlign: 'center'}}>
        Consider expanding your preferences to find a match.
      </Typography>
      <div>
        <SecondaryButton
          href={`/profile/${props.orgId}/edit`}
          size="large"
          sx={{width: '100%', textDecoration: 'none', marginTop: {xs: '20px', md: '90px'}}}>
          Add more tags
        </SecondaryButton>
      </div>
    </Stack>
  )
}

function ApplyToStartInitial(props: any) {
  return (
    <Stack>
      <Typography
        variant="d2"
        sx={{
          paddingTop: '1rem',
          color: colors.evergreen[100],
          textAlign: 'center',
          marginBottom: '20px',
          fontSize: {md: '64px'},
        }}>
        Apply to Cherub Select
      </Typography>
      <Typography variant="body2" sx={{color: colors.evergreen[100], textAlign: 'center'}}>
        Angel investor and advisor matches are only available for approved Cherub Select members
      </Typography>
      <div>
        <SecondaryButton
          href={`/profile/${props.orgId}/edit`}
          size="large"
          sx={{width: '100%', textDecoration: 'none', marginTop: {xs: '20px', md: '90px'}}}>
          Complete your profile
        </SecondaryButton>
      </div>
    </Stack>
  )
}

function ApplicationInReview() {
  return (
    <Stack>
      <Typography
        variant="d2"
        sx={{
          paddingTop: '1rem',
          color: colors.evergreen[100],
          textAlign: 'center',
          marginBottom: '20px',
          fontSize: {md: '64px'},
        }}>
        We’re reviewing your application
      </Typography>
      <Typography variant="body2" sx={{color: colors.evergreen[100], textAlign: 'center'}}>
        Once accepted, you’ll be able to match with angel investors and strategic advisors
      </Typography>
      <div>
        <SecondaryButton
          href={'https://intercom.help/cherub/en/articles/9035303-getting-started-with-your-cherub-select-application'}
          size="large"
          sx={{width: '100%', textDecoration: 'none', marginTop: {xs: '20px', md: '90px'}}}>
          Learn more
        </SecondaryButton>
      </div>
    </Stack>
  )
}

const IntroOverlayCard = styled(DealCard)(
  ({theme}) => `
    align-items: center;
    backdrop-filter: blur(22px);
    gap: 0.25rem;
    
    bottom: 0;
    position: absolute;
    width: 100%;
`,
)

type MatchDataType = {
  name?: string
  contact_name?: string
  contact_role?: string
  tagline?: string
  highlights?: Array<{value: string}>
  hero_url?: string
  logo_url?: string
}

type MatchType = {
  data: MatchDataType
  suggestedProfile: string
  recommendedFor: string
  introMessage: string
}

type MatchIntroProps = {
  sx?: SxProps
  match: MatchType
  onClose: () => void
}

function MatchIntro({sx, match, onClose}: MatchIntroProps) {
  return (
    <Box sx={{...sx, position: 'relative', bottom: 0}}>
      <IntroOverlayCard id="match-intro" backgroundColor="rgba(11, 57, 50, 0.10)">
        <Close
          style={{position: 'absolute', top: 16, right: 16, cursor: 'pointer', color: colors.background['warm white']}}
          aria-label="Close"
          onClick={onClose}
        />
        {match.data.hero_url && (
          <img
            alt={`${match.data.name} hero`}
            src={match.data.hero_url}
            style={{borderRadius: '5.75rem', maxWidth: '5.75rem', maxHeight: '5.75rem'}}
          />
        )}
        <Typography variant="h4" color={colors.darkEvergreen[20]}>
          {match.data.contact_name || match.data.name}
        </Typography>
        {match.data.contact_name && (
          <Stack direction="row" spacing={2}>
            {match.data.logo_url && (
              <img alt={`${match.data.name} logo`} src={match.data.logo_url} style={{width: '1rem', height: '1rem'}} />
            )}
            <Typography variant="body3" color={colors.background[4]}>
              {match.data.name}
              {match.data.contact_role ? `, ${match.data.contact_role}` : ''}
            </Typography>
          </Stack>
        )}
        {match.introMessage?.length > 0 ? (
          <>
            <Typography margin="1.5rem 0" variant="body3medium" color={colors.background[4]}>
              {match.introMessage}
            </Typography>
            <PrimaryButton
              href={`/profile/${match.suggestedProfile}`}
              size="medium"
              sx={{
                backgroundColor: colors.transparent.fully,
                color: colors.background[1],
                width: '100%',
                textDecoration: 'none',
              }}>
              Tell me more
            </PrimaryButton>
          </>
        ) : (
          <></>
        )}
      </IntroOverlayCard>
    </Box>
  )
}

function FocusedCard(props: any) {
  const deal = props.deal
  const profile = deal && deal.profile
  const dealmatch = deal && (deal.match || deal.deal)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showIntro, setShowIntro] = useState(true) // why isn't showIntro used?
  const touchStart = useRef(null)
  const touchEnd = useRef(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50

  const onTouchStart = (e: any) => {
    touchEnd.current = null // otherwise the swipe is fired even with usual touch events
    touchStart.current = e.targetTouches[0].clientX
  }

  const onTouchMove = (e: any) => (touchEnd.current = e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) {
      return
    }

    if (touchStart.current && touchEnd.current) {
      const distance = touchStart.current - touchEnd.current
      const isLeftSwipe = distance > minSwipeDistance
      const isRightSwipe = distance < -minSwipeDistance
      if (isLeftSwipe || isRightSwipe) {
        isLeftSwipe ? props.next() : props.prev()
      }
    }
  }

  return (
    <>
      <DealCard {...props} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        {/* <DealImage><img alt={`${profile.name} pitch`} src={profile.hero_url} style={{maxWidth:'100%', height:'auto'}} /> </DealImage> */}
        <Stack sx={{height: {}, minHeight: {md: '27rem'}}}>
          {profile.hero_url && (
            <DealImage
              sx={{
                backgroundImage: `url('${profile.hero_url}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            />
          )}
          <Grid container justifyContent="space-between" alignItems="flex-start">
            <Grid item>
              <CompanyImageIcon src={profile.logo_url} style={{border: `1px solid ${colors.background[4]}`}} />
            </Grid>
            <Grid item>
              <PrimaryButton size="small" sx={{display: 'none', backgroundColor: colors.evergreen[130]}}>
                + Follow
              </PrimaryButton>
            </Grid>
          </Grid>
          <Stack sx={{width: '100%', height: '8rem', marginTop: '0.31rem'}}>
            <Item>
              <Typography variant="h6" sx={{color: colors.background['warm white']}}>
                {profile.name}
              </Typography>
            </Item>
            <Item>
              {profile.tagline && (
                <Typography
                  variant="h6"
                  className="truncated-twoline"
                  sx={{color: colors.darkEvergreen[20], fontStyle: 'italic'}}>
                  {profile.tagline}
                </Typography>
              )}
            </Item>
            <Item>
              {profile.highlights && profile.highlights[0] && (
                <Typography variant="body3medium" className="truncated-twoline" sx={{color: colors.background[4]}}>
                  {profile.highlights[0].value}
                </Typography>
              )}
            </Item>
          </Stack>
        </Stack>
        <Stack sx={{width: '100%'}}>
          {deal?.data?.overlap?.length > 0 && (
            <Item>
              <Typography variant="body4" sx={{color: colors.darkEvergreen[60]}}>
                Your Fit
              </Typography>
            </Item>
          )}
          {deal?.data?.overlap?.length > 0 && (
            <Item sx={{height: {md: '6.25rem'}, minHeight: {md: '6.25rem'}}}>
              <DealTags tags={deal.data.overlap} />
            </Item>
          )}
          <Item>
            <Stack direction="row" spacing={4}>
              <PrimaryButton
                href={`/profile/${dealmatch.suggestedProfile}`}
                size="medium"
                sx={{
                  backgroundColor: colors.evergreen[130],
                  color: `${colors.background[1]} !important`,
                  width: '100%',
                  textDecoration: 'none',
                }}>
                Tell me more
              </PrimaryButton>
              {dealmatch.introMessage?.length > 0 ? (
                <Box
                  role="button"
                  aria-label="Unread Message"
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    border: '1px solid white',
                    borderRadius: '50%',
                    color: colors.background['warm white'],
                    cursor: 'pointer',
                    padding: '5px',
                  }}
                  onClick={() => setShowIntro(true)}>
                  <UnreadMessage />
                </Box>
              ) : (
                <></>
              )}
            </Stack>
          </Item>
        </Stack>
      </DealCard>
      {dealmatch.introMessage?.length > 0 && (
        <MatchIntro sx={props.sx} match={dealmatch} onClose={() => setShowIntro(false)} />
      )}
    </>
  )
}

const ChipHolder = styled('div')(
  ({theme}) => `
    display: inline-block;
    backdrop-filter: blur(11px);
    background-color: ${colors.evergreen[70]};
    color: #FFFFFF;
    padding: 0.5rem 0.75rem;

    font-family: 'Aktiv-Grotesk';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
  `,
)

function Chip(props: any) {
  const match = props.match
  const me = props.me
  let text = props.text || ''
  let chipStyle = {}

  if (text && text.length === 0) {
    return <></>
  }

  if (match?.state === 'match_made' || match?.state === 'match_sent' || match?.state === 'follow_up_sent') {
    text = text || 'Introduced'
  } else if (
    (match?.state === 'intro_requested' || match?.state === 'intro_sent') &&
    match?.recommendedFor !== me?.profile.owner.id
  ) {
    text = text || 'Request Sent'
    chipStyle = {backgroundColor: colors.background[5], color: colors.background['white']}
  } else if (
    (match?.state === 'intro_requested' || match?.state === 'intro_sent') &&
    match?.recommendedFor === me?.profile.owner.id
  ) {
    text = text || 'Requested You'
    chipStyle = {backgroundColor: colors.darkEvergreen[20], color: colors.darkEvergreen[100]}
  }

  if (text.trim().length === 0) {
    return <></>
  }

  return <ChipHolder sx={chipStyle}>{text}</ChipHolder>
}

function MessageModal(props: any) {
  const match = props.match
  return (
    <Box sx={{backgroundColor: colors.background[4], borderRadius: '15px', padding: '20px', width: '320px'}}>
      <Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Close style={{cursor: 'pointer'}} onClick={props.close} />
        </Stack>
        <Typography variant="body2" sx={{color: colors.darkEvergreen[100], marginTop: '15px'}}>
          "{match?.notes}"
        </Typography>
      </Stack>
    </Box>
  )
}

function HistoryItem(props: any) {
  const object = props.deal || props.match
  const me = props.me
  const sinceDays = Math.floor(Math.abs(DateTime.fromISO(object.createdAt).diffNow(['days']).days))
  const sinceWeeks = Math.floor(Math.abs(DateTime.fromISO(object.createdAt).diffNow(['weeks']).weeks))
  const messageHandler = props.messageHandler
  const setCurrentMatch = props.setCurrentMatch
  const type = props.type

  let sinceString = ''

  if (sinceDays < 1) {
    sinceString = 'Today'
  } else if (sinceDays <= 7) {
    sinceString = `${sinceDays}d`
  } else if (sinceWeeks <= 4) {
    sinceString = `${sinceWeeks}w`
  } else {
    sinceString = DateTime.fromISO(object.createdAt).toLocaleString()
  }

  function showMessage(e: any) {
    e.stopPropagation()
    e.preventDefault()
    setCurrentMatch(object)
    messageHandler(e)
  }

  type HistoryProfile = {
    logo_url: string
    name: string
    pageId: string
    profileType: string
    tagline: string
  }

  let profile = {} as HistoryProfile
  if (type === 'match') {
    let matchProfile = object?.initiatingProfile
    if (me?.profile.id === object?.initiatingProfile.id) {
      matchProfile = object?.targetProfile
    }
    const photoKey = matchProfile.viewData?.logo_url
    const imgUrl = photoKey ?? getPlaceholder(matchProfile.profileType, Math.floor(Math.random() * 11))

    profile = {
      logo_url: imgUrl,
      name: matchProfile.viewData?.name,
      pageId: matchProfile.owner.id,
      profileType: matchProfile.profileType,
      tagline: matchProfile.viewData?.tagline,
    }
  } else {
    const profileId = me?.profile.owner.id === object.recommendedFor ? object.suggestedProfile : object.recommendedFor

    const photoKey = object?.data?.logo_url
    const imgUrl = photoKey ?? getPlaceholder(object?.data?.profile_type, Math.floor(Math.random() * 11))

    profile = {
      logo_url: imgUrl,
      name: object?.data?.name,
      pageId: profileId,
      profileType: object?.data?.profile_type,
      tagline: object?.data?.tagline,
    }
  }

  const href = useHref(`/profile/${profile.pageId}`)

  return (
    <Box sx={{marginTop: '0.31rem', borderBottom: `1px solid ${colors.background[3]}`, padding: '10px'}}>
      <Link href={href} underline="none">
        <Grid container direction="row" spacing={{md: 2}}>
          <Grid item xs={2} md={1}>
            <CompanyImageIcon src={profile?.logo_url} />
          </Grid>
          <Grid container item direction="column" xs>
            <Grid item>
              <Typography variant="body3" sx={{color: colors.darkEvergreen[60], fontSize: '12px', lineHeight: '130%'}}>
                {_.startCase(profile?.profileType)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body3" sx={{color: colors.darkEvergreen[100], fontSize: '16px', lineHeight: '110%'}}>
                {profile?.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body3"
                sx={{
                  color: colors.darkEvergreen[100],
                  display: '-webkit-box',
                  fontSize: '14px',
                  letterSpacing: '-0.14px',
                  lineHeight: '130%',
                  overflow: 'hidden',
                  '-webkit-line-clamp': '1',
                  '-webkit-box-orient': 'vertical',
                }}>
                {profile?.tagline}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" alignItems="flex-end" justifyContent="center" item xs={4} spacing={2}>
            <Grid item>
              <Typography
                variant="body3"
                sx={{
                  color: colors.darkEvergreen[60],
                  fontSize: '14px',
                  letterSpacing: '-0.14px',
                  lineHeight: 'normal',
                }}>
                {sinceString}
              </Typography>
            </Grid>
            <Grid container item xs={4} direction="row" alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Chip match={object} me={me} />
              </Grid>
              <Grid item sx={{marginLeft: '15px'}}>
                <Box
                  sx={{
                    border: `0.8px solid ${colors.background[4]}`,
                    borderRadius: '80px',
                    color: 'black',
                    padding: '4px 8px 3px 5px',
                    visibility: `${object.notes ? '' : 'hidden'}`,
                  }}
                  onClick={showMessage}>
                  {' '}
                  <Box>
                    <UnreadMessage />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Link>
    </Box>
  )
}

const tabs: SxProps = {
  display: {xs: 'none', md: 'block'},
  '& .MuiTabs-indicator': {
    backgroundColor: colors.evergreen[100],
  },
}

const tab: SxProps = {
  color: colors.darkEvergreen[40],
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '110%',
  fontStyle: 'normal',
  padding: '0 16px',
  textTransform: 'none',
  '&:visited': {
    color: colors.darkEvergreen[40],
  },
  '&.Mui-selected': {
    color: colors.evergreen[100],
    borderBottom: 0,
  },
}
const Separator = styled('hr')(
  ({theme}) => `
      border: 1px solid;
      color: ${colors.background[3]};
      `,
)

type TabValues = 'all' | 'introed' | 'sent' | 'requested'
type CountsMap = Partial<
  Record<
    TabValues,
    {
      totalItems: number
      totalPages: number
    }
  >
>

type TabBarProps = {
  onChange: (tab: TabValues) => void
  counts?: CountsMap
}

function TabBar(props: TabBarProps) {
  const [value, setValue] = React.useState<TabValues>('all')
  const onChange = (_event: React.SyntheticEvent, newValue: TabValues) => {
    setValue(newValue)
    props.onChange(newValue)
  }

  return (
    <Grid container justifyContent="space-between" item sx={{marginTop: '50px'}}>
      <Grid item>
        <Tabs
          sx={tabs}
          value={value}
          aria-label="directory selector for investors or companies"
          role="navigation"
          onChange={onChange}
          variant="scrollable"
          scrollButtons="auto">
          <Tab
            disabled={props.counts?.all?.totalItems === 0}
            sx={tab}
            value="all"
            label={`All (${props.counts?.all?.totalItems})`}
          />
          <Tab
            disabled={props.counts?.introed?.totalItems === 0}
            sx={tab}
            value="introed"
            label={`Introduced (${props.counts?.introed?.totalItems})`}
          />
          <Tab
            disabled={props.counts?.requested?.totalItems === 0}
            sx={tab}
            value="requested"
            label={`Requested (${props.counts?.requested?.totalItems})`}
          />
          <Tab
            disabled={props.counts?.sent?.totalItems === 0}
            sx={tab}
            value="sent"
            label={`Sent (${props.counts?.sent?.totalItems})`}
          />
        </Tabs>
      </Grid>
      <Grid item xs={0} md={12} sx={{position: 'relative', top: '-14px', zIndex: '-1'}}>
        <Separator />
      </Grid>
    </Grid>
  )
}

export default function DealFlowView(): React.JSX.Element {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dealHistoryRef = useRef<HTMLDivElement>(null)

  // Initialize page from URL
  const [page, setPage] = useState(() => {
    const urlPage = parseInt(searchParams.get('page') || '1')
    return isNaN(urlPage) ? 1 : urlPage
  })

  // Cursor tracking (page 1 starts at null)
  const [cursorPages, setCursorPages] = useState<(string | null)[]>([null])

  const [totalPages, setTotalPages] = useState({
    all: 1,
    introed: 1,
    requested: 1,
    sent: 1,
  })

  const [filter, setFilter] = useState<TabValues>('all')

  // Fetch profile (already present in your code)
  const {data: meData, error: meError} = useQuery(GET_PROFILE)

  // Fetch page counts (for tab counts + total pages)
  const {data: pageCounts, error: pageCountsError} = useQuery(GET_DEAL_HISTORY_PAGE_METADATA, {
    onCompleted: data => {
      updateFilter(filter, data?.dealHistoryPageMetadata[filter].totalPages) // Ensures we refresh data once counts are loaded

      setTotalPages({
        all: data.dealHistoryPageMetadata.all.totalPages,
        introed: data.dealHistoryPageMetadata.introed.totalPages,
        requested: data.dealHistoryPageMetadata.requested.totalPages,
        sent: data.dealHistoryPageMetadata.sent.totalPages,
      })
    },
  })

  React.useEffect(() => {
    const urlPage = parseInt(searchParams.get('page') || '1')
    if (urlPage !== page) {
      setPage(urlPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages])

  const updateFilter = (newFilter: TabValues, counts: CountsMap | null) => {
    setFilter(newFilter)
    setPageAndUrl(1)
  }

  const fetchPage = (page: number) => {
    const cursor = cursorPages[`${page}`] || null

    fetchHistory({variables: {cursor, filter}}).then(({data}) => {
      if (data.dealHistory.length > 0) {
        const lastMatch: any = _.last(data.dealHistory)
        const nextCursor = lastMatch?.deal?.createdAt || lastMatch?.match?.createdAt
        updateCursorForPage(page + 1, nextCursor)
      }

      setCurrentDealHistory(data.dealHistory)
    })
  }

  const setPageAndUrl = (newPage: number) => {
    searchParams.set('page', newPage.toString())
    navigate(`?${searchParams.toString()}`, {replace: false})
    setPage(newPage)
  }

  const updateCursorForPage = (pageIndex: number, cursor: string | null) => {
    setCursorPages(prev => {
      // Create a new copy of the cursorPages object to avoid mutation
      const newPages = {...prev}

      // Only update if the cursor for the page is different or doesn't exist
      if (!newPages.hasOwnProperty(`${pageIndex}`)) {
        newPages[`${pageIndex}`] = cursor
      }

      return newPages
    })
  }

  // Pagination - next page
  const nextPage = () => {
    if (page < totalPages[filter]) {
      setPageAndUrl(page + 1)
      dealHistoryRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }

  const previousPage = () => {
    if (page > 1) {
      setPageAndUrl(page - 1)
      dealHistoryRef.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }

  // TabBar hookup
  function handleTabChange(tab: TabValues) {
    updateFilter(tab, null)
  }

  const [{deals}, dispatch] = useReducer(dealflowReducer, {
    deals: [],
  })

  const [currentDeal, setCurrentDeal] = useState(-1)
  const [markViewed] = useMarkViewed()

  const touchStart = useRef(null)
  const touchEnd = useRef(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50

  const onTouchStart = (e: any) => {
    touchEnd.current = null // otherwise the swipe is fired even with usual touch events
    touchStart.current = e.targetTouches[0].clientX
  }

  const onTouchMove = (e: any) => (touchEnd.current = e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return

    if (touchStart.current && touchEnd.current) {
      const distance = touchStart.current - touchEnd.current
      const isLeftSwipe = distance > minSwipeDistance
      const isRightSwipe = distance < -minSwipeDistance
      if (isLeftSwipe || isRightSwipe) {
        isLeftSwipe ? nextDeal() : previousDeal()
      }
    }
  }

  function nextDeal() {
    if (currentDeal < deals.length - 1) {
      setCurrentDeal(currentDeal + 1)
    }
    const deal = deals[currentDeal + 1]
    if (deal) {
      markViewed({
        variables: {
          id: deal.deal?.id || deal.match?.id,
          type: deal.type,
        },
      }).catch(e => console.log(e))
    }
  }

  function previousDeal() {
    if (currentDeal >= 0) {
      setCurrentDeal(currentDeal - 1)
    }
  }

  const {loading, error} = useQuery(GET_DEALS, {
    onCompleted: data => {
      if (!data || !data.dealCarousel || (data && data.dealCarousel && currentDeal >= data.dealCarousel.length))
        setCurrentDeal(-1)

      const deals = data.dealCarousel || []

      dispatch({
        type: 'set_deals_loaded',
        // TODO: encapsulate requestIntro switching throughout this file
        deals: deals.map((deal: any) => ({
          ...deal,
          data: deal?.deal?.data || deal?.match?.data,
          profile: deal.match?.data || deal.deal?.data,
        })),
      })
    },
  })

  const [currentDealHistory, setCurrentDealHistory] = useState<any[]>([])
  const {error: historyError, fetchMore: fetchHistory} = useQuery(GET_DEAL_HISTORY, {
    variables: {filter, cursor: null},
    onCompleted: data => {
      if (data.dealHistory.length > 0) {
        const lastMatch: any = _.last(data.dealHistory)
        const nextCursor = lastMatch?.deal?.createdAt || lastMatch?.match?.createdAt
        updateCursorForPage(1, null)
        updateCursorForPage(2, nextCursor)
      }
      setCurrentDealHistory(data.dealHistory)
    },
  })

  const hasPreviousPage = page > 1
  const hasNextPage = page < totalPages[filter]

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [currentMatch, setCurrentMatch] = React.useState<any>({})
  const messagePopup = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'message-popper' : undefined

  useEffect(() => {
    const urlPage = parseInt(searchParams.get('page') || '1')
    if (urlPage !== page) {
      setPage(urlPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    fetchPage(page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter])

  if (error) {
    console.error(error.message)
  }
  if (meError) {
    console.error(meError.message)
  }
  if (historyError) {
    console.error(historyError.message)
  }
  if (pageCountsError) {
    console.error(pageCountsError.message)
  }

  if (loading) {
    return (
      <Box>
        <Header />
        <Modal open={loading} aria-label="Loading Profile">
          <Box sx={styles.modal}>
            <CircularProgress color="primary" />
          </Box>
        </Modal>
      </Box>
    )
  }

  const progressNumber = ((currentDeal + 1) / deals.length) * 100
  const progressMessage = ''

  return (
    <DealFlowContext.Provider value={{deals}}>
      <DealFlowDispatchContext.Provider value={dispatch}>
        <Box>
          <Header />
        </Box>
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null)
          }}>
          <Popper id={id} open={open} anchorEl={anchorEl} placement="left-end">
            <Box sx={{marginRight: '50px'}}>
              <MessageModal
                match={currentMatch}
                close={() => {
                  setAnchorEl(null)
                }}
              />
            </Box>
          </Popper>
        </ClickAwayListener>
        <Grid
          container
          sx={{
            backgroundColor: colors.background[4],
            borderRadius: {md: '100px 100px 0 0', xs: '30px 30px 0 0'},
            // height: '60rem',
            paddingBottom: '2.31rem',
            paddingTop: '2.56rem',
            overflowX: 'clip',
          }}>
          <Grid container>
            <Grid container item direction="row" justifyContent={{xs: 'space-between', md: 'center'}}>
              <Grid item columns={{xs: 4, md: 12}} sx={{paddingLeft: '15px'}}>
                <Typography variant="h2" sx={{color: colors.evergreen[130]}}>
                  Deal Flow
                  <Typography variant="title2medium" sx={{color: colors.evergreen[100], marginLeft: '1rem'}}>
                    ({deals.length})
                  </Typography>
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{display: {xs: 'inline-block', md: 'none'}}}
                direction="row"
                justifyContent="center">
                <Box sx={{display: 'inline-block', height: '40px', width: '35px'}}>
                  <StyledLeftArrow onClick={previousDeal} enabled={currentDeal >= 0} width="30" height="30" />
                </Box>
                <Box sx={{display: 'inline-block', height: '40px', marginLeft: '20px', width: '35px'}}>
                  <StyledRightArrow
                    onClick={nextDeal}
                    enabled={currentDeal < deals.length - 1}
                    width="30"
                    height="30"
                  />
                </Box>
              </Grid>
            </Grid>
            {/* */}
            <Grid container item justifyContent="center">
              <Grid
                container
                item
                justifyContent="center"
                direction="column"
                alignItems="center"
                md={1.5}
                sx={{display: {xs: 'none', md: 'flex'}}}>
                <Grid item>
                  <StyledLeftArrow onClick={previousDeal} enabled={currentDeal >= 0} />
                </Grid>
              </Grid>
              <Grid item alignItems="center">
                <DealCard
                  backgroundColor="transparent"
                  sx={{
                    display: -1 === currentDeal ? '' : 'none',
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                  }}
                  onTouchStart={onTouchStart}
                  onTouchMove={onTouchMove}
                  onTouchEnd={onTouchEnd}>
                  <InitialCardContent
                    deals={deals}
                    historyPages={totalPages[filter]}
                    me={{
                      verificationStatus: meData?.profile?.profileReviewStatus.value,
                      orgId: meData?.profile?.owner.id,
                    }}
                  />
                </DealCard>
                {deals.map((deal, index) => {
                  return (
                    <FocusedCard
                      key={index}
                      prev={previousDeal}
                      next={nextDeal}
                      deal={deal}
                      sx={{display: index === currentDeal ? '' : 'none'}}
                    />
                  )
                })}
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                direction="column"
                alignItems="center"
                md={1.5}
                sx={{display: {xs: 'none', md: 'flex'}}}>
                <Grid item>
                  <StyledRightArrow onClick={nextDeal} enabled={currentDeal < deals.length - 1} />
                </Grid>
              </Grid>
            </Grid>
            {progressNumber > 0 && (
              <Grid
                container
                item
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{marginTop: '20px'}}>
                <ProgressComponent progress={progressNumber} message={progressMessage} />
              </Grid>
            )}
            <Grid
              container
              item
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{marginTop: '20px'}}>
              <Grid item>
                <Typography variant="h6" sx={{color: colors.darkEvergreen[60]}}>
                  Scroll down for your history
                </Typography>
              </Grid>
              <Grid item sx={{marginTop: '28px'}}>
                <ChevronDown />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{border: '1px solid black none', marginTop: '67px', padding: {xs: '0 0 40px', lg: '0 290px 40px'}}}>
          <Grid container direction="column" justifyContent="flex-start">
            <Grid item>
              <Typography variant="h2">Deal flow history</Typography>
            </Grid>
            <Grid item>
              <Box sx={{marginTop: '16px', display: {xs: 'block', md: 'none'}}}>
                <MobileNavMenu
                  defaultValue={0}
                  sections={[{title: 'All'}, {title: 'Introduced'}, {title: 'Requested'}, {title: 'Sent'}]}
                  onClick={i => {
                    const tabs = ['all', 'introed', 'requested', 'sent'] as TabValues[]
                    handleTabChange(tabs[i])
                  }}
                />
              </Box>
            </Grid>
            <Grid item>
              <TabBar onChange={handleTabChange} counts={pageCounts?.dealHistoryPageMetadata} />
            </Grid>
            {currentDealHistory && (
              <Stack ref={dealHistoryRef}>
                {currentDealHistory.map(({deal, match, type}: {deal: any; match: any; type: 'match' | 'deal'}) => {
                  return (
                    <HistoryItem
                      key={deal?.id || match?.id}
                      deal={deal}
                      match={match}
                      me={meData}
                      type={type}
                      messageHandler={messagePopup}
                      setCurrentMatch={setCurrentMatch}
                    />
                  )
                })}
              </Stack>
            )}
            <Grid item>
              {totalPages[filter] > 0 && (
                <Stack justifyContent="center" direction="row" spacing={4} sx={{marginTop: '10px'}}>
                  <StyledLeftArrow
                    height="24"
                    width="24"
                    padding="6px"
                    onClick={() => previousPage()}
                    enabled={hasPreviousPage}
                  />
                  <Typography
                    variant="body3"
                    sx={{color: colors.darkEvergreen[100], fontSize: '16px', paddingTop: '10px'}}>
                    {page} / {totalPages[filter]}
                  </Typography>
                  <StyledRightArrow
                    height="24"
                    width="24"
                    padding="6px"
                    onClick={() => nextPage()}
                    enabled={hasNextPage}
                  />
                </Stack>
              )}
              {totalPages[filter] === 0 && (
                <Stack justifyContent="center" direction="row" spacing={4}>
                  <Typography
                    align="center"
                    variant="d2"
                    sx={{color: colors.darkEvergreen[100], fontSize: '76px', paddingTop: '104px'}}>
                    Soon this will be <br />
                    full of opportunity...
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </DealFlowDispatchContext.Provider>
    </DealFlowContext.Provider>
  )
}

import {gql, useApolloClient, useMutation, useQuery} from '@apollo/client'
import {Modal} from '@mui/base'
import {Alert, CircularProgress, Grid, Typography} from '@mui/material'
import Divider from '@mui/material/Divider'
import {Box, Stack, SxProps, styled} from '@mui/system'
import _ from 'lodash'
import {DateTime} from 'luxon'
import React, {PropsWithChildren, Ref, createContext, useContext, useEffect, useReducer, useRef, useState} from 'react'
import {Outlet, useNavigate, useParams} from 'react-router-dom'
import {CherubLogo} from './CherubLogo'
import {getPlaceholder} from './Directory'
import Footer from './Footer'
import Header, {MatchHeader} from './Header'
import {PinnedHeader} from './PinnedHeader'
import {useMeQuery} from './api/types'
import {AfterAuthLoads} from './auth/AfterAuthLoads'
import {RequiresAuthentication} from './auth/RequiresAuthentication'
import {useAuthRedirects} from './auth/useAuthRedirects'
import {useAuthentication} from './auth/useAuthentication'
import colors from './colors'
import {profileViewData, useMetricWhenRendered, useTrackedExternalLink} from './common/helpers'
import {useSectionEngagementRef} from './common/useSectionEngagement'
import FloatingBanner from './components/FloatingBanner'
import YourFit from './components/YourFit'
import {
  MatchStatusContext,
  MatchStatusDispatchContext,
  matchStatusReducer,
  useMatchStatusContext,
} from './deal/Deal.Contexts'
import MobileNavMenu from './profile-view/MobileNavMenu'
import {DisplayedTags} from './profile-view/displayed-tags'
import Profile from './profile/Profile'
import {CherubModal} from './profile/Profile.CherubModal'
import {
  ProfileDataType,
  ProfileFormContext,
  ProfileFormDispatchContext,
  nullProfile,
  profileFormReducer,
  useProfileContext,
} from './profile/Profile.Contexts'
import {SectionDivider} from './profile/Profile.Section'
import TextField, {PreviewFor} from './profile/Profile.TextField'
import CherubTooltip from './profile/Profile.Tooltip'
import {externalLink} from './profile/utils'
import {PrimaryButton, SecondaryButton} from './ui/Buttons'
import {BoundedImage, Card, CardCaraousel, CardRow} from './ui/Cards'
import {Close, NorthEast} from './ui/icons'

export const route = {
  path: '/profile',
  element: <ProfileBase />,
  children: [
    {
      path: ':orgId',
      // TODO: this should permit non authed views and the backend should minimize the data
      element: (
        <AfterAuthLoads>
          <ProfileView />
        </AfterAuthLoads>
      ),
    },
    {
      // TODO: while perms block it backend, we should disallow
      //       this route from being navigated to outside of your ID if you are a superadmin
      path: ':orgId/edit',
      element: (
        <RequiresAuthentication>
          <Profile />
        </RequiresAuthentication>
      ),
    },
  ],
}

function ProfileBase(): React.JSX.Element {
  return <Outlet />
}

const profileQuery = `profile(owner: $orgId) {
  id
  data
  profileType
  creator {
    id
  }
  owner {
    id
    name
  }
  updatedAt
  files
  truncated
  tags {
    matchCharacteristics {
        displayName
        id
        name
        slug
    }
    matchIndustries {
        displayName
        id
        name
        slug
    }
    matchTargets {
        displayName
        id
        name
        slug
    }
  }
}`

const GET_PROFILE = gql`
  query GetProfile($orgId: ID) {
    ${profileQuery}
  }
`

const GET_MATCH_STATUS = gql`
  query Match($wanted: ID!) {
    match(wanted: $wanted) {
      matchingTags
      deal: match {
        id
        data
        recommendedFor
        suggestedProfile
        requestIntro
        initiatingProfile {
          id
        }
        targetProfile {
          id
        }
      }
      requestsRemaining
      matched
    }
  }
`

const CREATE_MATCH = gql`
  mutation MatchIntro($id: ID!) {
    deals {
      userMatchIntro(input: {requestedId: $id}) {
        errors
        success
      }
    }
  }
`

const StyledSeparator = function (props: any) {
  return (
    <Box
      sx={_.merge(
        {
          backgroundColor: `${props.backgroundColor || colors.background[5]}`,
        },
        props.sx,
      )}>
      <StyledHR sx={{margin: {md: '0 0 0 17.5rem', xs: '0'}}} />
    </Box>
  )
}

type Section = {
  title: string
  element: JSX.Element
  noBlur?: boolean
  sx?: SxProps
  skipIf?: (profile: ProfileDataType) => boolean
  separator?: JSX.Element
  anchor: string
}

const founderSections: Array<Section> = [
  {
    title: 'Overview',
    element: <ProfileHeader />,
    sx: {
      paddingRight: {xs: '1.75rem', md: '7.5rem'},
    },
    noBlur: true,
  },
  {
    title: 'Top Metrics',
    sx: {
      paddingTop: '3.38rem',
      paddingBottom: '3.75rem',
      paddingRight: {xs: '1.75rem', md: '7.5rem'},
    },
    skipIf: (profile: any) => !profile?.metrics,
    element: <TopMetrics />,
  },
  {
    title: 'Key products',
    sx: {
      bgcolor: colors.background['dark section'],
      paddingTop: '3.38rem',
      paddingBottom: '3.75rem',
      paddingRight: {xs: '1.75rem'},
    },
    skipIf: (profile: any) => !profile?.products,
    element: <KeyProducts />,
  },
  {
    title: 'The Pitch',
    sx: {
      backgroundColor: colors.darkEvergreen[100],
      paddingTop: '3.38rem',
      paddingBottom: '3.75rem',
      paddingRight: {xs: '1.75rem'},
    },
    skipIf: (profile: any) => !profile?.pitch && !profile?.pitch_materials,
    element: <PitchMaterials />,
  },
  {
    title: "Who's in",
    sx: {
      backgroundColor: colors.background[2],
      paddingTop: {xs: '1.55rem', md: '4rem'},
      paddingBottom: {xs: '2.125rem', md: '5.95rem'},
      paddingRight: {xs: '1.75rem', md: 0},
    },
    skipIf: (profile: any) => !profile?.active_investors,
    element: <WhosIn />,
    separator: <StyledSeparator backgroundColor={colors.background[2]} />,
  },
  {
    title: "What we're looking for",
    sx: {
      backgroundColor: colors.background[2],
      paddingTop: {xs: '2.125rem', md: '3.7rem'},
      paddingBottom: {xs: '2.125rem', md: '3.55rem'},
    },
    element: <LookingFor />,
    skipIf: (profile: any) => !profile?.matches?.looking_for,
    separator: <StyledSeparator backgroundColor={colors.background[2]} />,
  },
  {
    title: 'The Raise',
    sx: {
      backgroundColor: colors.background[2],
      paddingTop: {xs: '2.125rem', md: '3.3rem'},
      paddingBottom: {xs: '2.125rem', md: '3.7rem'},
      paddingRight: {xs: '1.75rem', md: '7.5rem'},
    },
    element: <TheRaise />,
    skipIf: (profile: any) => !profile?.raises,
    separator: <StyledSeparator backgroundColor={colors.background[2]} sx={{paddingRight: {md: '7.5rem'}}} />,
  },
  {
    title: 'Featured in...',
    sx: {
      backgroundColor: colors.background[2],
      paddingTop: {xs: '1.75rem', md: '3.1rem'},
      paddingBottom: {xs: '1.75rem', md: '3.55rem'},
      paddingRight: {xs: '1.75rem', md: 0},
    },
    element: <FeaturedIn />,
    skipIf: (profile: any) => !profile?.featured_articles,
    separator: <StyledSeparator backgroundColor={colors.background[2]} />,
  },
  {
    title: 'The Team',
    sx: {
      backgroundColor: colors.background[2],
      paddingTop: {xs: '2.25rem', md: '3.1rem'},
      paddingBottom: {xs: '1.75rem', md: '3.6rem'},
      paddingRight: {xs: '1.75rem', md: 0},
    },
    skipIf: (profile: any) => !profile?.organization_contact && !profile?.organization_members,
    element: <TheTeam />,
  },
  /* {
   *  title: 'Q & A'
   *  separator: <StyledSeparator backgroundColor={colors.background[2]} />,
   * },
   */
  {
    title: 'Company Details',
    sx: {
      backgroundColor: colors.background['warm white'],
      paddingTop: {xs: '1rem', md: '2rem'},
      paddingBottom: {xs: '3.75rem', md: '7.5rem'},
      paddingLeft: {xs: '1.75rem', md: '7.5rem'},
      paddingRight: {xs: '1.75rem', md: '7.5rem'},
      zIndex: {md: 150},
    },
    skipIf: (profile: any) =>
      !profile?.company_details && !profile?.company_social_links && !profile?.company_social_links_others,
    element: <CompanyDetails />,
  },
].map(s => ({...s, anchor: `${_.kebabCase(s.title)}-section`}))

const investorSections: Array<Section> = [
  {
    title: 'Overview',
    element: <ProfileHeader />,
    noBlur: true,
  },
  {
    title: 'About me',
    sx: {
      bgcolor: colors.darkEvergreen[100],
      padding: '2rem 0',
    },
    skipIf: (profile: any) => !profile?.about,
    element: <AboutMe />,
  },
  {
    title: "What I'm looking for",
    sx: {
      backgroundColor: colors.background[1],
      paddingTop: {md: '3.1rem'},
      paddingBottom: {md: '3.55rem'},
    },
    element: <LookingFor />,
    skipIf: (profile: any) => !profile?.matches?.looking_for,
    separator: <StyledSeparator backgroundColor={colors.background[2]} />,
  },
  {
    title: 'Key investments',
    sx: {
      backgroundColor: colors.background[1],
      paddingTop: {md: '3.1rem'},
      paddingBottom: {md: '3.55rem'},
    },
    element: <KeyInvestments />,
    skipIf: (profile: any) => !profile?.investing_experience && !profile?.investing_history,
    separator: <StyledSeparator backgroundColor={colors.background[2]} />,
  },
  {
    title: 'Featured in...',
    sx: {
      backgroundColor: colors.background[1],
      paddingTop: {md: '3.1rem'},
      paddingBottom: {md: '3.55rem'},
    },
    skipIf: (profile: any) => !profile?.featured_articles,
    element: <FeaturedIn />,
  },
].map(s => ({...s, anchor: `${_.kebabCase(s.title)}-section`}))

const styles: Record<string, SxProps> = {
  modal: {
    background: colors.transparent[10],
    position: 'fixed',
    zIndex: '1300px',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

const NavigationIcon = styled('div')<{focused?: boolean}>(
  ({theme, focused}) => `
  visibility: ${focused ? 'visible' : 'hidden'};

  color: #000;
  text-align: center;
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
`,
)

const NavigationItem = styled('div')<{focused?: boolean}>(
  ({theme, focused}) => `
  color: ${focused ? colors.evergreen[130] : colors.darkEvergreen[60]};
  cursor: pointer;
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 17.6px */
`,
)

const ProfileImagePort = styled('div')(
  ({theme}) => `
  width: 120px;
  max-width: 120px;
`,
)

const ProfileImagePreview = styled('div')(
  ({theme}) => `
  border-radius: 120px;
  border: 5px solid ${colors.background[4]};
  background: ${colors.background.white};


  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`,
)

const ProfileImage = styled('div')<{url: string; 'aria-label': string}>(
  ({theme, url}) => `
  border-radius: 110px;
  background-image: url("${url}");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  height: 110px;
  width: 110px;
`,
)

const ProfileWebsiteLink = styled('a')(
  ({theme}) => `
  color: ${colors.evergreen[100]};
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.32px;
  text-decoration-line: underline;

  &:visited {
    color: ${colors.evergreen[100]};
  }
`,
)

const NorthEastLink = styled('a')(
  ({theme}) => `
  border: 1px solid ${colors.evergreen[100]};
  border-radius: 100px;
  display: inline-flex;
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-weight: 400;
  gap: 10px;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.32px;
  padding: 2px 7px 11px 6px;
  text-decoration-line: underline;

  &:visited {
    color: ${colors.evergreen[100]};
  }
`,
)

const ProfileTagline = styled('div')(
  ({theme}) => `
  color: ${colors.darkEvergreen[60]};
  font-family: "Warnock-Pro";
  font-size: 46px;
  font-style: italic;
  font-weight: 300;
  line-height: 110%; /* 61.6px */
  letter-spacing: -1.68px;
`,
)

const ProfileHighlights = styled('div')(
  ({theme}) => `
  color: ${colors.darkEvergreen[100]};
  font-family: "Aktiv-Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
`,
)

const SectionTitleDarkGreen = function (props: PropsWithChildren<{sx?: SxProps; ref?: Ref<HTMLDivElement>}>) {
  return (
    <Box
      ref={props.ref}
      sx={_.merge(
        {
          color: {xs: colors.darkEvergreen[60], md: colors.darkEvergreen[100]},
          fontFamily: 'Aktiv-Grotesk',
          fontSize: {md: '2rem', xs: '1rem'},
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '130%',
        },
        props.sx,
      )}>
      {props.children}
    </Box>
  )
}

const SectionTitlelightGreen = styled('div')(
  ({theme}) => `
  color: ${colors.evergreen[10]};
  font-family: "Aktiv-Grotesk";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 41.6px */
`,
)

const PitchPoster = styled('div')(
  ({theme}) => `
  
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 41.6px */
`,
)

function KeyMetric({metric, variant}: {metric: {value: string; description: string}; variant?: string}) {
  return (
    <Box sx={{marginBottom: {xs: '1.05rem', md: '2.10rem'}}}>
      <Box
        sx={{
          textTransform: 'uppercase',
          typography: variant || 'd4',
          color: colors.darkEvergreen[100],
          fontFamily: 'Campaign',
          fontWeight: 900,
        }}>
        {metric.value}
      </Box>
      <Box
        sx={{
          textTransform: 'uppercase',
          typography: variant || 'd4',
          color: colors.darkEvergreen[60],
          fontFamily: 'Campaign',
          fontWeight: 900,
        }}>
        {metric.description}
      </Box>
    </Box>
  )
}

const TagChip = styled('div')(
  ({theme}) => `
  border-radius: 20px;
  background: ${colors.background[4]};
  
  align-items: center;
  padding: 12px 20px;

  color: ${colors.darkEvergreen[100]};
  text-align: center;
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  pointer: cursor;
`,
)

const ProfileModuleContainer = function (props: any) {
  return (
    <Box
      id={props.id}
      key={props.key}
      display="flex"
      sx={{
        paddingLeft: {xs: '1.5rem', md: '17.5rem'},
        paddingRight: {md: '7.5rem'},
        ...props?.sx,
      }}>
      {props.children}
    </Box>
  )
}

function dedupeTags(set1: string[], set2: string[]): string[] {
  if (!set1 && !set2) {
    return []
  } else if (!set1) {
    return set2
  } else if (!set2) {
    return set1
  }

  const deduped = new Set([...set1, ...set2])
  return Array.from(deduped)
}

const IntroModalContext = createContext<any>({})
const MeDataContext = createContext<any>({})

function IntroModal(props: any) {
  const {
    form: {profile, metadata},
  } = useProfileContext()

  const {match} = useMatchStatusContext()

  const introModalState = props.introModalState
  const {setIntroModalState}: any = useContext(IntroModalContext)
  const currentOrgId = metadata.orgId || ''

  const [introMsg, setIntroMsg] = useState('')
  const [introEmpty, setIntroEmpty] = useState(true)

  const updatedRequestsRemaining = Math.max(match.requestsRemaining - 1, 0)

  const [requestIntro] = useMutation(gql`
    mutation DealsRequestIntro($id: ID!, $notes: String!) {
      deals {
        userRequestIntro(input: {requestedId: $id, notes: $notes}) {
          errors
          success
        }
      }
    }
  `)

  // using metadtata to determine if the profile is logged out
  const data =
    metadata.profileType === 'founder'
      ? {
          photo: profile?.company?.logo,
          name: profile?.company?.name,
          summary: profile?.company?.tagline,
        }
      : {
          photo: profile?.investor?.headshot,
          name: `${profile?.investor?.first_name ?? ''} ${profile?.investor?.last_name ?? ''}`,
          summary: profile?.investor?.headline,
        }

  function updateIntroMsg(e: any) {
    let msg = e.target.value || ''
    setIntroMsg(msg)
    setIntroEmpty(msg.trim().length === 0)
  }

  function requestIntroClick() {
    const vars = {id: metadata.orgId && parseInt(metadata.orgId, 10), notes: introMsg}

    requestIntro({
      variables: vars,
      onCompleted: res => {
        if (res?.deals?.userRequestIntro?.success) {
          if (setIntroModalState != null) {
            setIntroModalState((prevState: any) => Object.assign({}, prevState, {view: 'introSent'}))
          }
        }
      },
    })
  }

  let modalContent = <></>
  if (introModalState.view === 'introForm') {
    modalContent = (
      <Stack sx={{width: '325px'}}>
        <Typography variant="h3" sx={{color: colors.darkEvergreen[100], textAlign: 'left'}}>
          Get intro
        </Typography>
        <Typography
          variant="body1"
          sx={{color: colors.darkEvergreen[75], fontSize: '16px', margin: '10px 0 20px 0', textAlign: 'left'}}>
          You have {match.requestsRemaining} requests left this week. You are requesting an intro {data?.name}.<br />{' '}
          They will review and respond if interested.
        </Typography>
        <SectionDivider />
        <Typography variant="body1" sx={{color: colors.darkEvergreen[100], margin: '20px 0', textAlign: 'left'}}>
          Add a note to personalize your request to {data?.name}
        </Typography>
        <Box sx={{margin: '0 0 20px 0'}}>
          <TextField
            aria-label=""
            placeholder="Hi, I think your experience would align perfectly with what I'm looking for. Would love to set aside some time to chat!"
            value={introMsg}
            onChange={updateIntroMsg}
            property="introNote"
            label="Message"
            sizes={{xs: 12}}
            type="text"
            extra={{maxLength: 300, textArea: {rows: 10}}}
          />
        </Box>
        <SectionDivider />
        <Grid item sx={{marginTop: '20px'}}>
          <SecondaryButton
            onClick={() => {
              setIntroModalState((prevState: any) => Object.assign({}, prevState, {open: false, view: 'introForm'}))
            }}
            size="large">
            Cancel
          </SecondaryButton>{' '}
          <PrimaryButton size="large" disabled={introEmpty} onClick={requestIntroClick}>
            Send
          </PrimaryButton>
        </Grid>
      </Stack>
    )
  } else if (introModalState.view === 'introSent') {
    modalContent = (
      <Stack sx={{width: '325px'}}>
        <Typography variant="h3" sx={{color: colors.darkEvergreen[100], textAlign: 'left'}}>
          Request sent!
        </Typography>
        <Typography variant="body1" sx={{color: colors.darkEvergreen[100], margin: '20px 0', textAlign: 'left'}}>
          You have {updatedRequestsRemaining} requests left this week.
        </Typography>

        <Typography
          variant="body1"
          sx={{color: colors.darkEvergreen[75], fontSize: '16px', margin: '10px 0 20px 0', textAlign: 'left'}}>
          Your Get Intro requests will reset every Thursday.
          <br />
          We limit requests to 5 a week in order to ensure everyone gets thoughtful, high quality leads.
          <br />
          <a href="https://intercom.help/cherub/en/articles/9519301-how-do-intro-requests-work">Learn more</a>
        </Typography>

        <SectionDivider />
        <SecondaryButton
          sx={{marginTop: '20px'}}
          onClick={() => {
            setIntroModalState((prevState: any) => Object.assign({}, prevState, {open: false, view: 'introForm'}))
            window.location.reload()
          }}
          size="large">
          Done
        </SecondaryButton>
      </Stack>
    )
  } else if (introModalState.view === 'successMatch') {
    let matchName = match.deal?.data.name
    if (match.deal?.recommendedFor === currentOrgId) {
      matchName = match.deal?.data.other.name
    }

    modalContent = (
      <Stack sx={{width: '325px'}}>
        <Grid container direction="row" spacing={2} sx={{height: '112px'}}>
          {/* <Grid item xs={6} ><img alt={match.deal?.data.name} src={match.deal?.data.hero_url}/></Grid>
          <Grid item xs={6}><img alt={match.deal?.data.other.name} src={match.deal?.data.other.hero_url}/></Grid> */}
        </Grid>
        <Typography variant="h3" sx={{color: colors.darkEvergreen[100], marginTop: '30px', textAlign: 'left'}}>
          Its a match!
        </Typography>

        <Typography
          variant="body1"
          sx={{color: colors.darkEvergreen[75], fontSize: '16px', margin: '10px 0 20px 0', textAlign: 'left'}}>
          Congratulations, you and {matchName} have been introduced via Cherub Email.
          <br />
          <br />
          We'll check in to see how things went in a few days.
        </Typography>

        <SectionDivider />
        <SecondaryButton
          sx={{marginTop: '20px'}}
          onClick={() => {
            setIntroModalState((prevState: any) => Object.assign({}, prevState, {open: false, view: 'introForm'}))
            window.location.reload()
          }}
          size="large">
          Done
        </SecondaryButton>
      </Stack>
    )
  }
  // } else if (introModalState.view === 'feedback') {
  //   modalContent = (
  //     <Stack sx={{width:'325px'}}>
  //       <FeedbackForm  matchId={match?.deal.id} orgId={metadata?.orgId || ''} tags={match.matchingTags} />
  //     </Stack>
  //   )
  // }

  return (
    <Box
      sx={{
        zIndex: 10000,
        position: 'absolute',
        left: '0px',
        height: '100vw',
        width: '100vw',
        backdropFilter: 'blur(30px)',
      }}>
      <PinnedHeader backgroundColor="transparent">
        <div>
          <CherubLogo />
          <Typography
            sx={{
              fontSize: '.75rem',
              fontFamily: 'Warnock-Pro',
              display: 'inline-block',
              marginLeft: '0.25rem',
              height: '100%',
              position: 'relative',
              top: '-14px',
            }}>
            Beta
          </Typography>
        </div>
        <Close
          style={{position: 'absolute', top: 8, right: 8, cursor: 'pointer', color: 'black'}}
          aria-label="Close"
          onClick={() => setIntroModalState((prevState: any) => Object.assign({}, prevState, {open: false}))}
        />
      </PinnedHeader>
      <Grid container direction="column" justifyContent="flex-start" alignItems="center">
        <Grid item xs={4}>
          {modalContent}
        </Grid>
      </Grid>
    </Box>
  )
}

function ProfileHeader() {
  const {
    form: {profile, currentFiles, metadata, isOwnProfile},
  } = useProfileContext()
  const {match} = useMatchStatusContext()
  const [isOpen, setIsOpen] = useState(false)

  const {setIntroOutOfView}: any = useContext(IntroModalContext)

  const meData = useContext(MeDataContext)

  const introButtonRef = useRef(null)

  const currentOrgId = metadata.orgId || ''
  const navigate = useNavigate()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // setIntroOutOfView(!entry.isIntersecting)
        setIntroOutOfView(entry.intersectionRatio < 1)
      },
      {threshold: [0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.1, 0.15, 0.25, 0.5, 0.75, 1]},
    )

    if (introButtonRef.current) {
      observer.observe(introButtonRef.current)
    }

    return () => {
      if (introButtonRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(introButtonRef.current)
      }
    }
  })

  const matchTuple = useMatchState(match, currentOrgId, meData)

  let skipPitch = false

  let pitchIndex = 0

  for (let i = 0; i < founderSections.length; i++) {
    if (founderSections[i].title === 'The Pitch') {
      if (!profile?.pitch && !profile?.pitch_materials) {
        skipPitch = true
        break
      }
      pitchIndex = i

      break
    }
  }

  function scrollToPitch() {
    const section = founderSections[pitchIndex]
    const element = document.getElementById(section.anchor)
    window.scrollTo({top: element!.offsetTop - 120, behavior: 'smooth'})
  }

  const {setIntroModalState}: any = useContext(IntroModalContext)

  // using metadtata to determine if the profile is logged out
  const data =
    metadata.profileType === 'founder'
      ? {
          ...profileViewData(metadata, profile),
          cta: matchTuple.ctaState.text,
          ctaOnClick: matchTuple.ctaState.action,
          ctaDisabled: matchTuple.locked ? false : matchTuple.ctaState.disabled,
          showCTA: !isOwnProfile && !metadata.truncated,
          showSecondary: !skipPitch && !metadata.truncated,
          secondaryCtaText: 'View Pitch',
          secondaryOnClick: scrollToPitch,
          showFeedback: false,
          feedbackOnClick: () =>
            setIntroModalState((prevState: any) => Object.assign({}, prevState, {open: true, view: 'feedback'})),
          feedbackText: 'Leave Feedback',
        }
      : {
          ...profileViewData(metadata, profile),
          cta: matchTuple.ctaState.text,
          ctaOnClick: matchTuple.ctaState.action,
          showCTA: !isOwnProfile && !metadata.truncated,
          ctaDisabled: matchTuple.ctaState.disabled,
          showSecondary: false,
          secondaryCtaText: null,
          secondaryOnClick: null,
          showFeedback: match && match?.deal,
          feedbackOnClick: () =>
            setIntroModalState((prevState: any) => Object.assign({}, prevState, {open: true, view: 'feedback'})),
          feedbackText: 'Leave Feedback',
        }

  const photoKey = data?.photo?.key && currentFiles[data.photo.key]
  const imgUrl = photoKey ?? getPlaceholder(metadata.profileType, Math.floor(Math.random() * 11))

  return (
    <Stack sx={{paddingRight: {md: '7.5rem'}, paddingBottom: {xs: '2.25rem'}}}>
      <Stack spacing={1}>
        <ProfileImagePort>
          <ProfileImagePreview>
            <ProfileImage url={imgUrl} aria-label="Company Logo" />
          </ProfileImagePreview>
        </ProfileImagePort>
        {data?.name && <Box sx={{typography: 'd1', color: colors.evergreen[100]}}>{data.name}</Box>}
      </Stack>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={6}>
          <Stack ref={introButtonRef} direction="row" spacing={5} sx={{marginTop: 5, marginBottom: 5}}>
            <CherubModal open={isOpen} omitSpacer={true} onClose={() => setIsOpen(false)}>
              <Grid container direction="column" sx={{backgroundColor: '#FDF8F0'}}>
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" fontWeight={500}>
                    Upgrade Your Plan
                  </Typography>
                </Grid>

                <Grid item sx={{marginTop: 5, marginBottom: 2}}>
                  <Typography variant="body2" color="textSecondary">
                    This feature is only available for Cherub Raise users. Upgrade your plan to unlock intro requests,
                    track profile views & clicks, and boost your fundraising visibility.
                  </Typography>
                </Grid>

                <Grid item>
                  <PrimaryButton
                    size="large"
                    onClick={() => {
                      navigate('/accounts')
                    }}>
                    Learn More
                  </PrimaryButton>
                </Grid>
              </Grid>
            </CherubModal>
            {data.showCTA && (
              <PrimaryButton
                size="large"
                disabled={matchTuple.locked === true ? false : matchTuple.ctaState.disabled}
                onClick={
                  matchTuple.locked === true
                    ? () => {
                        setIsOpen(true)
                      }
                    : data.ctaOnClick
                }>
                {data.cta}
              </PrimaryButton>
            )}
            {data.showSecondary && (
              <SecondaryButton size="large" onClick={data.secondaryOnClick}>
                {data.secondaryCtaText}
              </SecondaryButton>
            )}
            {/* {data.showFeedback && (
              <SecondaryButton size="large" onClick={data.feedbackOnClick}>
                {data.feedbackText}
              </SecondaryButton>
              )} */}
          </Stack>
          {data?.links &&
            data.links.map((link: string, i: number) => (
              <TrackedLink
                key={`${i}-${link}`}
                component={ProfileWebsiteLink}
                section={'ProfileLinks'}
                url={link}
                targetId={metadata.orgId ?? ''}>
                {link} <NorthEast />
              </TrackedLink>
            ))}
          {data?.summary && (
            <Box sx={{marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}}}>
              <ProfileTagline>{data.summary}</ProfileTagline>
            </Box>
          )}
          {profile?.company?.highlights && (
            <Stack spacing={3}>
              {profile.company.highlights.map(({value}: {value: string}, i: number) => (
                <>
                  <ProfileHighlights key={i}>{value}</ProfileHighlights>
                  {i < profile.company.highlights.length - 1 && <Divider sx={{display: {xs: 'block', md: 'none'}}} />}
                </>
              ))}
            </Stack>
          )}
        </Grid>
        <Grid item sx={{display: {xs: 'none', md: 'block'}}} flexBasis="23.75rem">
          {data?.additionalPhoto?.key && currentFiles[data.additionalPhoto.key] && (
            <img
              src={currentFiles[data.additionalPhoto.key]}
              alt="Additional content"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          )}
        </Grid>
      </Grid>
      <Box sx={{marginTop: 12}}>
        <Grid container spacing={4}>
          {!isOwnProfile ? <YourFit title="Your Fit" tags={match.matchingTags} /> : null}
          <ProfileTagSection profile={profile} metadata={metadata} isOwnProfile={isOwnProfile} />
        </Grid>
      </Box>
      {/* <CherubsTake /> */}
      <Box sx={{display: {xs: 'block', md: 'none'}}}>
        {data?.additionalPhoto?.key && currentFiles[data.additionalPhoto.key] && (
          <img
            src={currentFiles[data.additionalPhoto.key]}
            alt="Additional content"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        )}
      </Box>
    </Stack>
  )
}

function TopMetrics() {
  const {
    form: {profile},
  } = useProfileContext()

  const numberOfMetrics = profile?.metrics?.length

  if (numberOfMetrics === 0) {
    return <></>
  }

  let variant = ''

  if (numberOfMetrics === 1) {
    variant = 'd3'
  } else if (numberOfMetrics < 4) {
    variant = 'd4'
  } else {
    variant = 'd5'
  }

  const metricElements = profile?.metrics?.map((metric: any) => <KeyMetric variant={variant} metric={metric} />)
  return (
    <Box>
      {profile?.metrics?.length > 0 && (
        <SectionTitleDarkGreen sx={{marginBottom: {xs: '.88rem'}}}>Top Metrics</SectionTitleDarkGreen>
      )}
      {metricElements}
    </Box>
  )
}

function KeyProduct(props: any) {
  const {
    form: {currentFiles, metadata},
  } = useProfileContext()
  const product = props.product
  const photo = currentFiles[product?.photo?.key]
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Box>
          <Box
            sx={{
              typography: 'd2',
              color: colors.darkEvergreen[100],
            }}>
            {product.name}
          </Box>
          <Box
            sx={{
              typography: 'd2',
              color: colors.darkEvergreen[60],
            }}>
            {props.product.description}
          </Box>
          <Box>
            <TrackedLink component={NorthEastLink} section={'KeyProducts'} url={product.link} targetId={metadata.orgId}>
              <NorthEast height="24px" width="24px" />
            </TrackedLink>
          </Box>
        </Box>
      </Grid>
      <Grid item md={4}>
        {photo && <BoundedImage src={photo} alt={product.name} />}
      </Grid>
    </Grid>
  )
}

function KeyProducts() {
  const {
    form: {profile},
  } = useProfileContext()

  return (
    <Grid container rowSpacing={6}>
      {profile?.products?.length > 0 && (
        <Grid item>
          <SectionTitleDarkGreen>Key Products</SectionTitleDarkGreen>
        </Grid>
      )}
      {profile?.products?.length > 0 && profile.products.map((product: any) => <KeyProduct product={product} />)}
    </Grid>
  )
}

const ProductSample = styled('div')(
  ({theme}) => `
  display: flex;
  padding: 3.25rem;
  align-items: flex-end;
  justify-content: space-between;
  gap: 2.5rem;
  flex-shrink: 0;

  border-radius: 1.25rem;
  border: 2px solid ${colors.background[3]};
  background: ${colors.background[3]};

  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 1.0625rem;
    padding: 2rem;
  }
`,
)

function TrackedLink(props: any) {
  const {component: LinkComponent, targetId, section, url} = props
  const meData = useContext(MeDataContext)
  let tId = targetId
  if (meData?.me?.orgId === targetId) {
    tId = ''
  }
  const trackedExternalLink = useTrackedExternalLink(section, {
    type: 'profile_interaction',
    targetId: tId ?? '',
    extra: {link: url, section: section},
  })

  if (!LinkComponent) {
    return (
      <a {...props} {...trackedExternalLink}>
        {props.children}
      </a>
    )
  }
  return (
    <LinkComponent {...props} {...trackedExternalLink}>
      {props.children}
    </LinkComponent>
  )
}

function PitchMaterials() {
  const {
    form: {profile, metadata, currentFiles},
  } = useProfileContext()
  const pitch = profile?.pitch || {}

  const externalLink = useTrackedExternalLink('PitchMaterials', {
    type: 'profile_interaction',
    targetId: metadata.orgId ?? '',
    extra: {link: pitch.document_url, section: 'PitchMaterials'},
  })

  return (
    <Grid container sx={{backgroundColor: colors.darkEvergreen[100]}} rowSpacing={5} columnSpacing={10}>
      <Grid item xs={12} md={12}>
        <SectionTitlelightGreen>The Pitch</SectionTitlelightGreen>
      </Grid>

      <Grid item md={12}>
        <PreviewFor value={pitch.video} name={pitch.video_title} style={{width: '63rem', height: '57rem'}} />
      </Grid>
      <Grid item md={6}>
        <Typography variant="h4" sx={{color: colors.background['warm white']}}>
          "{pitch.elevator}"
        </Typography>
      </Grid>
      <Grid item container md={6} spacing={7} justifyContent={{xs: 'space-between', md: 'start'}}>
        {currentFiles[pitch?.hero?.key] && (
          <Grid item xs={12}>
            <PitchPoster>
              <BoundedImage src={currentFiles[pitch?.hero?.key]} alt={pitch.title} />
            </PitchPoster>
          </Grid>
        )}
        <Grid item xs="auto">
          <Typography sx={{fontSize: '2rem', color: colors.darkEvergreen[5]}}>{pitch.title}</Typography>
        </Grid>
        <Grid item xs="auto">
          <NorthEastLink sx={{borderColor: colors.darkEvergreen[5]}} {...externalLink}>
            <NorthEast height="24px" width="24px" color={colors.darkEvergreen[5]} />
          </NorthEastLink>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {profile?.product_sample?.discount_code && (
          <ProductSample>
            <Box>
              <Box
                typography="body1"
                sx={{color: colors.evergreen[100], fontSize: {xs: '16px', md: '24px'}, marginBottom: 1}}>
                {profile?.product_sample?.title ?? ''}
              </Box>
              <Box typography="body2" sx={{color: colors.gray[2], fontSize: {xs: '16px', md: '20px'}}}>
                {profile?.product_sample?.description ?? ''}
              </Box>
            </Box>
            <SecondaryButton
              style={{flexShrink: 0}}
              size="large"
              onClick={() => navigator.clipboard.writeText(profile.product_sample.discount_code)}>
              Copy the code
            </SecondaryButton>
          </ProductSample>
        )}
      </Grid>
    </Grid>
  )
}

const InvestorCard = styled(Card)(
  ({theme}) => `
  box-sizing: border-box;
  min-width: 24.15rem;
  padding: 3.12rem;

  ${theme.breakpoints.down('md')} {
    min-width: 15.38rem;
    padding: 1.9rem;
  }
`,
)

const InvestorSocialLink = styled('a')(
  ({theme}) => `
  border-radius: 1.25rem;
  border: 2px solid ${colors.background['warm white']};
  color: ${colors.background['warm white']};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3.25rem;
  text-decoration: none;  
`,
)

function AboutMe() {
  const {
    form: {metadata, profile},
  } = useProfileContext()

  return (
    <Grid container rowSpacing={5} columnSpacing={4} justifyContent="space-between">
      <Grid item xs={12}>
        <SectionTitleDarkGreen sx={{color: colors.evergreen[40]}}>About me</SectionTitleDarkGreen>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="h3" sx={{color: colors.background['warm white'], textOverflow: 'ellipsis'}}>
          {profile?.about?.summary}
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        {profile?.about?.video && (
          <PreviewFor
            value={profile.about.video}
            name={profile.about.video_title}
            style={{height: '31.25rem', width: '63rem'}}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <SectionTitleDarkGreen sx={{color: colors.evergreen[40]}}>Find me</SectionTitleDarkGreen>
      </Grid>
      {profile?.investor_social_links &&
        _.map(profile.investor_social_links, (link: string, name: string) => (
          <Grid item xs={12} md={4} sx={{color: colors.background['warm white']}}>
            <TrackedLink
              component={InvestorSocialLink}
              url={link}
              section={'InvestorSocialLinks'}
              targetId={metadata.orgId}>
              <div>{_.capitalize(name)}</div>
              <div>
                <NorthEast />
              </div>
            </TrackedLink>
          </Grid>
        ))}
    </Grid>
  )
}

const TopIndustriesCard = styled(Card)(
  ({theme, background}) => `
  background: ${background};
  padding: 1.875rem;
`,
)

const KeyInvestmentCounter = styled('span')(
  ({theme}) => `
  border-radius: 1.46156rem;
  border: 1.169px solid var(--Evergreen-40, #9FB9B6);
  
  display: inline-block;

  color: rgba(0, 0, 0, 0.50);
  font-family: "Aktiv-Grotesk";
  font-weight: 400;
  font-size: 0.87694rem;
  font-style: normal;
  letter-spacing: 0.02631rem;
  
  line-height: 175%;
  text-align: center;

  height: 1.325rem;
  margin-right: 0.875rem;
  width: 1.325rem;
`,
)

function KeyInvestments() {
  const {
    form: {profile, currentFiles},
  } = useProfileContext()

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <SectionTitleDarkGreen>Key investments</SectionTitleDarkGreen>
      </Grid>
      <Grid item xs={12} md={4} display="flex">
        <TopIndustriesCard background={colors.background[4]}>
          <Stack spacing={3}>
            <Box typography="h5" color={colors.darkEvergreen[100]}>
              Top industries
            </Box>
            {_.map(profile?.investing_experience, (item: any, i: number) => (
              <Grid container key={i}>
                <Grid item xs={0}>
                  <KeyInvestmentCounter>{i + 1}</KeyInvestmentCounter>
                </Grid>
                <Grid item xs="auto" typography="h5" color={colors.darkEvergreen[100]}>
                  {item.top_industry}
                </Grid>
              </Grid>
            ))}
          </Stack>
        </TopIndustriesCard>
      </Grid>
      <Grid item xs={12} md={8}>
        <Stack spacing={5}>
          {profile?.investing_history?.map((item: any, i: number) => (
            <Card key={i}>
              <Grid container spacing={5}>
                <Grid item xs={3}>
                  {currentFiles[item?.photo?.key] && (
                    <BoundedImage src={currentFiles[item?.photo?.key]} alt="Investment" />
                  )}
                </Grid>
                <Grid item xs={9}>
                  <Stack spacing={2}>
                    <Box typography="h6" sx={{color: colors.background.black}}>
                      {item?.name}
                    </Box>
                    <Box typography="body1" sx={{color: colors.gray[1.5]}}>
                      {item?.tagline}
                    </Box>
                    <TagChip style={{width: 'fit-content'}}>{item?.industry}</TagChip>
                  </Stack>
                </Grid>
                {/* <SecondaryButton><Add /> Follow</SecondaryButton> */}
              </Grid>
            </Card>
          ))}
        </Stack>
      </Grid>
    </Grid>
  )
}

function Investor({investor}: {investor: any}) {
  return (
    <InvestorCard>
      <Box>
        <Typography
          variant="h4"
          sx={{
            color: colors.darkEvergreen[100],
            marginBottom: '2.5rem',
          }}>
          {investor.name}
        </Typography>
        <Grid item xs={12} md={12}>
          <Typography variant="body1medium" sx={{color: colors.darkEvergreen[100]}}>
            {investor.title}
          </Typography>
        </Grid>
        <Typography variant="body2medium" sx={{color: colors.darkEvergreen[60]}}>
          {investor.type}
        </Typography>
      </Box>
    </InvestorCard>
  )
}
function WhosIn() {
  const {
    form: {profile},
  } = useProfileContext()

  return (
    <Grid container>
      <Grid item xs={12}>
        <SectionTitleDarkGreen sx={{marginBottom: '2rem'}}>Who's in</SectionTitleDarkGreen>
      </Grid>
      <Grid item xs={12}>
        <CardCaraousel>
          {Array.isArray(profile?.active_investors) &&
            profile.active_investors.map((investor: any, i: number) => <Investor key={i} investor={investor} />)}
        </CardCaraousel>
      </Grid>
    </Grid>
  )
}

function LookingFor() {
  const {
    form: {profile, metadata},
  } = useProfileContext()

  if (!profile.matches || !profile.matches.looking_for) {
    return <></>
  }

  const copy = metadata.profileType === 'founder' ? "What we're looking for" : "What I'm looking for"

  return (
    <Grid>
      <Grid item xs={12} md={12}>
        <SectionTitleDarkGreen sx={{marginBottom: {xs: '0.62rem', md: '2.35rem'}}}>{copy}</SectionTitleDarkGreen>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography sx={{color: colors.darkEvergreen[60], 'font-size': {md: '2rem', xs: '1.125rem'}}}>
          {profile.matches.looking_for}
        </Typography>
      </Grid>
    </Grid>
  )
}

const RaiseCard = styled(Card)(
  ({theme}) => `
  background: ${colors.background[3]};

  max-width: 20rem;
  padding: 1.875rem;
`,
)

const RaiseTooltip = styled(CherubTooltip)(
  ({theme}) => `
  color: ${colors.evergreen[40]};
`,
)

const RaiseReasons = styled('div')(
  ({theme}) => `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  flex: 1 0 0;
`,
)

const RaiseReasonCard = styled('div')(
  ({theme}) => `
  border-radius: 1.25rem;
  border: 2px solid ${colors.background[3]};
  background: ${colors.background[2]};

  align-items: flex-start;
  align-self: stretch;
  flex-direction: row;
  display: flex;
  gap: 1.25rem;
  padding: 1.875rem 3.125rem;
`,
)

function TheRaise() {
  const {
    form: {profile},
  } = useProfileContext()
  if (!profile.raises || profile.raises?.length === 0) {
    return <></>
  }

  const raise = _.last(_.sortBy(profile.raises, 'close_date'))

  const closeDate = raise.close_date && DateTime.fromISO(raise.close_date)
  const status = closeDate && closeDate < DateTime.now() ? 'Closed' : 'Active'
  const components = _.filter(
    [
      {title: 'Raise', value: raise.name, tooltip: null}, // tooltip: content.TODO},
      {title: 'Campaign status', value: status, tooltip: null}, // tooltip: content.TODO},
      {title: 'Funding goal', value: raise.amount, tooltip: null}, // tooltip: content.TODO},
      {title: 'Stage', value: raise.stage, tooltip: null}, // tooltip: content.TODO},
      {title: 'Min. check size', value: raise.minimum_check_size, tooltip: null}, // tooltip: content.TODO},
      {title: 'Closing Day', value: closeDate?.toFormat('MMM dd, yyyy'), tooltip: null}, // tooltip: content.TODO},
    ],
    c => !_.isEmpty(c.value),
  )

  return (
    <Grid container columnSpacing={5} bgcolor={colors.background[2]}>
      <Grid item xs={12}>
        <SectionTitleDarkGreen sx={{marginBottom: '2.35rem'}}>The Raise</SectionTitleDarkGreen>
      </Grid>
      <Grid item xs={12} sm={12} md={4} display="flex" sx={{marginBottom: {xs: '2.125rem', md: 0}}}>
        <RaiseCard>
          {components.map((c, i) => (
            <CardRow key={i}>
              <Typography variant="body1medium" color={colors.darkEvergreen[100]}>
                {c.title}
                {c.tooltip && <RaiseTooltip title={c.title} description={c.tooltip} />}
              </Typography>
              <Typography variant="body1medium" color={colors.darkEvergreen[100]}>
                {c.value}
              </Typography>
            </CardRow>
          ))}
        </RaiseCard>
      </Grid>
      <Grid item xs={12} sm={12} md={8} rowSpacing={5} display="flex">
        <RaiseReasons>
          {profile.raise_reasons?.map((reason: {title: string; details: string}, i: number) => (
            <RaiseReasonCard>
              <Typography variant="h1" color={colors.darkEvergreen[60]}>
                {i + 1}
              </Typography>
              <Box>
                <Box typography="body1" color={colors.darkEvergreen[100]}>
                  {reason.title}
                </Box>
                <Box typography="body2" color={colors.darkEvergreen[60]}>
                  {reason.details}
                </Box>
              </Box>
            </RaiseReasonCard>
          ))}
        </RaiseReasons>
      </Grid>
    </Grid>
  )
}

const FeaturedInCard = styled(Card)(
  ({theme}) => `
  box-sizing: border-box;
  min-width: 24.15rem;
  padding: 3.12rem;

  ${theme.breakpoints.down('md')} {
    max-width: 12.75rem;  
    padding: 2rem;
  }
`,
)

function FeaturedArticle({article}: {article: any}) {
  const {
    form: {currentFiles, metadata},
  } = useProfileContext()
  const photo = currentFiles[article?.photo?.key]
  return (
    <FeaturedInCard>
      <TrackedLink
        style={{display: 'block', textDecoration: 'none'}}
        section={'FeaturedIn'}
        targetId={metadata.orgId}
        url={article?.link}>
        <Box sx={{width: '5.625rem', height: '5.625rem', marginBottom: '2.5rem'}}>
          {photo && (
            <img
              style={{maxHeight: '5.625rem', width: '5.625rem'}}
              src={photo}
              alt={`${article.title}'s accompanying`}
            />
          )}
        </Box>
        <Box width="100%">
          <Stack spacing={3}>
            <Box typography="body1medium" color={colors.darkEvergreen[100]} sx={{fontSize: {xs: '1rem', md: '1.5rem'}}}>
              {article?.title}
            </Box>
            <Box
              typography="body1medium"
              color={colors.darkEvergreen[60]}
              sx={{fontSize: {xs: '0.75rem', md: '1.25rem'}}}>
              <Box>{article?.publisher_name}</Box>
            </Box>
          </Stack>
        </Box>
      </TrackedLink>
    </FeaturedInCard>
  )
}

function FeaturedIn() {
  const {
    form: {profile},
  } = useProfileContext()
  return (
    <Grid container>
      <Grid item xs={12}>
        <SectionTitleDarkGreen sx={{marginBottom: '2rem'}}>Featured in...</SectionTitleDarkGreen>
      </Grid>
      <Grid item xs={12}>
        <CardCaraousel>
          {Array.isArray(profile?.featured_articles) &&
            profile.featured_articles.map((article: any, i: number) => <FeaturedArticle key={i} article={article} />)}
        </CardCaraousel>
      </Grid>
    </Grid>
  )
}

function LinkedInLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clip-path="url(#clip0_353_6737)">
        <path
          d="M14.25 0H3.75C1.67925 0 0 1.67925 0 3.75V14.25C0 16.3207 1.67925 18 3.75 18H14.25C16.3215 18 18 16.3207 18 14.25V3.75C18 1.67925 16.3215 0 14.25 0ZM6 14.25H3.75V6H6V14.25ZM4.875 5.049C4.1505 5.049 3.5625 4.4565 3.5625 3.726C3.5625 2.9955 4.1505 2.403 4.875 2.403C5.5995 2.403 6.1875 2.9955 6.1875 3.726C6.1875 4.4565 5.60025 5.049 4.875 5.049ZM15 14.25H12.75V10.047C12.75 7.521 9.75 7.71225 9.75 10.047V14.25H7.5V6H9.75V7.32375C10.797 5.38425 15 5.241 15 9.18075V14.25Z"
          fill="#0F5148"
        />
      </g>
      <defs>
        <clipPath id="clip0_353_6737">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const TeamMemberCard = styled(Card)(
  ({theme}) => `
  box-sizing: border-box;
  min-width: 24.1875rem;
  padding: 1.875rem;

  ${theme.breakpoints.down('md')} {
    max-width: 14.75rem;
    padding: 1.25rem;
  }
`,
)

function TeamMember({member, label}: {member: any; label?: string}) {
  const {
    form: {currentFiles},
  } = useProfileContext()
  const headshot = currentFiles[member?.headshot?.key]
  const name = `${member?.first_name ?? ''} ${member?.last_name ?? ''}`
  return (
    <TeamMemberCard>
      <Box sx={{width: '5.625rem', height: '5.625rem', marginBottom: '2.5rem'}}>
        {headshot && (
          <ProfileImagePort>
            <ProfileImagePreview>
              <ProfileImage url={headshot} aria-label={`${name}'s headshot`} />
            </ProfileImagePreview>
          </ProfileImagePort>
        )}
      </Box>
      <Box width="100%">
        {member.linkedin && (
          <Box position={'relative'} sx={{float: 'right'}}>
            <a {...externalLink(member.linkedin)}>
              <LinkedInLogo />
            </a>
          </Box>
        )}
        <Stack spacing={3}>
          <Box sx={{visibility: label ? 'visible' : 'hidden'}} typography="body2medium" color={colors.gray[1]}>
            {label}
          </Box>
          <Box typography="body1medium" color={colors.darkEvergreen[100]} sx={{fontSize: {xs: '1rem', md: '1.5rem'}}}>
            {name}
          </Box>
          <Box
            typography="body1medium"
            color={colors.darkEvergreen[60]}
            sx={{fontSize: {xs: '0.75rem', md: '1.25rem'}}}>
            <Box>{member?.job_role}</Box>
            <Box>{member?.experience}</Box>
          </Box>
        </Stack>
      </Box>
    </TeamMemberCard>
  )
}

function TheTeam() {
  const {
    form: {profile},
  } = useProfileContext()
  return (
    <Grid container columnSpacing={5} rowSpacing={8} bgcolor={colors.background[2]}>
      <Grid item xs={12} display="flex">
        <SectionTitleDarkGreen>The team</SectionTitleDarkGreen>
      </Grid>
      <Grid item xs={12}>
        <CardCaraousel>
          {profile?.organization_contact && <TeamMember label="Main Contact" member={profile.organization_contact} />}
          {Array.isArray(profile?.organization_members) &&
            profile.organization_members.map((member: any, i: number) => <TeamMember key={i} member={member} />)}
        </CardCaraousel>
      </Grid>
    </Grid>
  )
}

function CompanyDetails() {
  const {
    form: {metadata, profile},
  } = useProfileContext()
  const components = _.filter(
    [
      {title: 'Legal Name', value: profile.company_details?.legal_name, tooltip: null}, // content.TODO},
      {title: 'Employees', value: profile.company_details?.company_size, tooltip: null}, // content.TODO},
      {title: 'Location', value: profile.company_details?.location, tooltip: null}, // content.TODO},
      {title: 'Founded', value: profile.company_details?.founded, tooltip: null}, // content.TODO},
    ],
    c => !_.isEmpty(c.value),
  )
  return (
    <Grid container columnSpacing={5} rowSpacing={8}>
      <Grid item xs={12}>
        <SectionTitleDarkGreen>Company Details</SectionTitleDarkGreen>
      </Grid>
      {components.length > 0 && (
        <Grid item xs={12} sm={6} display="flex">
          <Card background={colors.background[2]}>
            {components.map(c => (
              <CardRow key={c.title}>
                <Typography variant="body1medium" color={colors.darkEvergreen[100]}>
                  {c.title}
                  <span style={{color: colors.darkEvergreen[40]}}>
                    {c.tooltip && <CherubTooltip title={c.title} description={c.tooltip} />}
                  </span>
                </Typography>
                <Typography variant="body2medium" color={colors.darkEvergreen[100]} align="right">
                  {c.value}
                </Typography>
              </CardRow>
            ))}
          </Card>
        </Grid>
      )}
      {profile.company_social_links && (
        <Grid item xs={12} sm={6} display="flex">
          <Card>
            <Typography variant="body1medium" color={colors.darkEvergreen[100]} fontSize={{md: '24px'}}>
              Find us
            </Typography>
            {Object.keys(profile.company_social_links).map((key: string) => {
              const link = profile.company_social_links[key]
              if (!link) {
                return <></>
              }
              return (
                <CardRow key={link}>
                  <Typography variant="body2medium" color={colors.darkEvergreen[100]}>
                    {_.startCase(key)}
                  </Typography>
                  <TrackedLink
                    section={'CompanySocialLinks'}
                    style={{color: colors.evergreen[100]}}
                    targetId={metadata.orgId}
                    url={link}>
                    <NorthEast />
                  </TrackedLink>
                </CardRow>
              )
            })}
            {profile.company_social_links_others &&
              // url is optional cause we mistakenly saved the link as url in the backend for founders
              profile.company_social_links_others.map(
                ({title, url, link}: {title: string; url?: string; link: string}) => (
                  <CardRow key={title}>
                    <Typography variant="body2medium" color={colors.darkEvergreen[100]}>
                      {title}
                    </Typography>
                    <TrackedLink
                      section={'CompanySocialLinks'}
                      style={{color: colors.evergreen[100]}}
                      targetId={metadata.orgId}
                      url={url || link}>
                      <NorthEast />
                    </TrackedLink>
                  </CardRow>
                ),
              )}
          </Card>
        </Grid>
      )}
    </Grid>
  )
}

const StyledHR = styled('hr')(
  ({theme}) => `
  border: none;
  border-top: 1px solid ${colors.background[5]};
  color: ${colors.background[5]};
  
  width: auto;
`,
)

function LoggedOutCallToAction() {
  const {state} = useAuthentication()
  const {signUp} = useAuthRedirects()
  const {
    form: {profile},
  } = useProfileContext()
  const name = profile?.company?.name || profile?.investor?.name

  if (state !== 'unauthenticated') {
    return <></>
  }

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: '1.5rem 0',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 1500,
        backdropFilter: 'blur(10px)',
      }}>
      <Box typography="h3" color={colors.darkEvergreen[100]} marginBottom={1}>
        Where future 🦄s go to meet the next big 👼s
      </Box>
      <Box typography="body2" color={colors.darkEvergreen[100]} marginBottom={4}>
        Join Cherub to view {name}'s data room.
      </Box>
      <Box display="flex" gap="0.375rem" justifyContent="center">
        <PrimaryButton size="large" onClick={() => signUp()}>
          Sign Up
        </PrimaryButton>
        <SecondaryButton
          href="https://www.investwithcherub.com/#Memberships"
          size="large"
          sx={{background: colors.background.white}}>
          Learn More
        </SecondaryButton>
      </Box>
    </Box>
  )
}

function ProfileTagSection(props: any) {
  const profile = props.profile
  const metadata = props.metadata
  const columNumbers = props.isOwnProfile ? 6 : 3

  console.log(props)

  if (metadata.profileType === 'investor') {
    return <InvestorTagSection profile={profile} columNumbers={columNumbers} />
  }

  return <FounderTagSection profile={profile} columNumbers={columNumbers} />
}

function InvestorTagSection(props: any) {
  const profile = props.profile
  
  return (
    <>
      {profile?.tags.oldFormat.matchCharacteristics && profile?.tags.oldFormat.matchCharacteristics.length > 0 && (
        <Grid item sx={{}} xs={12} md={props.columNumbers}>
          <Typography sx={{color: colors.darkEvergreen[100]}}>My Skills</Typography>
          <Divider sx={{margin: '15px 0'}} flexItem />
          <DisplayedTags sx={{marginBottom: {xs: '2.62rem', md: '0'}}}>
            {profile &&
              dedupeTags(profile.tags.oldFormat.matchCharacteristics, profile.tags.oldFormat.matchIndustries).map((tag: string) => (
                <TagChip key={tag}>{tag}</TagChip>
              ))}
          </DisplayedTags>
        </Grid>
      )}
      {profile?.tags.oldFormat.matchTargets && profile?.tags.oldFormat.matchTargets.length > 0 && (
        <Grid item sx={{}} xs={12} md={props.columNumbers}>
          <Typography sx={{color: colors.darkEvergreen[100]}}>I'm investing in...</Typography>
          <Divider sx={{margin: '15px 0'}} flexItem />
          <DisplayedTags sx={{marginBottom: {xs: '2.62rem', md: '0'}}}>
            {profile?.tags.oldFormat.matchTargets?.map((tag: string) => <TagChip key={tag}>{tag}</TagChip>)}
          </DisplayedTags>
        </Grid>
      )}
    </>
  )
}

function FounderTagSection(props: any) {
  const profile = props.profile

  return (
    <>
      {(profile?.tags.oldFormat.matchCharacteristics?.length > 0 || profile?.tags.oldFormat.matchIndustries?.length > 0) && (
        <Grid item sx={{}} xs={12} md={props.columNumbers}>
          <Typography sx={{color: colors.darkEvergreen[100]}}>
            <span>{profile?.company?.name}</span> categories
          </Typography>
          <Divider sx={{margin: '15px 0'}} flexItem />
          <DisplayedTags sx={{marginBottom: {xs: '2.62rem', md: '0'}}}>
            {profile &&
              dedupeTags(profile.tags.oldFormat.matchCharacteristics, profile.tags.oldFormat.matchIndustries).map((tag: string) => (
                <TagChip key={tag}>{tag}</TagChip>
              ))}
          </DisplayedTags>
        </Grid>
      )}
      {profile?.tags.oldFormat.matchTargets && profile?.tags.oldFormat.matchTargets.length > 0 && (
        <Grid item sx={{}} xs={12} md={props.columNumbers}>
          <Typography sx={{color: colors.darkEvergreen[100]}}>Expertise wanted</Typography>
          <Divider sx={{margin: '15px 0'}} flexItem />
          <DisplayedTags sx={{marginBottom: {xs: '2.62rem', md: '0'}}}>
            {profile?.tags.oldFormat.matchTargets?.map((tag: string) => <TagChip key={tag}>{tag}</TagChip>)}
          </DisplayedTags>
        </Grid>
      )}
    </>
  )
}

type MatchState = {
  matchState: 'defaultMatchState' | 'getIntro' | 'notAFit' | 'requested' | 'acceptNotNow' | 'accepted' | 'declined'
  ctaState: {
    disabled: boolean
    text: React.ReactNode
    action?: () => void
  }
  locked: boolean
}

function useMatchState(match: any, currentOrgId: any, meData: any): MatchState {
  const state = calculateMatchState(match, currentOrgId, null, meData)
  const {setIntroModalState}: any = useContext(IntroModalContext)

  const ctaClick = () => {
    if (setIntroModalState != null) {
      window.scrollTo({top: 0, behavior: 'smooth'})
      setIntroModalState((prevState: any) => Object.assign({}, prevState, {open: true}))
    }
  }

  const [createMatch] = useMutation(CREATE_MATCH)
  function acceptIntroClick() {
    const vars = {id: match.deal.suggestedProfile}

    createMatch({
      variables: vars,
      onCompleted: res => {
        if (res?.deals?.userMatchIntro?.success) {
          window.scrollTo({top: 0, behavior: 'smooth'})
          setIntroModalState((prevState: any) => Object.assign({}, prevState, {open: true, view: 'successMatch'}))
        }
      },
    })
  }

  if (state.matchState === 'acceptNotNow') {
    state.ctaState.action = acceptIntroClick
  } else if (state.matchState === 'getIntro') {
    state.ctaState.action = ctaClick
  }

  return state
}

function calculateMatchState(match: any, currentOrgId: any, showNotAFit: any, meData: any): MatchState {
  let ctaState: MatchState['ctaState'] = {disabled: false, text: ''}
  let matchState: MatchState['matchState'] = 'defaultMatchState'
  let locked = false

  const disableGetIntro = meData?.me?.accountType === 'basic' && meData?.me?.profileType === 'founder'

  if (match.deal === null) {
    if (disableGetIntro) {
      matchState = 'getIntro'
      locked = true
      ctaState = {disabled: true, text: <span>Get Intro | &#128274;</span>}
    } else if (match.requestsRemaining > 0 && match.matchingTags && match.matchingTags.length > 0) {
      matchState = 'getIntro'
      ctaState = {disabled: false, text: <span>Get Intro | &#128075; {match.requestsRemaining}</span>}
    } else if (match.requestsRemaining <= 0) {
      matchState = 'getIntro'
      ctaState = {disabled: true, text: <span>Get Intro | &#128075; {match.requestsRemaining}</span>}
    }
    if (match.matchingTags && match.matchingTags.length === 0) {
      matchState = 'notAFit'
      locked = false
      ctaState = {disabled: true, text: <span onClick={showNotAFit}>Not a Fit</span>}
    }
  } else {
    if (match.deal) {
      if (match.deal.recommendedFor === currentOrgId && match.deal.requestIntro) {
        matchState = 'requested'
        ctaState = {disabled: true, text: 'Requested'}
      } else if (match.deal.suggestedProfile === currentOrgId && match.deal.requestIntro) {
        matchState = 'acceptNotNow'
        ctaState = {disabled: false, text: 'Accept Intro'}
      }
      if (match.matched === true) {
        matchState = 'accepted'
        ctaState = {disabled: true, text: 'Accepted'}
      }
    }
  }

  return {ctaState, matchState, locked}
}

function StickyActions(props: any) {
  // dont alllow match to self
  const meData = useContext(MeDataContext)
  const [isOpen, setIsOpen] = useState(false)

  let match = props.matchStatus
  const {
    form: {metadata},
  } = useProfileContext()

  const currentOrgId = metadata.orgId || ''
  const {ctaState, matchState, locked} = useMatchState(match, currentOrgId, meData)

  return (
    <Stack direction="row" spacing={6}>
      <CherubModal open={isOpen} omitSpacer={true} onClose={() => setIsOpen(false)}>
        <Grid container direction="column" sx={{backgroundColor: '#FDF8F0'}}>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Typography variant="h6" fontWeight={500}>
              Upgrade Your Plan
            </Typography>
          </Grid>

          <Grid item sx={{marginTop: 5, marginBottom: 3}}>
            <Typography variant="body2" color="textSecondary">
              This feature is only available for Cherub Raise users. Upgrade your plan to unlock intro requests, track
              profile views & clicks, and boost your fundraising visibility.
            </Typography>
          </Grid>

          <Grid item>
            <SecondaryButton
              size="medium-large"
              sx={{
                width: '100%',
                bgcolor: colors.darkEvergreen[100],
                borderColor: colors.background[5],
                color: colors.background[1],
                paddingTop: '14px',
              }}
              onClick={(e: any) => {
                e.preventDefault()
              }}>
              Learn More
            </SecondaryButton>
          </Grid>
        </Grid>
      </CherubModal>
      <SecondaryButton
        disabled={!(matchState === 'getIntro' && locked) && ctaState.disabled}
        size="medium-large"
        sx={{
          bgcolor: colors.background[1],
          borderColor: colors.background[5],
          color: colors.darkEvergreen[100],
          paddingTop: '14px',
        }}
        onClick={
          matchState === 'getIntro' && locked
            ? () => {
                setIsOpen(true)
              }
            : ctaState.action
        }>
        {ctaState.text}
      </SecondaryButton>
    </Stack>
  )
}

function MetricTracker() {
  const params = useParams()
  useMetricWhenRendered('ProfileView', {targetId: params.orgId || '', type: 'profile_view'}, {seconds: 1})
  return <></>
}

function SectionWithTracking({
  section,
  profileId,
  isOwnProfile,
  isBlurred,
}: {
  section: any
  profileId: string
  isBlurred: boolean
  isOwnProfile: boolean
}) {
  const engagementRef = useSectionEngagementRef(section.title, profileId, isOwnProfile)

  return (
    <React.Fragment key={section.anchor}>
      <div ref={engagementRef}>
        <ProfileModuleContainer
          id={section.anchor}
          sx={{
            ...section.sx,
            ...(isBlurred
              ? {
                  filter: 'blur(10px)',
                  userSelect: 'none',
                }
              : {}),
          }}>
          {isBlurred && <Box sx={{position: 'absolute', width: '100%', height: '100%', zIndex: 1400}} />}
          {section.element}
        </ProfileModuleContainer>
        {section.separator}
      </div>
    </React.Fragment>
  )
}

export default function ProfileView(): React.JSX.Element {
  const {state} = useAuthentication()
  const client = useApolloClient()
  const params = useParams()
  const [{profile, currentFiles, pendingFiles, metadata}, dispatch] = useReducer(profileFormReducer, nullProfile)
  const [matchStatus, matchStatusDispatch] = useReducer(matchStatusReducer, {
    matchingTags: [''],
    matchId: 0,
    deal: null,
    requestsRemaining: 3,
    matched: false,
  })

  const {data: me} = useMeQuery()
  const {loading, error} = useQuery(GET_PROFILE, {
    onCompleted: data => {
      dispatch({
        type: 'set_loaded',
        profile: {...data.profile.data, tags: data.profile.tags},
        files: data.profile.files,
        metadata: {
          profileType: data.profile.profileType,
          truncated: data.profile.truncated,
          orgId: params.orgId,
        },
      })
    },
    variables: {
      orgId: params.orgId,
    },
  })

  useEffect(() => {
    if (state === 'authenticated') {
      client.query({query: GET_MATCH_STATUS, variables: {wanted: params.orgId}}).then(({data}) => {
        matchStatusDispatch({
          type: 'set_match_status',
          match: data.match,
        })
      })
    }
  }, [state, client, params.orgId])

  const isOwnProfile = params.orgId === me?.me.orgId

  const [introModalState, setIntroModalState] = useState({open: false, view: 'introForm'})
  const [introOutOfView, setIntroOutOfView] = useState(false)

  const allSections = metadata.profileType === 'founder' ? founderSections : investorSections
  const sections = allSections.filter(s => !s.skipIf || !s.skipIf(profile))

  const [focusedSection, setFocusedSection] = React.useState(0)
  const onSetFocusedSection = (index: number) => {
    const section = sections[index]
    const element = document.getElementById(section.anchor)
    window.scrollTo({top: element!.offsetTop - 120, behavior: 'smooth'})
    setFocusedSection(index)
  }

  if (error) {
    return <Alert>{error.message}</Alert>
  }

  if (!profile) {
    return (
      <Box>
        <Header />
        <Modal open={loading} aria-label="Loading Profile">
          <Box sx={styles.modal}>
            <CircularProgress color="primary" />
          </Box>
        </Modal>
      </Box>
    )
  }

  return (
    <ProfileFormContext.Provider value={{profile, currentFiles, pendingFiles, metadata, isOwnProfile}}>
      <ProfileFormDispatchContext.Provider value={dispatch}>
        <MatchStatusContext.Provider value={matchStatus}>
          <MatchStatusDispatchContext.Provider value={matchStatusDispatch}>
            <IntroModalContext.Provider value={{setIntroModalState, setIntroOutOfView}}>
              <MeDataContext.Provider value={me}>
                <FloatingBanner openTo={profile.open_to} profileType={metadata?.profileType} />
                {me && params.orgId && params.orgId !== me?.me.orgId && <MetricTracker />}
                {introModalState.open && <IntroModal introModalState={introModalState} />}
                <Box>
                  {introOutOfView && state === 'authenticated' ? (
                    <MatchHeader
                      deal={matchStatus.deal?.id}
                      matchStatus={matchStatus}
                      updatedFn={() => {}}
                      component={StickyActions}
                    />
                  ) : (
                    <Header />
                  )}
                  {metadata.truncated && <LoggedOutCallToAction />}
                  <Box sx={{width: '100%', position: 'fixed', top: '100px', display: {xs: 'block', md: 'none'}}}>
                    <MobileNavMenu sections={sections} defaultValue={focusedSection} onClick={onSetFocusedSection} />
                  </Box>
                  <Stack
                    spacing={3}
                    sx={{display: {xs: 'none', md: 'block'}, position: 'fixed', top: '120px', left: 0}}>
                    {sections.map((section, index) => (
                      <Grid key={section.title} container>
                        <Grid item>
                          <NavigationIcon focused={focusedSection === index}>📍</NavigationIcon>
                        </Grid>
                        <Grid item>
                          <NavigationItem focused={focusedSection === index} onClick={() => onSetFocusedSection(index)}>
                            {section.title}
                          </NavigationItem>
                        </Grid>
                      </Grid>
                    ))}
                  </Stack>
                  <Stack sx={{width: '100%', marginTop: {xs: 20, md: 0}}} spacing={0}>
                    {sections.map(section => (
                      <SectionWithTracking
                        key={section.anchor}
                        section={section}
                        profileId={metadata.orgId ?? ''}
                        isOwnProfile={isOwnProfile}
                        isBlurred={metadata.truncated && !section.noBlur}
                      />
                    ))}
                  </Stack>

                </Box>
                <Footer />
              </MeDataContext.Provider>
            </IntroModalContext.Provider>
          </MatchStatusDispatchContext.Provider>
        </MatchStatusContext.Provider>
      </ProfileFormDispatchContext.Provider>
    </ProfileFormContext.Provider>
  )
}

import { useEffect, useRef } from 'react'
import mixpanel from 'mixpanel-browser'
import { gql, useMutation } from '@apollo/client'


export function useSectionEngagementRef(sectionName: string, profileId: string, isOwnProfile: boolean) {
    const [createMetric] = useMutation(gql`
        mutation SectionDurationMetric($targetId: String!, $type: String!, $extra: JSON) {
          addMetric(input: {targetId: $targetId, type: $type, extra: $extra}) {
            success
            errors
          }
        }
      `)
      
      

  const ref = useRef<HTMLDivElement | null>(null)
  const enterTime = useRef<number | null>(null)
  const totalDuration = useRef(0)
  const hasTracked = useRef(false)

  useEffect(() => {
    const node = ref.current
    if (!node) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
          enterTime.current = performance.now()
        } else if (enterTime.current) {
          const durationMs = performance.now() - enterTime.current
          totalDuration.current += durationMs
          enterTime.current = null

          const seconds = Math.round(totalDuration.current / 1000)
          if (seconds >= 2 && !hasTracked.current) {
            mixpanel.track('Profile Section Duration', {
              profile_id: profileId,
              section: sectionName,
              duration: seconds,
            })
            
            if (!isOwnProfile) {
              createMetric({
                variables: {
                  targetId: profileId,
                  type: 'section_duration',
                  extra: {
                    section: sectionName,
                    duration: seconds,
                  },
                },
              })                                 
              .then(response => {
                  if (response.errors) {
                      console.error('Error creating metrics for section_duration', response.errors)
                  }
              })
              .catch(e => {
                  console.error('Error creating metrics for section_duration', e)
              })
            }

            hasTracked.current = true
          }
        }
      },
      { threshold: [0.5] },
    )

    observer.observe(node)

    return () => observer.disconnect()
  }, [sectionName, profileId, createMetric])

  return ref
}

import {styled} from '@mui/system'
import {useEffect, useState} from 'react'

const BannerWrapper = styled('div')(
  ({theme}) => `
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px 0;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`,
)

const BannerComponent = styled('div')(
  ({theme}) => `
  font-size: 18px;
  color: #333;
  display: inline-block;
  transform: translateX(100%);
  white-space: nowrap;
  animation: scroll-left 45s linear infinite;
  
  @keyframes scroll-left {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`,
)

const FloatingBanner = ({openTo, profileType}: {openTo: string; profileType: string}) => {
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (openTo && profileType) {
      const messages: Record<string, Record<string, string>> = {
        investor: {
          'Advising / advisory shares': '👋 ACTIVELY ADVISING',
          'Angel investing': '👋 ACTIVELY INVESTING',
          Both: '👋 ACTIVELY INVESTING AND ADVISING',
          'Do not match or surface me to anyone': '💤 NOT ACTIVELY INVESTING OR ADVISING',
        },
        founder: {
          'Match with angel investors': '👋 OPEN TO ANGEL INVESTORS',
          'Match with advisors': '👋 OPEN TO ADVISORS',
          Both: '👋 OPEN TO ANGEL INVESTORS AND ADVISORS',
          'Do not match or surface me to anyone': '💤 NOT ACTIVELY RAISING',
        },
      }

      // Update message based on the investorType prop
      setMessage(messages[profileType ?? ''][openTo ?? ''] || '')
    }
  }, [openTo, profileType])

  // If there's no message, don't render the banner
  if (!message) {
    return <></>
  }

  return (
    <BannerWrapper>
      <BannerComponent>
        {Array(10)
          .fill(message)
          .map((msg, index) => (
            <span key={index} style={{marginRight: '30px'}}>
              {msg}
            </span>
          ))}
      </BannerComponent>
    </BannerWrapper>
  )
}

export default FloatingBanner

import {Accordion, AccordionDetails, AccordionProps, AccordionSummary, Grid, Typography} from '@mui/material'
import {Box, Stack, styled} from '@mui/system'
import _ from 'lodash'
import React from 'react'
import colors from '../colors'
import theme from '../theme'
import {SecondaryButton} from '../ui/Buttons'
import {Add, ChevronDown, Delete, Done} from '../ui/icons'
import {useProfileContext} from './Profile.Contexts'
import DateField from './Profile.DateField'
import ImageInput from './Profile.ImageInput'
import RaiseInput from './Profile.RaiseInput'
import SelectField from './Profile.SelectField'
import TagInput from './Profile.TagInput'
import TextField from './Profile.TextField'
import {ProfileField, RepeatingFields, Section, anchorFor, isSectionFinished} from './common'

const SectionStatusIcon = styled('div')<{finished: boolean}>(
  ({theme, finished}) => `
  border-radius: 6.25rem;
  border: 1px solid ${finished ? colors.evergreen[130] : colors.background[4]};
  background: ${finished ? colors.evergreen[130] : colors.transparent.fully};
  color: ${finished ? colors.background['warm white'] : colors.darkEvergreen[20]};

  padding: 0.5rem;
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;

  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5rem;
`,
)

const SectionTitle = styled('div')(
  ({theme}) => `
  color: ${colors.evergreen[100]};
  font-family: "Warnock-Pro";
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 110%; /* 2.475rem */
  letter-spacing: -0.0225rem;
`,
)

const CherubAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({theme}) => ({
    border: 0,
    background: colors.background['warm white'],
    '&:not(:last-child)': {
      // TODO: not being picked up?
      borderBottom: `2px solid ${colors.background[5]}`,
    },
    '&::before': {
      display: 'none',
    },
  }),
)

export function SectionDivider(): React.JSX.Element {
  return <Box sx={{background: 'rgba(0, 0, 0, 0.10)', height: '0.0625rem'}} />
}

export function SectionInstructions({text}: {text: string}): React.JSX.Element {
  return (
    <Typography variant="h5" color={colors.gray[1]}>
      {text}
    </Typography>
  )
}

export function SectionSubInstructions({text}: {text: string}): React.JSX.Element {
  return (
    <Typography variant="body3" color={colors.gray[1]}>
      {text}
    </Typography>
  )
}

function SectionField({field}: {field: React.FC | ProfileField}): React.JSX.Element {
  const {
    form: {profile},
    dispatch,
  } = useProfileContext()

  if (typeof field === 'function') {
    const FC = field as React.FC<any>
    return <FC />
  }

  const value = _.get(profile, field.property) ?? ''

  switch (field.type) {
    case 'image':
      return (
        <ImageInput
          {...field}
          // images are an odd case w.r.t inputs, so we define the key here to force a full rerender
          // when the file or containing object itself changes
          key={value?.key}
          aria-label={field.label}
          placeholder={field.placeholder}
          description={field.description}
          accept={'image/*'}
          url={value}
          onChange={event => {
            const file = event.target.files?.[0] ?? null
            dispatch({type: 'set_image', field, file})
          }}
        />
      )
    case 'tags':
      return (
        <TagInput
          {...field}
          aria-label={field.label}
          // filterTODO=""
          // showSuggestTODO={true}
          // setTags={(tags) => {
          //   dispatch({ type: 'set_tags', field, value: tags.map((tag) => tag.slug) })
          // }}
          value={value || []}
        />
      )
    case 'raise':
      // TODO: raise index for repeats
      return <RaiseInput raiseIndex={0} aria-label={field.label} />
    case 'date': {
      return (
        <DateField
          {...field}
          aria-label={field.label}
          value={value}
          onChange={date => {
            dispatch({type: 'set_date', field, value: date})
          }}
        />
      )
    }
    case 'select': {
      return (
        <SelectField
          field={{...field, value}}
          select={{
            onChange: (_, value) => {
              // TODO: for some reason this fires if the value is null
              if (value) {
                dispatch({type: 'set_value', field, value})
              }
            },
            'aria-label': field.label,
          }}
        />
      )
    }
    case 'text': {
      return (
        <TextField
          {...field}
          aria-label={field.label}
          value={value}
          onChange={event => {
            dispatch({type: 'set_value', field, value: event.target.value})
          }}
        />
      )
    }
  }
}

const RepeatCounter = styled('div')(
  ({theme}) => `
  border-radius: 20px;
  border: 1px solid ${colors.gray[3]};
  display: flex;
  width: 18px;
  padding: 4px 4px 2px 4px;
  justify-content: center;
  align-items: center;
`,
)


function Repeater({ field }: { field: RepeatingFields | ProfileField }) {
  const {
    form: { profile },
    dispatch,
  } = useProfileContext()

  const current = _.get(profile, field.property) ?? []
  const [count, setCount] = React.useState(current.length || 1)

  if (field.type !== 'repeating') {
    return (
      <Grid item {...field.sizes}>
        <SectionField field={field} />
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Box>
        <Grid container rowSpacing={4}>
          {_.range(0, count).map(repeatNumber => {
            const baseProperty = `${field.property}[${repeatNumber}]`
            return (
              <React.Fragment key={baseProperty}>
                <Grid item xs={12}>
                  <RepeatCounter>{repeatNumber + 1}</RepeatCounter>
                </Grid>
                {field.fields.map(childField => {
                  const property = `${baseProperty}.${childField.property}`
                  return (
                    <Grid item {...childField.sizes} key={property}>
                      <SectionField field={{ ...childField, property }} />
                    </Grid>
                  )
                })}
                {count > field.minimum && (
                  <Grid item xs={12}>
                    <SecondaryButton
                      size="medium-large"
                      onClick={() => {
                        dispatch({
                          type: 'remove_field',
                          field: { property: baseProperty },
                        })
                        setCount((prev: any) => prev - 1)
                      }}>
                      <Delete />
                      Delete
                    </SecondaryButton>
                  </Grid>
                )}
              </React.Fragment>
            )
          })}
          {count < field.repeats && (
            <Grid item xs={12}>
              <SecondaryButton size="medium-large" onClick={() => setCount(count + 1)}>
                <Add />
                Add
              </SecondaryButton>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}

let defaultExpanded: boolean | null = null

export default function ProfileSection({section}: {section: Section}) {
  const {
    form: {profile, pendingFiles},
  } = useProfileContext()
  // Collapsed on mobile, otherwise expanded by default
  if (defaultExpanded === null) {
    defaultExpanded = window.innerWidth > theme.breakpoints.values.sm
  }

  const finished = isSectionFinished(section, profile, pendingFiles)

  return (
    <Grid item id={anchorFor(section)} key={section.title} xs={12}>
      <CherubAccordion defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ChevronDown />}>
          <Stack direction="row" spacing={5}>
            <SectionStatusIcon finished={finished}>
              <Done />
            </SectionStatusIcon>
            <SectionTitle>{section.title}</SectionTitle>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={5} rowSpacing={7.5}>
            {section.description && (
              <Grid item xs={12}>
                {section.description}
              </Grid>
            )}
            {section.repeats && (
              <Grid item xs={12}>
                <div style={{color: 'rgba(255, 0, 0, 0.5)', fontSize: '12px'}}>Repeating TODO</div>
              </Grid>
            )}
            {section.fields.map((field, i) =>
              typeof field === 'function' ? (
                <Grid item xs={12} key={i}>
                  <SectionField field={field} />
                </Grid>
              ) : (
                <Repeater key={i} field={field} />
              ),
            )}
          </Grid>
        </AccordionDetails>
      </CherubAccordion>
    </Grid>
  )
}

import React from 'react'
import {Typography, Grid, Tooltip} from '@mui/material'
import colors from '../../colors'
import Divider from '@mui/material/Divider'
import {styled} from '@mui/system'
import {FitIcon, Help} from '../../ui/icons'
import {DisplayedTags} from '../../profile-view/displayed-tags'

const TagChip = styled('div')(
  ({theme}) => `
  border-radius: 20px;
  background: ${colors.background[4]};
 
  align-items: center;
  padding: 12px 20px;
  color: ${colors.darkEvergreen[100]};
  text-align: center;
  font-family: "Aktiv-Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;

  pointer: cursor;
`,
)

const YourFitComponent = ({title, tags}: {title: string; tags: Array<string>}) => {
  return (
    <Grid item sx={{}} xs={12} md={4}>
      <Grid display="flex" alignItems="center">
        <Typography sx={{color: colors.darkEvergreen[100], height: '26px'}} display="flex" alignItems="center">
          <FitIcon style={{marginRight: 10}} />
          {title}
          <Tooltip title="This explains the 'Your fit' section" placement="top" style={{marginLeft: 10}}>
            <Help />
          </Tooltip>
        </Typography>
      </Grid>
      <Divider sx={{margin: '15px 0'}} flexItem />
      {
        <DisplayedTags sx={{marginBottom: {xs: '2.62rem', md: '0'}}}>
          {tags && tags.map((tag: string) => <TagChip key={tag}>{tag}</TagChip>)}
        </DisplayedTags>
      }
    </Grid>
  )
}

export default YourFitComponent

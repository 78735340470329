import {gql, useMutation} from '@apollo/client'
import {Button, Grid, TextField, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {PrimaryButton} from './ui/Buttons'

export const UPDATE_EMAIL = gql`
  mutation UpdateEmail($newEmail: String!) {
    updateEmail(input: {newEmail: $newEmail}) {
      success
      errors
    }
  }
`

interface UpdateEmailProps {
  prefillEmail: any
}

export const UpdateEmailModal: React.FC<UpdateEmailProps> = ({prefillEmail}) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState(prefillEmail)
  const [updateEmail] = useMutation(UPDATE_EMAIL)
  const [mutationError, setMutationError] = useState('')
  const [showError, setShowError] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allowUpdate, setAllowUpdate] = useState(false)
  const [success, setSuccess] = useState(false)

  function isJsonString(item: any): boolean {
    if (typeof item !== 'string') {
      return false
    }
    try {
      JSON.parse(item)
    } catch (e) {
      return false
    }
    return true
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const response = await updateEmail({variables: {newEmail: email}})

      if (response.data?.updateEmail.errors && response.data?.updateEmail.errors.length > 0) {
        let error
        if (isJsonString(response.data.updateEmail.errors)) error = JSON.parse(response.data.updateEmail.errors[0])
        else error = response.data.updateEmail.errors[0]

        setShowError(true)
        setMutationError(error)
      } else {
        setShowError(false)
        setSuccess(true)
        setMutationError('Email updated successfully!')
      }
    } catch (err) {
      console.error('Mutation error:', err)
    }
  }

  const handleClose = (path?: string) => {
    window.location.reload()

    if (path) {
      navigate(path)
    }
  }

  useEffect(() => {
    if (email === prefillEmail) setAllowUpdate(false)
    else setAllowUpdate(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  return (
    <>
      {success ? (
        <Grid container direction="column" sx={{backgroundColor: '#FDF8F0'}}>
          <Grid item container justifyContent="space-between" alignItems="center">
            <Typography variant="h6" fontWeight={500}>
              Email Changed
            </Typography>
          </Grid>

          <Grid item sx={{marginTop: 5}}>
            <Typography variant="body2" color="textSecondary">
              Your email has been successfully changed. Please sign in again.
            </Typography>
          </Grid>

          <Grid item container justifyContent="flex-start" spacing={2} sx={{mt: 3}}>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => handleClose('/')}
                sx={{borderRadius: 6, backgroundColor: '#1F3D2B'}}>
                Sign In
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" sx={{backgroundColor: '#FDF8F0'}}>
            <Grid item container justifyContent="space-between" alignItems="center">
              <Typography variant="h6" fontWeight={500}>
                Change your email
              </Typography>
            </Grid>

            <Grid item sx={{marginTop: 5}}>
              <Typography variant="body2" color="textSecondary">
                Email address
              </Typography>
            </Grid>

            <Grid item>
              <TextField
                fullWidth
                error={showError}
                value={email}
                onChange={e => setEmail(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    fontSize: '1.2rem',
                    '&:hover': {
                      borderColor: 'inherit', // No hover effect
                    },
                    '&.Mui-focused': {
                      borderColor: 'inherit', // No focus effect
                    },
                  },
                }}
              />
            </Grid>

            {showError && <Typography sx={{fontSize: 12, color: 'red', marginTop: 5}}>{mutationError}</Typography>}

            {/* Buttons */}
            <Grid item container justifyContent="flex-start" spacing={2} sx={{mt: 3}}>
              <Grid item>
                {/* <Button
          type='submit'
          disabled={!allowUpdate}
          variant='contained'
          sx={{
            borderRadius: 6,
            backgroundColor: '#1F3D2B',
            '&:hover': {
              backgroundColor: '#1F3D2B', // No hover effect
            },
            '&:focus': {
              backgroundColor: '#1F3D2B', // No focus effect
            },
            '&:active': {
              backgroundColor: '#1F3D2B', // No active effect
            },
          }}
        >
          Update
        </Button> */}

                <PrimaryButton type="submit" size="medium" sx={{marginTop: 1, alignSelf: 'center'}}>
                  Update
                </PrimaryButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  )
}

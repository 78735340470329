import {gql, useQuery} from '@apollo/client'
import {useState} from 'react'
import ProfileOverviewCard from './ProfileOverviewCard'

export const GET_PROFILE_OVERVIEW = gql`
  query ProfileOverview($objectId: ID!) {
    profileMetrics(objectId: $objectId) {
      profileViews
      externalLinkClicks
      averageTimeSpentSeconds
      clickRate
      monthlyViewDelta
      monthlyClickRateDelta
      monthlyTimeDelta
    }

    averageProfileMetrics {
      profileViews
      externalLinkClicks
      averageTimeSpentSeconds
    }

    engagementBreakdown(objectId: $objectId) {
      averageTimeSpent {
        value
        unit
        period
      }
    }
  }
`


export const ProfileOverview = ({ orgId }: { orgId: string }) => {
  const [selectedMetric, setSelectedMetric] = useState<'profileViews' | 'externalLinkClicks' | 'averageTimeSpentSeconds'>('profileViews')

  const { data, error } = useQuery(GET_PROFILE_OVERVIEW, {
    variables: { objectId: orgId },
  })

  if (error) return <p>Error loading profile overview.</p>
  if (!data) return <></>

  const metrics = data?.profileMetrics
  const avg = data?.engagementBreakdown?.averageTimeSpent

  const getMetricData = (metricKey: typeof selectedMetric) => {
    const values = metrics[metricKey]
    const global = data.averageProfileMetrics
  
    const getSimilar = () => {
      const val = global[metricKey]
      const normalized = metricKey === 'averageTimeSpentSeconds' ? val / 60 : val
      return Math.round(normalized * 100) / 100
    }
  
    const similarValue = getSimilar()
  
    return [
      { name: '30 days', You: metricKey === 'averageTimeSpentSeconds' ? values.last_30_days / 60 : values.last_30_days, Similar: similarValue },
      { name: '60 days', You: metricKey === 'averageTimeSpentSeconds' ? values.last_60_days / 60 : values.last_60_days, Similar: similarValue },
      { name: '90 days', You: metricKey === 'averageTimeSpentSeconds' ? values.last_90_days / 60 : values.last_90_days, Similar: similarValue },
    ]
  }  

  const chartData = getMetricData(selectedMetric)

  return (
    <ProfileOverviewCard
      profileViews={metrics.profileViews.last_30_days}
      averageTime={((avg?.value ?? 0))}
      clickRate={`${(metrics.clickRate.last_30_days * 100).toFixed(1)}%`}
      viewChange={metrics.monthlyViewDelta}
      timeChange={metrics.monthlyTimeDelta} // Replace with your actual delta if you have it
      clickChange={metrics.monthlyClickRateDelta}
      data={chartData}
      selectedMetric={selectedMetric}
      onSelectMetric={setSelectedMetric}
    />
  )
}

import {Typography} from '@mui/material'
import {Stack, styled} from '@mui/system'
import _ from 'lodash'
import React from 'react'
import colors from '../colors'
import {PrimaryButton} from '../ui/Buttons'
import {useProfileContext} from './Profile.Contexts'
import DateField from './Profile.DateField'
import TextField from './Profile.TextField'
import {DateProfileField, InputProfileField, ProfileField} from './common'

type ManageRaiseFormProps = {
  raiseIndex: number
  onSave: () => void
}

const RaiseName = styled('div')(
  ({theme}) => `
  border-bottom: 1px solid ${colors.gray[5]};
  display: flex;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`,
)

function newNameFor(profile: any): string {
  const previous = _.filter(profile.raises, r => r.stage === profile.stage)?.length ?? 0
  return `${profile.stage} ${previous + 1}`
}

// see https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=376-6984&mode=dev &
//     https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=178-3573&mode=dev
export default function ManageRaiseForm({raiseIndex, onSave}: ManageRaiseFormProps): React.JSX.Element {
  const {
    form: {profile},
    dispatch,
  } = useProfileContext()
  // const [closeDateChoice, setCloseDateChoice] = React.useState<keyof typeof choices>('choose')
  const isNew = raiseIndex === profile.raises?.length

  // rather anti-pattern ish, but so is this modal
  const nameField: InputProfileField = {
    property: 'name',
    label: 'Raise Name',
    sizes: {xs: 12},
    type: 'text',
  }
  const amountField: InputProfileField = {
    property: 'amount',
    label: 'How much are you looking to raise, or how much did you raise if this is a past round?',
    sizes: {xs: 12},
    type: 'text',
  }
  const minumumCheckSizeField: InputProfileField = {
    property: 'minimum_check_size',
    label: 'What’s your minimum check size? (Optional)',
    // TODO: Not sure how to fill this? Leave it blank.
    sizes: {xs: 12},
    type: 'text',
  }
  const closeDateField: DateProfileField = {
    property: 'close_date',
    label: isNew ? 'My raise will or did close on:' : 'My raise closes or closed on:',
    sizes: {xs: 12},
    type: 'date',
  }

  const baseRaise = {name: newNameFor(profile), stage: profile.stage}
  const currentRaise = profile.raises?.[raiseIndex] ?? {}
  const [raise, setRawRaise] = React.useState<any>({
    ...baseRaise,
    ...currentRaise,
  })
  const setRaise = (field: ProfileField, value: any) => {
    setRawRaise({...raise, [field.property]: value})
  }

  const canSave = _.every([nameField, amountField], f => !!_.get(raise, f.property))

  const saveChanges = () => {
    dispatch({
      type: 'set_object',
      field: {
        label: 'Raise',
        property: `raises[${raiseIndex}]`,
        type: 'text', // TODO: cleanup
        sizes: {xs: 12},
      },
      value: raise,
    })
    onSave()
  }

  const deleteRaise = () => {
    const updatedRaises = [...profile.raises]
    updatedRaises.splice(raiseIndex, 1)

    dispatch({
      type: 'set_object',
      field: {
        label: 'Raises',
        property: 'raises',
        type: 'text',
        sizes: { xs: 12 },
      },
      value: updatedRaises,
    })

    onSave()
  }

  return (
    <Stack spacing={3}>
      <Typography variant="h5">{isNew ? 'New raise' : 'Manage Raise'}</Typography>
      <RaiseName>
        <Typography variant="body2">Raise Name</Typography>
        {/* TODO: dispatch on clicking save, new or existing */}
        <Typography variant="body2">{raise.name}</Typography>
      </RaiseName>
      <TextField
        {...amountField}
        aria-label={amountField.label}
        value={_.get(raise, amountField.property) ?? ''}
        onChange={event => {
          setRaise(amountField, event.target.value)
        }}
      />
      <TextField
        {...minumumCheckSizeField}
        aria-label={minumumCheckSizeField.label}
        value={_.get(raise, minumumCheckSizeField.property) ?? ''}
        onChange={event => {
          setRaise(minumumCheckSizeField, event.target.value)
        }}
      />
      {/* <div>
        <Typography variant="body4">Raise close date</Typography>
        <CherubRadioGroup
          choices={choices}
          defaultValue={closeDateChoice}
          onChange={(_, v) => setCloseDateChoice(v as keyof typeof choices)}
        />
      </div>
      {closeDateChoice === 'manual' ? (
        <SecondaryButton
          size="large"
          aria-label="End raise"
          onClick={() => {
            const date = DateTime.local().startOf('day')
            setRaise(closeDateField, date)
            saveChanges()
          }}>
          End raise
        </SecondaryButton>
      ) : ( */}
      <DateField
        {...closeDateField}
        aria-label={closeDateField.label}
        value={_.get(raise, closeDateField.property) ?? null}
        onChange={date => {
          setRaise(closeDateField, date)
        }}
      />
      {/* )} */}

      <PrimaryButton
          disabled={isNew}
          size="large"
          aria-label="Delete raise"
          color="error"
          onClick={deleteRaise}
        >
          Delete Raise
        </PrimaryButton>
        
      <PrimaryButton disabled={!canSave} size="large" aria-label="Save changes" onClick={() => saveChanges()}>
        Save changes
      </PrimaryButton>
    </Stack>
  )
}

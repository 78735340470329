import {Grid} from '@mui/material'
import {styled} from '@mui/system'
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import colors from '../../colors'
import {CustomTooltip} from './CustomTooltip'

const Card = styled('div')({
  padding: '20px',
  borderRadius: '30px',
  maxWidth: '100%',
  backgroundColor: colors.background['warm white'],
})

const MetricBox = styled('div')<{ selected?: boolean }>(({ selected }) => ({
  textAlign: 'left',
  padding: '10px',
  borderRadius: '5px',
  backgroundColor: selected ? '#F3F5F5' : 'transparent',
  cursor: 'pointer',
  height: '100%',
}))

const MetricValue = styled('h3')({
  fontSize: '32px',
  fontFamily: 'Aktiv-Grotesk',
  fontWeight: '400',
  margin: '0',
  color: '#0B3932',
})

const MetricChange = styled('span')<{ increase?: boolean; isZero?: boolean }>(({ increase, isZero }) => ({
  fontFamily: 'Aktiv-Grotesk',
  fontWeight: '400',
  fontSize: '14px',
  color: isZero ? 'rgba(201, 201, 201, 1)' : increase ? '#2F8F2F' : '#D9534F',
  display: 'block',
  marginTop: '5px',
}))

const MetricChangeBackground = styled('div')<{ increase?: boolean; isZero?: boolean }>(({ increase, isZero }) => ({
  backgroundColor: isZero
    ? 'rgba(201, 201, 201, 0.3)'
    : increase
    ? 'rgba(218, 221, 204, 0.3)'
    : 'rgba(248, 91, 91, 0.1)',
  width: '70px',
  textAlign: 'center',
  borderRadius: '4px',
  whiteSpace: 'nowrap',
}))

const MetricLabel = styled('p')<{ isSubtitle?: boolean }>(({ isSubtitle }) => ({
  fontSize: isSubtitle ? '14px' : '16px',
  fontWeight: '400',
  color: isSubtitle ? '#767676' : '#3A3A3A',
  margin: '5px 0 6px 0',
}))

type Props = {
  profileViews: number
  averageTime: number
  clickRate: string
  viewChange?: number
  timeChange?: number
  clickChange?: number
  data: Array<{ name: string; You: number; Similar: number }>
  selectedMetric: 'profileViews' | 'externalLinkClicks' | 'averageTimeSpentSeconds'
  onSelectMetric: (metric: 'profileViews' | 'externalLinkClicks' | 'averageTimeSpentSeconds') => void
}

const ProfileOverviewCard = ({
  profileViews,
  averageTime,
  clickRate,
  viewChange = 0.1,
  timeChange = -0.18,
  clickChange = 0.0,
  data,
  selectedMetric,
  onSelectMetric,
}: Props) => {
  const renderChange = (value: number) => {
    const isZero = value === 0
    const increase = value > 0
    const display = `${increase ? '▲' : '▼'} ${Math.abs(Math.round(value * 100))}%`

    return (
      <MetricChange increase={increase} isZero={isZero}>
        <MetricChangeBackground increase={increase} isZero={isZero}>{display}</MetricChangeBackground>
      </MetricChange>
    )
  }

  const formatSeconds = (seconds: number) => {
    const mins = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${mins}:${secs.toString().padStart(2, '0')}`
  }
  
  const formattedTime = averageTime ? formatSeconds(averageTime) : ''

  return (
    <Card>
      <h2 style={{ fontWeight: 400 }}>Profile Overview</h2>
      <p style={{ fontFamily: 'Aktiv Grotesk !important', fontSize: 16, fontWeight: 400 }}>
        How your profile is performing at-a-glance
      </p>

      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={4}>
          <MetricBox selected={selectedMetric === 'profileViews'} onClick={() => onSelectMetric('profileViews')}>
            <MetricLabel>Profile views</MetricLabel>
            <MetricValue>{profileViews}</MetricValue>
            {renderChange(viewChange)}
            <MetricLabel isSubtitle>past 30 days</MetricLabel>
          </MetricBox>
        </Grid>
        <Grid item xs={12} sm={4}>
          <MetricBox selected={selectedMetric === 'averageTimeSpentSeconds'} onClick={() => onSelectMetric('averageTimeSpentSeconds')}>
            <MetricLabel>Average time spent (min)</MetricLabel>
            <MetricValue>{formattedTime}</MetricValue>
            {renderChange(timeChange)}
            <MetricLabel isSubtitle>past 30 days</MetricLabel>
          </MetricBox>
        </Grid>
        <Grid item xs={12} sm={4}>
          <MetricBox selected={selectedMetric === 'externalLinkClicks'} onClick={() => onSelectMetric('externalLinkClicks')}>
            <MetricLabel>Click rate</MetricLabel>
            <MetricValue>{clickRate}</MetricValue>
            {renderChange(clickChange)}
            <MetricLabel isSubtitle>past 30 days</MetricLabel>
          </MetricBox>
        </Grid>
      </Grid>

      <ResponsiveContainer width="100%" height={320} style={{marginTop: '30px'}}>
        <BarChart data={data} barSize={25} barGap={4} barCategoryGap={20}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={<CustomTooltip selectedMetric={selectedMetric} />}
            contentStyle={{
              backgroundColor: 'white',
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              boxShadow: 'none',
              width: '180px',
              height: 100,
            }}
          />
          <Bar dataKey="You" fill="#2F4F4F" />
          <Bar dataKey="Similar" fill="#9FB9B6" />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default ProfileOverviewCard

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LockIcon from '@mui/icons-material/Lock'
import {Box, Grid, Typography, useMediaQuery, useTheme} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import colors from '../colors'
import {PrimaryButton} from '../ui/Buttons'

export function CherubRaiseCard() {
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box p={isMobile ? 2 : 3} borderRadius={isMobile ? 0 : 2}>
      {/* Lock icon and heading */}
      <Grid container spacing={2} alignItems="center" wrap="nowrap">
        <Grid item>
          <LockIcon color="disabled" fontSize={isMobile ? 'small' : 'medium'} />
        </Grid>
        <Grid item xs>
          <Typography variant={isMobile ? 'subtitle1' : 'h5'}>Take your raise to the next level</Typography>
        </Grid>
      </Grid>

      {/* Subtitle text */}
      <Typography variant={isMobile ? 'caption' : 'body2'} color="textSecondary" mt={1} padding={2}>
        Unlock this feature and more with Cherub Raise
      </Typography>

      {/* Call to action button */}
      <PrimaryButton
        size="medium-large"
        sx={{
          width: '100%',
          bgcolor: colors.darkEvergreen[100],
          borderColor: colors.background[5],
          color: colors.background[1],
          paddingTop: '14px',
        }}
        onClick={(e: any) => {
          e.preventDefault()
          navigate('/accounts', {replace: true})
        }}>
        Try Cherub Raise for free
      </PrimaryButton>

      {/* Features list */}
      <Box mt={3}>
        {[
          '14-day free trial',
          'Request intros from verified strategic angel investors and VCs',
          'Build a beautiful data room for your fundraise',
          'See who’s viewed and clicked your profile links',
          'Access fundraising content & education',
        ].map((item, index) => (
          <Grid container spacing={1} alignItems="center" key={index} mt={isMobile ? 0.75 : 1} wrap="nowrap">
            <Grid item>
              <CheckCircleIcon sx={{color: '#0B3932', fontSize: isMobile ? 'small' : 'medium'}} />
            </Grid>
            <Grid item xs>
              <Typography variant={isMobile ? 'caption' : 'body2'} lineHeight={1.4}>
                {item}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  )
}

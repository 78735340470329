import {TooltipProps} from 'recharts'

export const CustomTooltip = ({
  active,
  payload,
  label,
  selectedMetric,
}: TooltipProps<string, any> & { selectedMetric: 'profileViews' | 'externalLinkClicks' | 'averageTimeSpentSeconds' }) => {
  if (!active || !payload || !payload.length) return null

  const formatValue = (value: number, name: string) => {
    if (selectedMetric === 'externalLinkClicks') {
      return `${value} clicks`
    }
    if (selectedMetric === 'averageTimeSpentSeconds') {
      const mins = Math.floor(value)
      const secs = Math.round((value - mins) * 60)
      return `${mins}:${secs.toString().padStart(2, '0')} min`
    }
    if (selectedMetric === 'profileViews') {
      return `${value} views`
    }

    return `${value}`
  }

  return (
    <div style={{ backgroundColor: '#fff', border: '1px solid #E2E2E2', borderRadius: '8px', padding: '8px 12px', fontSize: 12, lineHeight: 1.3, color: '#1A1A1A', boxShadow: '0px 4px 6px rgba(0,0,0,0.05)' }}>
      <div style={{ marginBottom: 4, fontWeight: 'bold' }}>{label}</div>
      {payload.map((entry, i) => {
        const numericValue = typeof entry.value === 'number' ? entry.value : parseFloat(entry.value ?? '0')
        return (
          <div key={`item-${i}`} style={{ color: entry.color, margin: 0 }}>
            {entry.name} : {formatValue(numericValue, entry.name)}
          </div>
        )
      })}
    </div>
  )
}

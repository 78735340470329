export const config = Object.freeze({
  app: {
    apiHost: process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'http://localhost:3000',
    env: process.env.REACT_APP_ENVIRONMENT || 'development',
    homepage: process.env.REACT_APP_HOMEPAGE_URL || 'https://d3vfz2a91d7sa7.cloudfront.net/homepage.json',
    version: process.env.REACT_APP_VERSION || 'local',
    build: process.env.REACT_APP_BUILD_NUMBER || 'local',
    mixpanel: process.env.REACT_APP_MIXPANEL_PROJECT || 'dd2a8bc973b1e0ac25881d29c5079c8e',
    sentry: 'https://5868c5bca7fc5e20b6503d68e5658fce@o4506894299299840.ingest.us.sentry.io/4506989746716672',
  },
  stripe: {
    pk:
      process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
      'pk_test_51N8RvyLkCiJXxkOU6i6acedNVQMMCCQUzAdM35e29Iz5CSZEvTSC4OmjQxV5ByHCPckb8PQgjreUoVwZgy0ufi8r00rGqOiGUD',
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'dev-58msult3vs72lgcx.us.auth0.com',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'VYY8dSdVZHFv55kLIu3uPDLnfh6PgRId',
    redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL || 'http://localhost:3001/#/auth/callback',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://dev-58msult3vs72lgcx.us.auth0.com/api/v2/',
    // for local development, we permit setting this explicitly to test registration via NGROK
    devRegisterCallbackUri:
      process.env.NODE_ENV === 'development' ? process.env.REACT_APP_REGISTER_CALLBACK : undefined,
  },
})

export default config

import {CircularProgress, Grid, Link, Typography} from '@mui/material'
import {Box, Stack, styled} from '@mui/system'
import React, {useEffect} from 'react'
import {useHref} from 'react-router-dom'
import {useMeQuery} from './api/types'
import colors from './colors'
import config from './config'
import {SecondaryButton} from './ui/Buttons'

const MarketingImageHolder = styled('div')(
  ({theme}) => `

    
    overflow: clip;
  `,
)

const ChipHolder = styled('div')(
  ({theme}) => `
    display: inline-block;
    background-color: ${colors.evergreen[70]};
    color: #FFFFFF;
    padding: 0.5rem 0.75rem;

    font-family: 'Aktiv-Grotesk';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: normal;
  `,
)
function Chip(props: any) {
  const text = props.text
  if (text && text.length === 0) {
    return <></>
  }

  return <ChipHolder>{text}</ChipHolder>
}

function CallToAction(props: any) {
  const {callToAction} = props

  if (!callToAction || !callToAction.type || callToAction.type.length === 0) {
    return <></>
  }

  if (callToAction.type === 'link') {
    return (
      <div>
        <SecondaryButton
          href={callToAction.linkSrc}
          size="large"
          sx={{textDecoration: 'none'}}
          target={callToAction.newTab ? '_blank' : ''}
          rel={callToAction.newTab ? 'noopener' : ''}>
          {callToAction.text}
        </SecondaryButton>
      </div>
    )
  }

  return (
    <div>
      <SecondaryButton size="large">{callToAction.text}</SecondaryButton>
    </div>
  )
}
function FlexibleMarketingModule(props: any) {
  const {module} = props
  if (!module.headline || module.headline.length === 0) {
    return <></>
  }

  return (
    <Grid
      container
      sx={{backgroundColor: colors.background[1], padding: {md: '3.75rem 7.5rem', xs: '3.75rem 1.55rem'}}}>
      <Grid item md={6} xs={12}>
        <MarketingImageHolder>
          <img
            src={module.imageSrc}
            alt={module.headline}
            style={{borderRadius: '32px', width: '100%', height: '100%'}}
          />
        </MarketingImageHolder>
      </Grid>
      <Grid container item alignItems="center" md={6} xs={12}>
        <Stack spacing={5} sx={{paddingLeft: {md: '3.75rem'}, paddingTop: {xs: '1.88rem'}}}>
          {/**container item direction='column' justifyContent='center' alignItems='flex-start' */}
          <div>{module.chipText && <Chip text={module.chipText} />}</div>
          <Typography variant="h3" sx={{color: colors.evergreen[130]}}>
            {module.headline}
          </Typography>
          <Typography variant="body2medium" sx={{color: colors.darkEvergreen[75]}}>
            {module.subHeadline}
          </Typography>
          <CallToAction callToAction={module.callToAction} />
        </Stack>
      </Grid>
    </Grid>
  )
}

function EditorialItem(props: any) {
  const {item} = props

  let href = useHref(item.linkSrc || '')
  // TODO: better validation
  if (item.linkSrc && (item.linkSrc.startsWith('http') || item.linkSrc.startsWith('mailto:'))) {
    href = item.linkSrc
  }

  return (
    <Link
      href={href}
      sx={{textDecoration: 'none'}}
      target={item.newTab ? '_blank' : ''}
      rel={item.newTab ? 'noopener' : ''}>
      <Box sx={{maxWidth: '17.81rem'}}>
        <Box sx={{overflow: 'clip'}}>
          <img
            alt={item.imgAlt || item.title}
            src={item.imgSrc}
            style={{borderRadius: '32px', width: '100%', height: '100%'}}
          />
        </Box>
        <Box sx={{marginTop: {md: '1.25rem', xs: '1.25rem'}, color: colors.darkEvergreen[100]}}>
          <Typography variant="h5">{item.title}</Typography>
        </Box>
      </Box>
    </Link>
  )
}

function CarouselModule(props: any) {
  const {module} = props

  if (!module || !module.items || module.items.length === 0) {
    return <></>
  }

  if (
    !module.items[0].imgSrc ||
    module.items[0].imgSrc.length === 0 ||
    !module.items[0].linkSrc ||
    module.items[0].linkSrc.length === 0
  ) {
    return <></>
  }

  return (
    <Grid
      container
      sx={{padding: {md: '3.75rem 7.5rem', xs: '3.75rem 1.55rem'}, backgroundColor: colors.background['warm white']}}>
      {module.eyebrowText && (
        <Grid item xs={12} md={12}>
          <Typography variant="body2" sx={{color: colors.evergreen[100]}}>
            {module.eyebrowText}
          </Typography>
        </Grid>
      )}
      {module.headerText && (
        <Grid item xs={12} md={12}>
          <Typography variant="h3" sx={{color: colors.darkEvergreen[100], marginTop: '1.25rem'}}>
            {module.headerText}
          </Typography>
        </Grid>
      )}
      <Grid container direction="row" justifyContent="center" spacing={5} sx={{paddingTop: '2.5rem'}}>
        {module.items.map((item: any, i: number) => {
          return (
            <Grid key={i} item md={3}>
              <EditorialItem item={item} />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default function HomeView(): React.JSX.Element {
  const {data: me, loading: meLoading} = useMeQuery()
  const [modules, setModules] = React.useState<any[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(config.app.homepage)
      const hpData = await response.json()

      if (me?.me?.profileType === 'investor') {
        setModules(hpData.investorModules)
      } else {
        setModules(hpData.founderModules)
      }
    }

    fetchData()
  }, [me?.me?.profileType])

  if (meLoading) {
    return (
      <Box>
        <CircularProgress color="primary" />
      </Box>
    )
  }

  return (
    <Grid sx={{backgroundColor: 'transparent'}}>
      {modules.map((module: any, i: number) => {
        if (module.type === 'FlexibleMarketingModule') {
          return <FlexibleMarketingModule key={i} module={module} />
        }
        if (module.type === 'CarouselModule') {
          return <CarouselModule key={i} module={module} />
        }
        return <></>
      })}
    </Grid>
  )
}

import {Box} from '@mui/system'
import {SectionDivider, SectionInstructions, SectionSubInstructions} from './Profile.Section'
import {Section, commonFields} from './common'

// TODOs: need to display lenght limits on text input
// see: https://www.figma.com/file/qMoxJn4rVySreDEnf2R9Js/Cherub-Annotations?type=design&node-id=266-1268&mode=dev
const logo: Section = {
  title: 'Headshot',
  percent: 10,
  description: (
    <Box sx={{typography: 'body3'}}>
      <p>Add a headshot image to help people recognize you.</p>
      <p>File format: .JPG, .GIF, .PNG – Max size 10MB</p>
    </Box>
  ),
  fields: [
    {
      property: 'investor.headshot',
      label: 'Headshot',
      placeholder: 'Upload',
      sizes: {xs: 12},
      type: 'image',
      extra: {
        previewType: 'avatar',
        hideText: true,
      },
    },
  ],
}

const basicInfo: Section = {
  title: 'Basic Info',
  percent: 20,
  fields: [
    // TODO: properties here are not right for both, refactor todo
    commonFields.investorFirstName,
    commonFields.investorLastName,
    SectionDivider,
    {
      label: "What's the headline we should include under your name?",
      property: 'investor.headline',
      sizes: {xs: 12},
      placeholder: 'Founder with 2x multi-million dollar exits, currently CEO/Co-founder...',
      tooltip: 'Headlines help founders understand why you’re an exciting partner for them.',
      type: 'text',
      extra: {
        textArea: {
          rows: 3,
        },
        maxLength: 100,
      },
    },
    SectionDivider,
    {
      property: 'investor.check_size',
      label: 'What’s your typical check size?',
      description: 'Check size is optional but it may help to get you more relevant deal flow.',
      tooltip:
        'Check size is not displayed on your profile and only used in the directory as a filter to narrow results. While not required it may help to get you more relevant deal flow.',
      sizes: {xs: 12},
      type: 'select', // TODO: tag select
      options: [
        {value: 'Less than $10K', label: 'Less than $10K'},
        {value: '$10,000 - $25,000', label: '$10,000 - $25,000'},
        {value: '$25K - $50K', label: '$25K - $50K'},
        {value: '$50K - $100K', label: '$50K - $100K'},
        {value: '$100K - $250K', label: '$100K - $250K'},
        {value: 'More than $250K', label: 'More than $250K'},
      ],
    },
    {
      property: 'match_characteristics',
      label: 'My expertise type/skills',
      description: 'Select up to 5 categories to help us find relevant matches.',
      categories: ['expertise-type-skill'],
      sizes: {xs: 12},
      type: 'tags',
      extra: {
        modalPrompt: 'Which skills best apply to you?',
        maxItems: 5,
      },
    },
    {
      property: 'match_targets',
      label: 'My ideal investment is...',
      description: 'Select as many options as relevant to help us find your matches.',
      categories: ['industries', 'company-characteristics'],
      sizes: {xs: 12},
      type: 'tags',
      extra: {
        modalPrompt: 'What type of Cherub members are you looking to connect with?',
        maxItems: null,
      },
    },
  ],
}

const about: Section = {
  title: 'About',
  description: (
    <Box sx={{typography: 'body3'}}>
      <p>Founders want to get to know you and learn why you're the best partner for them.</p>
    </Box>
  ),
  percent: 20,
  fields: [
    () => <SectionInstructions text="Add a video" />,
    {
      property: 'about.video',
      label: 'Paste a public video URL',
      description: 'Paste the video URL from YouTube.',
      placeholder: 'http://www.example.com',
      type: 'text',
      sizes: {xs: 12},
      extra: {
        showPreview: true,
      },
    },
    {
      property: 'about.video_title',
      label: 'Video Title',
      type: 'text',
      sizes: {xs: 12},
    },
    SectionDivider,
    {
      property: 'about.summary',
      label: 'In your own words, what type of investment or advisory opportunities are you looking for?',
      description:
        'Ex: "I\'m looking to pay it forward and invest in underrepresented founders building disruptive businesses in the consumer space," "I\'m passionate about investing in brands that help make our world more sustainable," or "I\'m industry agnostic but looking for profitable companies with proven traction ready to scale their businesses at $1MM+ revenue"',
      placeholder: 'Enter a description...',
      sizes: {xs: 12},
      type: 'text',
      extra: {
        textArea: {
          rows: 6,
        },
        maxLength: 300,
      },
    },
    SectionDivider,
    () => <SectionInstructions text="Add additional assets" />,
    {
      type: 'repeating',
      minimum: 1,
      repeats: 3,
      property: 'about_materials',
      fields: [
        {
          property: 'title',
          label: 'Display Title',
          sizes: {xs: 12},
          type: 'text',
        },
        {
          property: 'link',
          label: 'Link',
          sizes: {xs: 12},
          type: 'text',
        },
        {
          property: 'photo',
          label: 'Upload a thumbnail',
          description: 'File format: .JPG, .GIF, .PNG – Max size 10MB \n Recommended asset size is 1824x1026px',
          sizes: {xs: 12},
          type: 'image',
          extra: {},
        },
      ],
    },
    SectionDivider,
    () => <SectionInstructions text="Find me" />,
    {
      // TODO: validation that it's an LL link, same for all
      property: 'investor_social_links.linkedin',
      label: 'LinkedIn',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'investor_social_links.facebook',
      label: 'Facebook',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'investor_social_links.instagram',
      label: 'Instagram',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'investor_social_links.youtube',
      label: 'Youtube',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'investor_social_links.twitter',
      label: 'Twitter',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    {
      property: 'investor_social_links.tiktok',
      label: 'TikTok',
      placeholder: 'Enter URL',
      sizes: {xs: 12},
      type: 'text',
    },
    () => <SectionInstructions text="Other" />,
    {
      type: 'repeating',
      minimum: 1,
      repeats: 3,
      property: 'investor_social_links_others',
      fields: [
        {
          property: 'title',
          label: 'Display title',
          placeholder: 'Enter title',
          sizes: {xs: 12},
          type: 'text',
        },
        {
          property: 'link',
          label: 'Link',
          placeholder: 'Enter URL',
          sizes: {xs: 12},
          type: 'text',
        },
      ],
    },
  ],
}

const lookingFor: Section = {
  title: "What you're looking for",
  percent: 10,
  fields: [
    {
      property: 'matches.looking_for',
      label:
        'Let founders know what you’re looking for when deciding to make an angel investment or take on an advisory role.',
      sizes: {xs: 12},
      type: 'text',
      extra: {
        textArea: {
          rows: 6,
        },
      },
    },
  ],
}

const featuredIn: Section = {
  title: 'Featured In',
  description: (
    <Box sx={{typography: 'body3'}}>
      Include any notable press mentions or awards that highlight the value you bring as an angel investor or advisor
      beyond a check.
    </Box>
  ),
  percent: 20,
  fields: [
    {
      type: 'repeating',
      minimum: 1,
      repeats: 10,
      property: 'featured_articles',
      fields: [
        {
          property: 'publisher_name',
          label: 'Name',
          // tooltip: content.TODO,
          sizes: {xs: 12},
          type: 'text',
        },
        {
          property: 'title',
          label: 'Title',
          // tooltip: content.TODO,
          sizes: {xs: 12},
          type: 'text',
        },
        {
          // THOUGHT: small service job that queries all link hostnames and reports on them
          property: 'link',
          label: 'link',
          sizes: {xs: 12},
          type: 'text',
        },
        // TODO: need to sort out article_image has_many
        {
          property: 'photo',
          label: 'Upload a thumbnail',
          description: 'File format: .JPG, .GIF, .PNG – Max size 10MB \n Recommended asset size is 1000x1000px',
          sizes: {xs: 12},
          type: 'image',
          extra: {},
          // TODO:
        },
      ],
    },
  ],
}

const keyInvestments: Section = {
  title: 'Key Investments',
  percent: 20,
  fields: [
    () => <SectionInstructions text="Top industries" />,
    () => (
      <SectionSubInstructions text="Give founders a sense of your key investment areas.  These might be areas you want to invest in more or have a lot of experience angel investing in." />
    ),
    {
      type: 'repeating',
      minimum: 1,
      repeats: 5,
      property: 'investing_experience',
      fields: [
        {
          // TODO: this is a tag select dropdown
          property: 'top_industry',
          label: 'Industry',
          sizes: {xs: 12},
          type: 'select',
          options: [
            // TODO: fetch from tags
            {value: 'Food & Beverage', label: 'Food & Beverage'},
            {value: 'D2C', label: 'D2C'},
            {value: 'B2B', label: 'B2B'},
            {value: 'Real Estate', label: 'Real Estate'},
            {value: 'Tech / Software', label: 'Tech / Software'},
            {value: 'Fashion & Beauty', label: 'Fashion & Beauty'},
            {value: 'Health & Wellness', label: 'Health & Wellness'},
            {value: 'Petcare', label: 'Petcare'},
            {value: 'Social / Community', label: 'Social / Community'},
            {value: 'Finance', label: 'Finance'},
            {value: 'Environment', label: 'Environment'},
            {value: 'Media', label: 'Media'},
          ],
        },
      ],
    },
    SectionDivider,
    () => <SectionInstructions text="All your investments" />,
    () => (
      <SectionSubInstructions text="You should add all your past and current investments to create the fullest picture of your experience and portfolio." />
    ),
    {
      type: 'repeating',
      minimum: 1,
      repeats: 10,
      property: 'investing_history',
      fields: [
        {
          property: 'name',
          label: 'Company Name',
          sizes: {xs: 12},
          placeholder: 'Company name',
          type: 'text',
        },
        {
          property: 'tagline',
          label: 'Tagline',
          sizes: {xs: 12},
          placeholder: 'Cutting-edge Asian American culinary style',
          // tooltip: content.TODO,
          type: 'text',
          extra: {
            maxLength: 30,
          },
        },
        {
          property: 'industry',
          label: 'Industry',
          // tooltip: content.TODO,
          sizes: {xs: 12},
          type: 'tags',
          categories: ['industries'],
          extra: {
            modalPrompt: 'Which industry best applies to this company?',
            maxItems: 1,
          },
        },
        {
          property: 'photo',
          label: 'Upload a thumbnail',
          description: 'File format: .JPG, .GIF, .PNG – Max size 10mb',
          sizes: {xs: 12},
          type: 'image',
          extra: {},
        },
      ],
    },
  ],
  // featured investments todo: for now it's just first 3 items in investments
}

const sections = [logo, basicInfo, about, lookingFor, featuredIn, keyInvestments]

if (sections.reduce((acc, section) => acc + section.percent, 0) !== 100) {
  throw new Error('Sections do not add up to 100%')
}

export default sections

import {gql, useMutation, useQuery} from '@apollo/client'
import {Avatar, Box, Card, CardContent, Grid, Stack, styled, Typography, useMediaQuery} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {CherubModal} from './profile/Profile.CherubModal'
import {PrimaryButton, SecondaryButton} from './ui/Buttons'

import {RequiresAuthentication} from './auth/RequiresAuthentication'
import colors from './colors'
import {profileViewData} from './common/helpers'
import {getPlaceholder} from './Directory'
import Footer from './Footer'
import Header from './Header'
import AvatarImagePreview from './profile/Profile.AvatarImagePreview'
import {Done} from './ui/icons'
import {UpdateEmailModal} from './UpdateEmailModal'

// GraphQL Queries & Mutations
const GET_BILLING_DETAILS = gql`
  query BillingDetails {
    billingDetails {
      id
      status
      subscriptions
      planName
      currentPeriodEnd
      customer
      priceId
      paymentMethods
      billingHistory
    }
  }
`

const GET_AVAILABLE_PLANS = gql`
  query GetAvailablePlans {
    availablePlans {
      id
      name
      amount
      currency
      lookupKey
    }
  }
`

const GET_ME = gql`
  query Me {
    me {
      username
      orgId
      subscriptionStatus
      verificationStatus
      profileType
    }
  }
`

const profileQuery = `profile(owner: $orgId) {
  id
  data
  profileType
  creator {
    id
  }
  owner {
    id
    name
  }
  updatedAt
  files
  truncated
}`

const GET_PROFILE = gql`
  query GetProfile($orgId: ID) {
    ${profileQuery}
  }
`

export const UPDATE_EMAIL = gql`
  mutation UpdateEmail($newEmail: String!) {
    updateEmail(input: {newEmail: $newEmail}) {
      success
      errors
    }
  }
`

export const DOWNGRADE_SUBSCRIPTION = gql`
  mutation DowngradeSubscription {
    downgradeSubscription(input: {}) {
      success
      errors
    }
  }
`

const FilledDone = styled(Done)(
  ({theme}) => `
  background: ${colors.evergreen[100]};
  border-radius: 4rem;
  color: ${colors.background.white};

  height: 1.125rem;
  width: 1.125rem;
`,
)

const benefitsMap: any = {
  cherub_free_subscription_annual_v1: [
    'Build a beautiful data room',
    'Accept investor intro requests',
    'Get listed in our directory',
    '',
    '',
    '',
  ],
  cherub_select_quarterly_v1: [
    'Everything in Cherub Basic, plus:',
    'Request investor intros',
    'Priority access to quarterly events',
    'Track profile views & clicks',
    'Virtual fundraising workshops',
    'Expert, concierge support',
  ],
  cherub_select_annual_v1: [
    'Request investor intros',
    'Priority access to quarterly events',
    'Track profile views & clicks',
    'Virtual fundraising workshops',
    'Expert, concierge support',
    '',
  ],
  cherub_invest_annual_v1: [
    'Access curated deal flow',
    'Match with verified startups',
    'Build a robust investor profile',
    'Quarterly IRL event invites',
    'Virtual investor workshops',
    '',
    '',
  ],
}

const PAID_PLANS = [' cherub_select_annual_v1', 'cherub_select_quarterly_v1', 'cherub_select_annual_legacy_v1']

export const route = {
  path: '/accounts',
  element: (
    <RequiresAuthentication>
      <StripeBillingView />
    </RequiresAuthentication>
  ),
  title: 'Account Management',
}

function StripeBillingComponent(): React.JSX.Element {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const [activeCardIndex, setActiveCardIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const isMobile = useMediaQuery('(max-width:600px)')
  const navigate = useNavigate()
  const location = useLocation()

  const {loading: loading2, error: error2, data: data2, refetch: refetchBillingDetails} = useQuery(GET_BILLING_DETAILS)
  const {data: data3, refetch: refetchMe} = useQuery(GET_ME)
  const {loading: loading4, data: data4, refetch: refetchProfile} = useQuery(GET_PROFILE)
  const {data: plansData} = useQuery(GET_AVAILABLE_PLANS)
  const [downgradeSubscription] = useMutation(DOWNGRADE_SUBSCRIPTION)

  const [selectedPlan, setSelectedPlan] = useState<string | null>(null)

  const containerRef = useRef<HTMLDivElement | null>(null)

  const [imgUrl, setImgUrl] = useState('')
  const [companyName, setCompanyName] = useState('')

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenDowngrade, setIsOpenDowngrade] = useState(false)

  // Function to handle scroll event
  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current

      if (container) {
        const scrollPosition = container.scrollLeft

        const cardWidth = (container.children[0] as HTMLDivElement).offsetWidth

        const index = Math.round(scrollPosition / cardWidth)
        setActiveCardIndex(index)
      }
    }
  }

  useEffect(() => {
    const container = scrollContainerRef.current

    if (container) {
      container.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    if (loading4) return
    const data = profileViewData(data4.profile)
    const files = data4.profile.files as Record<string, string>
    const photoKey = data4.profile.profileType === 'founder' ? data.photo?.key : data.additionalPhoto?.key
    const imgUrl = (photoKey && files[`${photoKey}`]) || getPlaceholder(data4.profile.profileType, 1)

    const name = data?.name ?? ''
    setCompanyName(name)
    setImgUrl(imgUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data4])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Refetch queries instead of a full page reload
        await refetchBillingDetails()
        await refetchMe()
        await refetchProfile()

        // Optionally close modal
        setIsOpenDowngrade(false)
      } catch (err) {
        console.error('Mutation error:', err)
      }
    }

    fetchData() // Call the async function inside useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate])

  if (loading2) return <p>Loading billing details...</p>
  if (error2) return <p>Error fetching billing details</p>

  const onManageSubscription = () =>
    window.open(
      'https://intercom.help/cherub/en/articles/9151815-how-to-manage-your-cherub-invest-membership',
      '_blank',
    )

  const Separator = styled('hr')(
    ({theme}) => `
        border: 1px solid;
        width: 100%;
        color: ${colors.background[3]};
        `,
  )

  const allowed_lookup_keys: any = {
    cherub_free_subscription_annual_v1: '',
    cherub_select_quarterly_v1: 'quarter',
    cherub_select_annual_v1: 'year (13% off)',
    cherub_invest_annual_v1: '',
  }

  function getCardTypeImage(type: string): string {
    switch (type) {
      case 'visa':
        return 'https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png'
      case 'mastercard':
        return 'https://upload.wikimedia.org/wikipedia/commons/b/b7/MasterCard_Logo.svg'
      case 'amex':
        return 'https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo.svg'
      case 'discover':
        return 'https://upload.wikimedia.org/wikipedia/commons/5/5f/Discover_Card_logo.svg'
      default:
        return 'https://upload.wikimedia.org/wikipedia/commons/6/6b/Generic_Credit_Card_Icon.png'
    }
  }

  const handleClose = (path?: string) => {
    // setAnchorEl(null)
    if (path) {
      navigate(path)
    }
  }

  const handleDowngrade = async () => {
    setIsLoading(true)

    try {
      const response = await downgradeSubscription()
      if (response.data?.downgradeSubscription.success) {

        // Refetch queries instead of a full page reload
        await refetchBillingDetails()
        await refetchMe()
        await refetchProfile()

        // Optionally close modal
        setIsOpenDowngrade(false)
      } else {
        console.log(`Failed: ${response.data?.downgradeSubscription.errors.join(', ')}`)
      }
    } catch (err) {
      console.error('Mutation error:', err)
    }

    setIsOpenDowngrade(false)
    setIsLoading(false)
  }

  function getDaysLeftInTrial(trialEndTimestamp: any) {
    const currentTimestamp = Math.floor(Date.now() / 1000) // current time in seconds
    const secondsLeft = trialEndTimestamp - currentTimestamp
    const daysLeft = Math.max(0, Math.ceil(secondsLeft / (24 * 60 * 60))) // convert seconds to days
    return daysLeft
  }

  if (selectedPlan)
    return (
      <Grid sx={{display: 'flex', justifyContent: 'center'}}>
        <div ref={containerRef} style={{maxWidth: '30%', minWidth: '30%', height: 'auto'}} />
      </Grid>
    )

  const selectedPlanId = data2?.billingDetails?.priceId

  // Separate selected plan and others
  const selectedPlanDefault = plansData.availablePlans.find((plan: any) => plan.id === selectedPlanId)
  const otherPlans = plansData.availablePlans.filter((plan: any) => plan.id !== selectedPlanId)

  // Sort other plans (modify sorting logic if needed)
  const sortedOtherPlans = [...otherPlans].sort((a, b) => a.amount - b.amount)

  // Insert selected plan in the middle
  const middleIndex = Math.floor(sortedOtherPlans.length / 2)
  const sortedPlans = selectedPlanDefault
    ? [...sortedOtherPlans.slice(0, middleIndex), selectedPlanDefault, ...sortedOtherPlans.slice(middleIndex)]
    : sortedOtherPlans

  let hideDowngrade = false

  if (
    PAID_PLANS.includes(data2?.billingDetails?.subscriptions?.items?.data[0]?.price?.lookup_key) &&
    data2?.billingDetails?.subscriptions?.cancellation_details?.reason === 'cancellation_requested'
  ) {
    hideDowngrade = true
  }

  return (
    <Grid>
      <CherubModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        omitSpacer={true}>
        <UpdateEmailModal prefillEmail={data3.me.username} />
      </CherubModal>
      <CherubModal
        open={isOpenDowngrade}
        onClose={() => setIsOpenDowngrade(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        omitSpacer={true}>
        {isLoading ? (
          <Typography>Loading</Typography>
        ) : (
          <Grid container direction="column" sx={{backgroundColor: '#FDF8F0'}}>
            <Grid item container justifyContent="space-between" alignItems="center">
              <Typography variant="h6" fontWeight={500}>
                Are You Sure?
              </Typography>
            </Grid>

            <Grid item sx={{marginTop: 5}}>
              <Typography variant="body2" color="textSecondary">
                You will lose access to paid features and functionality.
              </Typography>
            </Grid>

            <Grid item container justifyContent="flex-start" spacing={2} sx={{mt: 3}}>
              <Grid item spacing={2}>
                {/* // yes no no hover refresh */}
                <PrimaryButton size="medium-large" onClick={handleDowngrade}>
                  Yes
                </PrimaryButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </CherubModal>
      <Grid
        container
        direction="column"
        alignItems="center"
        sx={{
          border: '1px solid black none',
          marginTop: '67px',
          padding: {xs: '0 16px 24px', sm: '0 24px 32px', lg: '0 290px 40px'},
        }}>
        {/* Account Management Section */}
        <Grid container direction="column" justifyContent="flex-start" sx={{marginTop: 10}}>
          <Typography variant="body1medium">Account Management</Typography>
          <Separator />

          <Grid
            container
            direction={{xs: 'column', md: 'row'}}
            justifyContent="space-between"
            flexWrap="nowrap"
            whiteSpace="nowrap"
            alignItems={{xs: 'flex-start', md: 'center'}}
            sx={{marginTop: 3}}>
            <Stack direction={{xs: 'column', md: 'row'}} alignItems="center" spacing={2} sx={{width: '100%'}}>
              <Avatar sx={{height: {xs: 72, md: 100}, width: {xs: 72, md: 100}}}>
                <AvatarImagePreview image={imgUrl} />
              </Avatar>
              <Box>
                <Typography
                  sx={{fontSize: {xs: 20, md: 26}, fontFamily: 'Warnock-Pro', cursor: 'pointer'}}
                  onClick={() => setIsOpen(true)}>
                  {companyName}
                </Typography>
                <Grid container direction="row" sx={{display: 'flex', flexWrap: 'wrap'}}>
                  <Typography sx={{fontSize: 14}} onClick={() => setIsOpen(true)}>
                    {data3.me.username}
                  </Typography>
                  <Typography
                    sx={{fontSize: 14, marginLeft: 2, textDecoration: 'underline', color: colors.darkEvergreen[100]}}
                    onClick={() => setIsOpen(true)}>
                    Change Email
                  </Typography>
                </Grid>
              </Box>
            </Stack>

            <Box
              sx={{
                width: {xs: '100%', md: 'auto'},
                marginTop: {xs: 2, md: 0},
                display: 'flex',
                justifyContent: 'center',
              }}>
              <SecondaryButton
                size="medium"
                fullwidth="true"
                onClick={() => handleClose(`/profile/${data3?.me.orgId}/edit`)}>
                Edit Profile
              </SecondaryButton>
            </Box>
          </Grid>
        </Grid>

        {/* Available Plans */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          sx={{
            marginTop: 15,
            paddingX: 2,
            display: 'flex',
            height: '100%',
          }}>
          <Typography variant="body1medium">Subscriptions</Typography>
          <Separator />

          {/* Container for plans - can switch to horizontal scroll if preferred */}
          <Box
            ref={scrollContainerRef}
            sx={{
              flexWrap: 'nowrap',
              whiteSpace: 'nowrap',
              display: 'flex',
              height: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              width: '100%',
              scrollSnapType: 'x mandatory',
              ...(isMobile && {
                overflowX: 'scroll',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }),
            }}>
            {sortedPlans ? (
              sortedPlans?.map((plan: any) => {
                let data2PriceId = data2?.billingDetails?.priceId
                let data2PriceIdCompare = data2?.billingDetails?.priceId

                let planId = plan.lookupKey
                // let planIdCompare = plan.lookupKey

                if (data2PriceId === 'price_1Qz3eELkCiJXxkOU2MLcHYVE') {
                  // this line is only for legacy users
                  data2PriceIdCompare = 'price_1OtZ3hLkCiJXxkOUrdMV58Di'
                  planId = 'cherub_select_annual_v1'
                }

                const showFreeTrialBanner = data2?.billingDetails?.subscriptions?.trial_end &&
                getDaysLeftInTrial(data2?.billingDetails?.subscriptions?.trial_end) > 0 &&
                getDaysLeftInTrial(data2?.billingDetails?.subscriptions?.trial_end) < 15 &&
                data2PriceIdCompare === plan.id

                return (
                  <Card
                    key={plan.id}
                    sx={{
                      scrollSnapAlign: 'start',
                      flex: '0 0 auto', // Helps with horizontal scroll
                      width: '100%', // Default to full width on mobile
                      maxWidth: 360, // Limit width on larger screens
                      height: '100%',
                      display: 'flex',
                      border: data2PriceIdCompare === plan.id ? '1px solid green' : undefined,
                      marginTop: 2,
                    }}>
                    <CardContent
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}>
                      <Typography variant="h6">{plan.name}</Typography>
                      <Typography variant="body1medium" sx={{lineHeight: '150%'}}>
                        {plan.amount !== 0 ? `$${plan.amount} / ${allowed_lookup_keys[planId]}` : 'Free'}
                      </Typography>

                      {/* Free Trial Banner */}
                      {data2?.billingDetails?.subscriptions?.trial_end &&
                        getDaysLeftInTrial(data2?.billingDetails?.subscriptions?.trial_end) > 0 &&
                        getDaysLeftInTrial(data2?.billingDetails?.subscriptions?.trial_end) < 15 &&
                        data2PriceIdCompare === plan.id && (
                          <Box
                            sx={{
                              top: 8,
                              right: 8,
                              backgroundColor: colors.darkEvergreen[100],
                              color: 'white',
                              padding: '4px 8px',
                              borderRadius: '16px',
                              fontSize: '0.75rem',
                              fontWeight: 'bold',
                              marginBottom: 5,
                              marginTop: 2,
                            }}>
                            {getDaysLeftInTrial(data2?.billingDetails?.subscriptions?.trial_end)} days left
                          </Box>
                        )}

                      {/* Exipration Banner */}
                      {data2?.billingDetails?.subscriptions?.cancel_at_period_end === true &&
                        data2PriceIdCompare === plan.id && !showFreeTrialBanner && (
                          <Box
                            sx={{
                              top: 8,
                              right: 8,
                              backgroundColor: colors.red[100],
                              color: 'white',
                              padding: '4px 8px',
                              borderRadius: '16px',
                              fontSize: '0.75rem',
                              fontWeight: 'bold',
                              marginBottom: 5,
                              marginTop: 2,
                            }}>
                            Downgrading plan in {getDaysLeftInTrial(data2?.billingDetails?.subscriptions?.cancel_at)} days
                          </Box>
                        )}

                      {/* Benefits list */}
                      <Grid container spacing={2} sx={{marginBottom: 5, minHeight: 250}}>
                        {benefitsMap[planId].map((item: any, i: any) => (
                          <Grid key={i} container item spacing={1} alignItems="center">
                            <Grid item sx={{marginRight: 3}}>
                              {item ? <FilledDone /> : <Grid item sx={{height: '1.125rem'}}></Grid>}
                            </Grid>
                            <Grid item sx={{typography: 'body2', color: colors.darkEvergreen[100], flex: 1}}>
                              {item}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>

                      {/* Action button */}
                      {data2PriceIdCompare === plan.id ? (
                        <Grid container justifyContent="center" sx={{marginTop: 1}}>
                          <SecondaryButton
                            disabled={data3.me?.profileType !== 'investor'}
                            size="medium"
                            onClick={() => {
                              if (data3.me?.profileType === 'investor') {
                                onManageSubscription()
                              } else {
                                setSelectedPlan(plan.id)
                                navigate('/change-subscription', {state: {priceId: plan.id}})
                              }
                            }}>
                            {data3.me?.profileType === 'investor' ? 'Manage Membership' : 'Current Plan'}
                          </SecondaryButton>
                        </Grid>
                      ) : (
                        <SecondaryButton
                          disabled={(plan.lookupKey === 'cherub_free_subscription_annual_v1' || plan.lookupKey === 'cherub_free_subscription_quarterly_v1') && hideDowngrade}
                          size="medium"
                          sx={{marginTop: 1, alignSelf: 'center'}}
                          onClick={() => {
                            if (plan.lookupKey === 'cherub_free_subscription_annual_v1' || plan.lookupKey === 'cherub_free_subscription_quarterly_v1') {
                              setIsOpenDowngrade(true)
                            } else {
                              setSelectedPlan(plan.id)
                              navigate('/change-subscription', {state: {priceId: plan.id}})
                            }
                          }}>
                            {(plan.lookupKey === 'cherub_free_subscription_annual_v1' || plan.lookupKey === 'cherub_free_subscription_quarterly_v1') && hideDowngrade ? `Switch to ${plan.name}` : `Keep ${plan.name}`}
                        </SecondaryButton>
                      )}
                    </CardContent>
                  </Card>
                )
              })
            ) : (
              <Typography>No Available Plans.</Typography>
            )}
          </Box>

          {/* Ellipses Indicator */}
          {isMobile && (
            <Box sx={{display: 'flex', justifyContent: 'center', gap: 1, marginTop: 2}}>
              {sortedPlans?.map((_, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{
                    fontSize: '1.5rem',
                    color: activeCardIndex === index ? colors.darkEvergreen[100] : 'gray',
                    transition: 'color 0.3s',
                  }}>
                  •
                </Typography>
              ))}
            </Box>
          )}
        </Grid>

        {/* Payment Method Section */}
        <Grid container direction="column" justifyContent="flex-start" sx={{marginTop: 15}}>
          <Typography variant="body1medium">Payment Method</Typography>
          <Separator />

          {data2?.billingDetails?.paymentMethods ? (
            <Grid container alignItems="center" justifyContent="space-between" sx={{marginTop: 3}}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box
                  component="img"
                  src={getCardTypeImage(
                    data2.billingDetails.paymentMethods[data2.billingDetails.paymentMethods.type].brand,
                  )}
                  sx={{height: 20}}
                />
                <Typography>
                  •••• •••• •••• {data2.billingDetails.paymentMethods[data2.billingDetails.paymentMethods.type].last4}
                </Typography>
              </Stack>
              {/* <SecondaryButton size='medium' onClick={onManageSubscription}>Change</SecondaryButton> */}
            </Grid>
          ) : (
            <Typography>No active payment methods.</Typography>
          )}
        </Grid>

        {/* Billing History Section */}
        <Grid container direction="column" justifyContent="flex-start" sx={{marginTop: 15}}>
          <Typography variant="body1medium">Billing History</Typography>
          <Separator />

          {data2?.billingDetails?.billingHistory?.length ? (
            data2.billingDetails.billingHistory[0].lines.data.map((t: any, index: any) => (
              <Box key={index} sx={{marginTop: 2}}>
                <Typography sx={{fontSize: 14, color: 'grey'}}>
                  {new Date(t.period.start * 1000).toLocaleDateString()}
                </Typography>
                <Typography sx={{fontSize: 16}}>
                  {t.description} - ${t.amount * 0.01}
                </Typography>
                <Separator />
              </Box>
            ))
          ) : (
            <Typography>No billing history available.</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default function StripeBillingView(): React.JSX.Element {
  return (
    <div>
      <Header />
      <StripeBillingComponent />
      <Footer />
    </div>
  )
}

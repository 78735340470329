import React, {createContext} from 'react'
import {SelectionArrayT} from '../../Directory'

export type FiltersContextType = {
  selectionArray: SelectionArrayT[]
  setSelected: (value: any) => void
  sortBy: string
  setSortBy: (value: string) => void
  showFilters: boolean
  setShowFilters: (value: boolean) => void
}

export const FiltersContext = createContext<FiltersContextType | null>(null)

export function useFilterContext() {
  const filter = React.useContext(FiltersContext)
  if (!filter) {
    throw new Error('useFilterContext must be used within a ProfileFormProvider')
  }

  return {...filter}
}

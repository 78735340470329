import {gql, useMutation} from '@apollo/client'

export function useMarkViewed() {
  return useMutation(gql`
    mutation MarkViewed($id: ID!, $type: String!) {
      markViewed(input: {id: $id, type: $type}) {
        viewedAt
        errors
      }
    }
  `)
}

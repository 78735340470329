import {gql, useQuery} from '@apollo/client'
import {Grid} from '@mui/material'
import {styled} from '@mui/system'
import {useState} from 'react'
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts'
import colors from '../../colors'

export const GET_ENGAGEMENT_BREAKDOWN = gql`
  query EngagementBreakdown($objectId: ID!) {
    engagementBreakdown(objectId: $objectId) {
      averageTimeSpent {
        value
        unit
        period
      }
      sections {
        name
        percentage
      }
    }
  }
`

const Card = styled('div')(({ theme }) => ({
  backgroundColor: colors.background['warm white'],
  padding: '20px',
  borderRadius: '30px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}))

const BlurWrapper = styled('div')({
  filter: 'blur(3.5px)',
  pointerEvents: 'none',
})

const RegularWrapper = styled('div')({
})

const LegendItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  borderBottom: '1px solid #E1DED1',
  width: '100%',
})

const ColorDot = styled('div')(({ color }: { color: string }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  backgroundColor: color,
  flexShrink: 0,
}))

const SectionName = styled('span')({
  fontSize: 14,
  color: colors.darkEvergreen[100],
  flex: 1,
})

const SectionValue = styled('span')({
  fontSize: 14,
  color: colors.darkEvergreen[100],
  fontWeight: 500,
})

const COLORS = ['#2F4F4F', '#4C6B6B', '#6E8C8C', '#90ADAD', '#B3CFCF', '#D6F0F0']

const formatSeconds = (seconds: number) => {
  const mins = Math.floor(seconds / 60)
  const secs = seconds % 60
  return `${mins}:${secs.toString().padStart(2, '0')}`
}

const fakeSectionData = [
  { name: 'Pitch Deck', percentage: 42 },
  { name: 'Team', percentage: 28 },
  { name: 'Product', percentage: 18 },
  { name: 'Traction', percentage: 12 },
]

const EngagementBreakdown = ({ orgId }: any) => {
  const [selectedSection, setSelectedSection] = useState<any>(null)

  const { data, loading, error } = useQuery(GET_ENGAGEMENT_BREAKDOWN, {
    variables: { objectId: orgId },
  })

  const sectionData = data?.engagementBreakdown?.sections || []
  const isEmpty = sectionData.length === 0
  const dataToDisplay = isEmpty ? fakeSectionData : sectionData
  const avgTime = data?.engagementBreakdown?.averageTimeSpent?.value ?? 0 // fallback time
  const formattedTime = avgTime ? formatSeconds(avgTime) : ''

  const Wrapper = isEmpty ? BlurWrapper : RegularWrapper

  return (
    <Card>
      <h2 style={{ fontWeight: 400 }}>Engagement Breakdown</h2>
      {isEmpty ? <p style={{ fontSize: 16, fontWeight: 400 }}>This will be available once we have enough info about your profile viewers</p> : <p style={{ fontSize: 16, fontWeight: 400 }}>{'Which sections of your profile get the most investor attention (past 30 days)'}</p> }

      {loading && <p>Loading...</p>}
      {error && <p>Error loading engagement data</p>}

      {!loading && !error && (
        <Wrapper>
          <Grid container spacing={3} style={{ marginTop: '10px' }} alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              <div style={{ width: '100%', height: 250 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={dataToDisplay}
                      dataKey="percentage"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius={50}
                      outerRadius={80}
                      activeIndex={-1}
                      onClick={(data) => setSelectedSection(data)}
                    >
                      {dataToDisplay.map((_: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <text
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      style={{
                        fontFamily: 'Aktiv Grotesk !important',
                        fontSize: '14px',
                        fill: colors.darkEvergreen[100],
                      }}
                    >
                      {!selectedSection ? (
                        <>
                          <tspan x="50%" dy="-0.6em">Average</tspan>
                          <tspan x="50%" dy="1.2em">Time Spent</tspan>
                          <tspan style={{ fontWeight: 'bold' }} x="50%" dy="1.2em">
                            {formattedTime}
                          </tspan>
                        </>
                      ) : (
                        <>
                          <tspan x="50%" dy="-0.6em">{selectedSection.name}</tspan>
                          <tspan x="50%" dy="1.2em">Time Spent</tspan>
                          <tspan style={{ fontWeight: 'bold' }} x="50%" dy="1.2em">
                            {formatSeconds(Math.round((avgTime * selectedSection.percentage) / 100))}
                          </tspan>
                        </>
                      )}
                    </text>
                    <Tooltip
                      formatter={(value: number) => `${value}%`}
                      cursor={{ fill: 'transparent' }}
                      contentStyle={{
                        backgroundColor: 'white',
                        border: '1px solid #e0e0e0',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        height: 40,
                      }}
                      labelStyle={{
                        fontFamily: 'Aktiv-Grotesk',
                        fontSize: '14px',
                        fontWeight: '400',
                      }}
                      itemStyle={{
                        fontFamily: 'Aktiv-Grotesk',
                        fontSize: '14px',
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container direction="column" spacing={1}>
                {dataToDisplay.map((entry: any, index: number) => (
                  <Grid item key={entry.name}>
                    <LegendItem>
                      <ColorDot color={COLORS[index % COLORS.length]} />
                      <SectionName>{entry.name}</SectionName>
                      <SectionValue>{entry.percentage}%</SectionValue>
                    </LegendItem>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Wrapper>
      )}
    </Card>
  )
}

export default EngagementBreakdown

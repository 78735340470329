import React from 'react'
import {styled} from '@mui/system'
import colors from '../../colors'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '10px',
  marginBottom: '35px',
  color: colors.darkEvergreen[80],
  fontFamily: 'Arial, sans-serif',
  fontSize: '14px',
})

const Message = styled('p')({
  margin: '0 0 10px 0',
  textAlign: 'center',
  color: colors.darkEvergreen[60],
  marginBottom: '25px',
})

const ProgressBarContainer = styled('div')({
  maxWidth: '168px',
  width: '80%',
  height: '4px',
  backgroundColor: '#d3d3d3',
  borderRadius: '2px',
  overflow: 'hidden',
  position: 'relative',
})

const Progress = styled('div')({
  height: '100%',
  width: '40%',
  backgroundColor: colors.darkEvergreen[80],
  borderRadius: '2px',
  transition: 'width 0.3s ease',
})

const ProgressComponent = ({progress, message}: {progress: number; message: string}) => {
  return (
    <Container>
      <Message>{message}</Message>
      <ProgressBarContainer>
        <Progress style={{width: `${progress > 0 ? progress : 0}%`}} />
      </ProgressBarContainer>
    </Container>
  )
}

export default ProgressComponent

import {Popper} from '@mui/base/Popper'
import {DropdownContext, useDropdown} from '@mui/base/useDropdown'
import {MenuProvider, useMenu} from '@mui/base/useMenu'
import {useMenuButton} from '@mui/base/useMenuButton'
import {useMenuItem} from '@mui/base/useMenuItem'
import clsx from 'clsx'
import * as React from 'react'
import colors from '../colors'
import {ChevronDown} from '../ui/icons'

const Menu = React.forwardRef(function Menu(
  props: React.ComponentPropsWithoutRef<'ul'>,
  ref: React.Ref<HTMLUListElement>,
) {
  const {children, ...other} = props

  const {open, triggerElement, contextValue, getListboxProps} = useMenu({
    listboxRef: ref,
  })

  return (
    <Popper className="menu-popper" open={open} anchorEl={triggerElement}>
      <ul className="menu-root" {...other} {...getListboxProps()}>
        <MenuProvider value={contextValue}>{children}</MenuProvider>
      </ul>
    </Popper>
  )
})

const MenuItem = React.forwardRef(function MenuItem(props: React.ComponentPropsWithoutRef<'li'>, ref: React.Ref<any>) {
  const {children, onClick, ...other} = props

  const {getRootProps, disabled, focusVisible, highlighted} = useMenuItem({rootRef: ref})

  const classes = {
    'focus-visible': focusVisible,
    'menu-item': true,
    disabled,
    highlighted: highlighted,
  }

  return (
    <li {...other} {...getRootProps({onClick: onClick ?? (() => {})})} className={clsx(classes)}>
      {children}
    </li>
  )
})

const MenuButton = React.forwardRef(function MenuButton(
  props: React.PropsWithChildren<{}>,
  forwardedRef: React.ForwardedRef<HTMLButtonElement>,
) {
  const {getRootProps: getButtonProps} = useMenuButton({rootRef: forwardedRef})

  return <button type="button" {...props} {...getButtonProps()} className="button" />
})

function Check(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M7.16306 13.2405L3.16211 9.2396L3.96403 8.4377L7.16306 11.6367L14.0371 4.7627L14.839 5.5646L7.16306 13.2405Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default function MobileNavMenu({
  defaultValue,
  sections,
  onClick,
}: {
  defaultValue: number
  sections: {title: string}[]
  onClick: (i: number) => void
}) {
  const {contextValue: dropdownContextValue} = useDropdown()

  const [chosen, setChosen] = React.useState(defaultValue)

  return (
    <React.Fragment>
      <DropdownContext.Provider value={dropdownContextValue}>
        <MenuButton>
          <div>📍 {sections[chosen].title}</div>
          <div>
            <ChevronDown />
          </div>
        </MenuButton>
        <Menu>
          {sections.map((section, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                onClick(i)
                setChosen(i)
              }}>
              <Check visibility={i === chosen ? 'visible' : 'hidden'} />
              <div>{section.title}</div>
            </MenuItem>
          ))}
        </Menu>
      </DropdownContext.Provider>
      <Styles />
    </React.Fragment>
  )
}

function Styles() {
  // Replace this with your app logic for determining dark mode
  const styles = `
    .menu-popper {
      width: 90%;
    }
    
    .menu-root {
      background: var(--background-divier-2, #F6F0EA);
      border-radius: 0.625rem;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);

      list-style-position: inside;
      padding: 0.625rem;
    }

    .menu-item {
      list-style: none;
      border-radius: 0.625rem;
      cursor: default;
      user-select: none;
      color: ${colors.darkEvergreen[100]};
      font-family: "Aktiv-Grotesk";

      display: flex;
      padding: 0.375rem 0.625rem;
      align-items: center;
      gap: 0.625rem;
      align-self: stretch;
    }

    .menu-item:last-of-type {
      border-bottom: none;
    }

    .menu-item.highlighted {
      background-color: rgba(11, 57, 50, 0.10);
      outline: 0;
    }

    .menu-item.disabled {
      color: ${colors.gray[3]};
    }

    .button {
      border: 0;
      background: ${colors.background[2]};
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.10);
      display: flex;
      padding: 0.625rem;
      align-items: center;
      justify-content: space-between;
      gap: 0.625rem;
      border-radius: 0.625rem;
      width: 90%;
      margin: 0 auto;

      color: ${colors.darkEvergreen[100]};
      font-family: "Aktiv-Grotesk";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 1.1rem */
    }
  `

  // eslint-disable-next-line react/no-danger
  return <style dangerouslySetInnerHTML={{__html: styles}} />
}

import {ApolloClient, ApolloProvider, InMemoryCache} from '@apollo/client'
import {AppState, Auth0Provider, User} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import {PropsWithChildren} from 'react'
import {RouteObject, createHashRouter} from 'react-router-dom'
import {route as appRoute} from './App'
import {route as authRoute} from './Authenticate'
import {route as callbackRoute} from './Callback'
import {route as dealsRoute} from './DealFlow'
import {route as directoryRoute} from './Directory'
import {route as engagementRoute} from './Engagement'
import {route as onboardingRoute} from './Onboarding'
import {route as profileViewRoute} from './Profile.View'
import {route as accountsRoute} from './StripeBilling'
import {route as subscriptionsRoute} from './StripeBillingCheckout'
import {route as landingsRoute} from './auth/Landings'
import {useAuthentication} from './auth/useAuthentication'
import config from './config'

// TODO: only load authRoute if domain is auth0 / auth.
// In here for now so we can call router.navigate
// TODO: alert if these are not set
let routes: RouteObject[] = [
  appRoute,
  dealsRoute,
  directoryRoute,
  engagementRoute,
  profileViewRoute,
  callbackRoute,
  onboardingRoute,
  landingsRoute,
  accountsRoute,
  subscriptionsRoute,
]
if (window.location.host === config.auth0.domain) {
  routes = [authRoute]
}

export const router = Sentry.wrapCreateBrowserRouter(createHashRouter)(routes)

// const localStorageAuthKey = '@@Cherub:auth:state'

// export function isAuthenticated() {
//   return window.localStorage.getItem(localStorageAuthKey) === 'authenticated'
// }

export const Auth0ProviderWithNavigate = ({children}: PropsWithChildren) => {
  // TODO: RouterProvider doesn't take child elements, so for now we just reload with a blip
  // const navigate = useNavigate()

  const onRedirectCallback = (appState?: AppState, user?: User) => {
    // window.localStorage.setItem(localStorageAuthKey, 'authenticated')
    if (user) {
      mixpanel.identify(user.sub)
    }
    window.location.href = appState?.returnTo || '/#/'
  }

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      authorizationParams={{
        redirect_uri: config.auth0.redirectUri,
        audience: config.auth0.audience,
      }}
      onRedirectCallback={onRedirectCallback}>
      <AuthenticatedApollo>{children}</AuthenticatedApollo>
    </Auth0Provider>
  )
}

const anonymousClient = new ApolloClient({
  uri: `${config.app.apiHost}/graphql`,
  cache: new InMemoryCache(),
  name: 'Cherub',
  version: 'beta',
})

function AuthenticatedApollo({children}: PropsWithChildren<{}>) {
  const {state, _client} = useAuthentication()

  if (state !== 'authenticated' || !_client) {
    return <ApolloProvider client={anonymousClient}>{children}</ApolloProvider>
  }

  return <ApolloProvider client={_client}>{children}</ApolloProvider>
}
